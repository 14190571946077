import React, { useState, useEffect } from 'react';
import { Radio, Typography, message, Layout, Row, Col, Card, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal, DatePicker, InputNumber, Upload } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons' //antd design icons import
import { UndoOutline } from 'antd-mobile-icons'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import columns from './config';
import ImageUpload from '../../../common/ImageUpload';
import SearchComponent from '../../../common/TableSearch';
import EditForm from './editForm';
import AdsService from '../../../services/ads.service';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;
const { Link } = Typography;

const AdsManager = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false); //drawer add new product
    const [storeId, setStoreId] = useState(null);
    const [fileList, setFileList] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isEditmode, setIsEditMode] = useState(false);

    const showDrawer = () => {
        loadData();
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        onReset();
        setIsEditMode(false);
    };

    useEffect(() => {
        // const path = window.location.pathname; // Get the current URL path
        // const parts = path.split('/'); // Split the path by '/'
        // const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        loadData(' ');
    }, []);

    const loadData = async () => {
        const response = await AdsService.getAll();
        //console.log('ads list', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: isEditmode ? 'แก้ไขข้อมูลเรียบร้อย' : `เพิ่มข้อมูลเรียบร้อย`,
            description:
                isEditmode ? 'ระบบได้บันทึกแก้ไขข้อมูลเรียบร้อย' : `ระบบเพิ่มข้อมูลเรียบร้อย`,
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'product');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUrl: response.data.imageUrl };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        const oldCover = isEditmode ? selectedItem.imageUrl : { imageUrl: null };
        const imageUrl = fileList.length >= 1 && fileList[0].originFileObj ? await uploadImage() : oldCover;
        if (isEditmode) {
            const response = await AdsService.updateById(selectedItem._id, { ...values, imageUrl: fileList.length >= 1 && fileList[0].originFileObj ? `https://www.atom-soft.com/image-api/${imageUrl.imageUrl}` : oldCover });
            if (response) {
                console.log(response);
                successNotification('topRight')
                await loadData();
                onClose();
            }
        } else {
            const response = await AdsService.create({ ...values, imageUrl: fileList.length >= 1 ? `https://www.atom-soft.com/image-api/${imageUrl.imageUrl}` : '' });
            if (response) {
                console.log(response);
                successNotification('topRight')
                await loadData();
                onClose();
            }
        }

    };

    const onReset = () => {
        form.resetFields();
        setSelectedItem(null);
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSearch = (value, _e, info) => console.log(info?.source, value);
    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
        form.setFieldsValue({
            image: fileList,
        });
    };

    const handleSearch = async (results) => {
        if (results.error) {
            loadData(' ');
        } else {
            setData(results.data);
        }
    };

    columns[1].render = (text, record) => (
        <>
            <Link onClick={() => {
                console.log('selected', record);
                setIsEditMode(true);
                setSelectedItem({ ...record, id: record._id });
                showDrawer(true);
            }} target="_self">
                {text}
            </Link>
        </>
    )

    return (
        <>
            {contextHolder}
            <Drawer
                title={isEditmode ? 'แก้ไข' : 'สร้างใหม่'}
                width={720}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onReset}>เคลียร์</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>}>

                {isEditmode ? (
                    <>
                        {selectedItem && (
                            <>
                                <EditForm
                                    initialValues={selectedItem}
                                    form={form}
                                    onFinish={onFinish}
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    handleUpload={handleUpload} />
                            </>
                        )}

                    </>
                ) : (
                    <>
                        <Form
                            onFinish={onFinish}
                            form={form}
                            layout="vertical">

                            <Row gutter={16}>
                                <Col span={16}>
                                    <Form.Item
                                        name="isActive"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากำหนดสถานะ',
                                            },
                                        ]}>
                                        <Radio.Group size="large">
                                            <Radio.Button value={true}>แสดง</Radio.Button>
                                            <Radio.Button value={false}>ซ่อน</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={16}>
                                    <Form.Item
                                        name="index"
                                        label="ลำดับโฆษณา"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'ลำดับโฆษณา',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="1" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label="ชื่อโฆษณา"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาป้อนชื่อ',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="เครื่องดื่ม" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="link"
                                        label="ลิงค์รูป">
                                        <Input placeholder="ลิงค์ที่ต้องการให้ไปเมื่อคลิกรูป" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="position"
                                        label="ตำแหน่งโฆษณา"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'เลือกตำแหน่งแสดงโฆษณา',
                                            },
                                        ]}
                                    >
                                        <Select
                                            defaultValue={'HOME-TOP'}
                                        >
                                            <Select.Option value="NONE">เลือกตำแหน่งแสดงโฆษณา</Select.Option>
                                            <Select.Option value="HOME-TOP">โฆษณาหน้าแรก</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="description"
                                        label="หมายเหตุ">
                                        <Input placeholder="หมายเหตุ" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="image"
                                        label="รูปโฆษณา"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'เลือกรูปโฆษณา',
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            maxCount={1}
                                            fileList={fileList}
                                            onChange={handleUpload}
                                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    อัพโหลด
                                                </div>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </Drawer>
            <Layout style={{ minHeight: '100%', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'ads'} storeId={storeId} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<UndoOutline />}
                                style={{ position: 'absolute', right: '180px' }}
                                onClick={() => {
                                    loadData();
                                }}
                            >
                                รีเฟรช
                            </Button>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                                onClick={() => showDrawer(true)}
                            >
                                เพิ่มโฆษณาใหม่
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card className='card' style={{ width: '100%', }} title="รายการโฆษณา">
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={'_id'}
                                    locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default AdsManager;
