import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Space, Divider, Breadcrumb, Layout, Menu, theme, Avatar, Dropdown, Typography, Image } from 'antd';
import { Footer } from 'antd-mobile'
import { UserOutlined, SettingOutlined, HomeOutlined, ShopOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { logout } from "../../slices/auth";
import liff from '@line/liff';
import { loginLine } from "../../slices/auth";
import EventBus from "../../common/EventBus";
import SearchComponent from "../../components/store/SearchComponent";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../config/AppConfig";
import ShoppingCartPopup from '../cart/cartPopup';
import dbdRegistered from '../../assets/images/dbd_registered.png';

const { Header, Content, Sider } = Layout;
const { Text } = Typography;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('เมนู', 'menu', <HomeOutlined />, [
    getItem('คำสั่งซื้อ', 'orders', null, [getItem(<Link to={"admin/stores-orders"}>จัดการคำสั่งซื้อ</Link>, 'orders-list'), getItem(<Link to={"payment-info"}>การแจ้งชำระเงิน</Link>, 'payment-list')], 'group'),
    getItem('ร้านค้า', 'stores', null, [getItem((<Link to={"/admin/add-store"}>เพิ่มร้านใหม่</Link>), 'add-store'), getItem(<Link to={"/admin/stores-list"}>รายชื่อร้านค้า</Link>, 'stores-list')], 'group'),
    // getItem('กิจกรรม', 'events', null, [getItem(<Link to={"/admin/events-list"}>จัดการกิจกรรม</Link>, 'events-list')], 'group'),
  ]),
  {
    type: 'divider',
  },
  getItem('การตั้งค่า', 'settings', <SettingOutlined />, [
    getItem('โฆษณา', 'adsmanager', null, [getItem((<Link to={"/admin/ads"}>จัดการโฆษณา</Link>), 'ads'),], 'group'),
    getItem('บัญชีผู้ใช้', 'accounts', null, [getItem((<Link to={"/admin/users-list"}>จัดการบัญชีผู้ใช้</Link>), 'administrator'),], 'group'),
    getItem('ผู้ใช้', 'store', null, [getItem((<Link to={"/profile"}>ข้อมูลของฉัน</Link>), 'store-setting'),], 'group'),
  ]),
];

const BoxedLayout = ({ children, cartData, onUpdateCart, onDelete, setCartData, rerenderKey, onSearch }) => {
  const navigate = useNavigate();
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showOwnerBoard, setShowOwnerBoard] = useState(false);
  const [showUserBoard, setShowUserBoard] = useState(false);
  const { token: { colorBgContainer }, } = theme.useToken();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const logOut = useCallback(() => {
    dispatch(logout());
    liff.logout();
  }, [dispatch]);

  //LINE LIFF initialization
  const initLine = () => {
    liff.init({ liffId: AppConfig.LIFF_ID }, () => {
      if (!isLoggedIn) {
        liff.getProfile().then(profile => {
          //console.log('Received values:', profile);
          dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl }))
            .unwrap()
            .then(() => {
              navigate("/home");
              //window.location.reload();
            })
            .catch(() => {
              // setLoading(false);
            });
        }).catch(err => console.error(err));
      }
    }, err => console.error(err));
  }

  useEffect(() => {
    initLine();
  }, []);

  const onClick = (e) => {
    if (e.key === 'profile') {

    } else if (e.key === 'logout') {
      logOut();
    }
  };

  const handleSearch = (keyword) => {
    onSearch(keyword);
    navigate(`search?keyword=${keyword}`);
  }

  const handleOnKeywordChange = (keyword) => {
    onSearch(keyword);
    navigate(`search?keyword=${keyword}`);
  }

  useEffect(() => {

    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setShowUserBoard(currentUser.roles.includes("ROLE_USER"));
      setShowOwnerBoard(currentUser.roles.includes("ROLE_OWNER"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const topNavItems = [
    {
      label: <Link to={"/home"}>หน้าแรก</Link>,
      key: 'home',
      icon: <HomeOutlined />,
    },
    {
      label: <Link to={"/store"}>ร้านค้า</Link>,
      key: 'store',
      icon: <ShopOutlined />,
    },
    {
      label: <Link to={"/aboutus"}>เกี่ยวกับเรา</Link>,
      key: 'aboutus',
      icon: <InfoCircleOutlined />,
    },
  ];

  const logedInMenu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => navigate('/profile')}>
        โปรไฟล์
      </Menu.Item>
      {showOwnerBoard || showAdminBoard && (
        <>
          <Menu.Item key="settings" onClick={() => navigate('/admin/stores-list')}>
            จัดการร้านของฉัน
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item key="logout" onClick={() => {
        logOut();
        navigate('/')
      }}>
        ออกจากระบบ
      </Menu.Item>
    </Menu>
  );

  const guestMenu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => navigate('/login')}>
        เข้าสู่ระบบ
      </Menu.Item>
      <Menu.Item key="settings" onClick={() => navigate('/register')}>
        สมัครสมาชิก
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout
        style={{
          margin: '0 auto',
          maxWidth: showAdminBoard ? '2000px' : '1200px',
          display: 'block',
          width: '100%',
          minHeight: '100vh',
          minWidth: '300px', // Add a minimum width for small screens
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Adding box shadow
        }}>
        <Layout>
          {/* Sidebar menu */}
          {currentUser ? (
            showAdminBoard && (
              <Sider
                width={200}
                style={{
                  background: theme.background,
                }}
              >
                <Menu
                  onClick={onClick}
                  theme="light"
                  style={{ height: '100%', borderRight: 0, left: 0, position: 'absolute', background: '#002673', }}
                  defaultSelectedKeys={['menu']}
                  defaultOpenKeys={['menu', 'settings']}
                  mode="inline"
                  inlineCollapsed={true}
                  items={items}
                />
              </Sider>)) : null}
        </Layout>

        <Layout style={{ marginLeft: showAdminBoard ? '220px' : 'auto', display: 'block' }}>
          <Header
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              background: '#007bff',
              padding: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div style={{ padding: '0', display: 'flex', justifyContent: 'flex-start', color: '#ffffff' }}>
              <Menu
                className="custom-menu-background"
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['2']}
                items={topNavItems}
                style={{
                  width: 350,
                  color: '#ffffff'
                }}
              />
              <SearchComponent onSearch={handleSearch} onChange={handleOnKeywordChange} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#ffffff', width: '200px', marginRight: '50px', marginTop: 10, }}>
              <Space>

                {cartData && (
                  <>
                    <ShoppingCartPopup cartData={cartData} onUpdateCart={onUpdateCart} onDelete={onDelete} setCartData={setCartData} rerenderKey={rerenderKey} />
                  </>
                )}

                <Dropdown overlay={currentUser ? logedInMenu : guestMenu} placement="bottomRight">
                  <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ marginRight: '0px', cursor: 'pointer' }}>
                    <Avatar icon={<UserOutlined />} size="small" style={{ marginRight: '8px' }} />
                    {currentUser ? currentUser.name || currentUser.username : 'บัญชีของฉัน'}
                  </p>
                </Dropdown>
              </Space>
            </div>
          </Header>
        </Layout>
        <Layout style={{ marginLeft: showAdminBoard ? '220px' : 'auto', }}>
          <Divider orientation="left" type="vertical">
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <UserOutlined />
                      <span>Application List</span>
                    </>
                  ),
                },
                {
                  title: 'Application',
                },
              ]}
            />
          </Divider>
          <Content>
            <div style={{ padding: 5, background: '#fff', minHeight: 360, marginTop: '20px' }}>
              {children}
            </div>
          </Content>
          <Footer
            links={[
              {
                text: 'ร้านค้าที่ให้การสนับสนุน',
                href: '/store',
              },
              {
                text: 'เกี่ยวกับเรา',
                href: '/aboutus',
              },
            ]}
          ></Footer>
          <Footer content={
            <>
              {AppConfig.APP_FOOTER}{AppConfig.APP_FOOTER1}
            </>
          }></Footer>
          <Footer content='@ 2023 atom-soft.com All rights reserved'></Footer>
          {/* <Footer content={
            <Image width={120} src={dbdRegistered} preview={false} style={{ cursor: 'pointer' }} onClick={() => {
              window.open('https://www.trustmarkthai.com/callbackData/popup.php?data=922-37-6-78b603f9713b6889a3ee1d845e99634d7d1132531a7', '_blank');
            }} />
          }></Footer> */}
        </Layout>
      </Layout>
    </>
  );
};

export default BoxedLayout;