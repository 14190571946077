import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Layout, Menu, theme, Card, Col, Row, Button, List, Drawer, Image, Typography } from 'antd';
import { Popup, SearchBar, NavBar, Toast, Input, Space } from 'antd-mobile'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import UserService from "../../services/user.service";
import StoreService from "../../services/stores.service";
import SearchComponent from "./SearchComponent";
import styles from './style.css';
import StoreItem from './StoreItem';


const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Text } = Typography;

const Store = ({ onSearch }) => {
  const { token: { colorBgContainer }, } = theme.useToken();
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
        loadData();
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );

    // Set the document title when the component mounts
    document.title = `ร้านค้า`;
    scrollToTop();
  }, []);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const loadData = async () => {
    const response = await StoreService.getAllStores();
    //console.log('stores', response);
    if (response.status == 200) {
      setData(response.data);

    } else {
      setData([]);
    }
  }

  // Function to update screenWidth state when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to update screenWidth when the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSelect = (item) => {
    navigate('/' + item.name + '/' + item._id)
  }

  return (
    <>
      {screenWidth < 768 && (
        <>
          <NavBar backArrow={false} style={{ position: 'fixed', top: 0, left: 0, zIndex: 2, background: '#007bff', height: '60px', width: '100%', color: 'white' }}>
            <Space>
              <SearchBar
                style={{ maxWidth: '90%', color: '#ffffff' }}
                onChange={(val) => {
                  setKeyword(val);
                }}
                onSearch={(val) => {
                  navigate(`../search?keyword=${keyword}`);
                  onSearch(keyword);
                  Toast.show(`ผลการค้นหา：${keyword}`)
                }}
                placeholder='พิมพ์คำที่ต้องการค้นหา...'
                showCancelButton
                onCancel={() => {
                  navigate(`../search?keyword=${keyword}`);
                  onSearch(keyword);
                  Toast.show(`ผลการค้นหา：${keyword}`)
                }}
                cancelText={(<Text type="secondary" style={{ color: '#ffffff' }}>ค้นหา</Text>)} />
            </Space>
          </NavBar>
        </>
      )}

      <div class="container" style={{ marginTop: screenWidth < 768 ? 60 : 0, marginBottom: screenWidth < 768 ? 50 : 0 }}>
        <div class="store-grid">
          {data.map(item => (
            <StoreItem key={item._id} item={item} onSelect={onSelect}/>
          ))}
        </div>
      </div>
      
    </>
  );
};

export default Store;
