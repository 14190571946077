import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}stores`;

const getPublicStores = () => {
  return axios.get(API_URL + "/all");
};

const getAllStoresByOwnerId = (ownerId) => {
  return axios.get(AppConfig.API_URL+'mystore/' + ownerId, { headers: authHeader() });
};

const getStoresById = (storeId) => {
  return axios.get(AppConfig.API_URL+'stores/' + storeId, { headers: authHeader() });
};

const getSearchByName = (ownerId, keyword) => {
  return axios.get(AppConfig.API_URL+`mystore/${ownerId}/${keyword}`, { headers: authHeader() });
};

const adminGetSearchByName = (ownerId, keyword) => {
  return axios.get(AppConfig.API_URL+`mystore-admin/${keyword}`, { headers: authHeader() });
};

const getAllStores = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const createStore = (value) => {
    return axios.post(API_URL, value, { headers: authHeader() });
};

const updateById = (id, value) => {
  return axios.put(API_URL + '/' + id, { ...value }, { headers: authHeader() });
};

const StoreService = {
    createStore,
    getPublicStores,
    getStoresById,
    getAllStores,
    getAllStoresByOwnerId,
    getSearchByName,
    adminGetSearchByName,
    updateById
};

export default StoreService