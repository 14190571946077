import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import liff from '@line/liff';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { QRCode, Spin, Select, Popover, Form, Input, Upload, message, Avatar, Layout, Col, Row, notification, Menu, Typography } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Grid, Card, Toast, Button, List, Space, PageIndicator, Swiper, Tabs, AutoCenter } from 'antd-mobile'
import { LocationOutline, AntOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline } from 'antd-mobile-icons'

import AppConfig from '../../../config/AppConfig';
import userService from '../../../services/user.service';
import { logout } from "../../../slices/auth";
import { loginLine } from "../../../slices/auth";
import { getAutoSuggestion } from 'thai-data';
import '../overview/style.css';

const { Text, Title } = Typography;

const MemberQRCode = ({ isMobile, redirectUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = React.useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0) //set default active tab is 0
    const [walletBalance, setWalletBalance] = useState(0);
    const [isLoading, setIsloading] = useState(true);

    const logOut = useCallback(() => {
        dispatch(logout());
        liff.logout();
    }, [dispatch]);

    //LINE LIFF initialization
    const initLine = () => {
        liff.init({ liffId: AppConfig.LIFF_ID }, () => {
            if (liff.isLoggedIn()) {
                const email = liff.getDecodedIDToken().email;
                if (!isLoggedIn) {
                    liff.getProfile().then(profile => {
                        dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl, email: email }))
                            .unwrap()
                            .then(() => {
                                window.location.reload();
                                setIsloading(false);
                            })
                            .catch(() => {
                                setIsloading(false);
                            });
                    }).catch(err => console.error(err));
                }
            }
        }, err => console.error(err));
    }

    useEffect(() => {
        initLine();
    }, []);


    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }
    }, []);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            setUserData(response.data[0]);
            setWalletBalance(response.data[0].points);
            formRef.current?.setFieldsValue({
                subdistirct: response.data[0].subdistirct,
            });
            setIsloading(false);
        } else {
            setIsloading(false);
        }

    }

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    if (!isLoggedIn) {

        // Navigate to the destination route
        navigate('/login');

    }

    const Content = () => {
        return <>
            <div style={
                {
                    display: 'flex',
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally
                    minHeight: '100vh', // To center it in the viewport vertically
                }}>
                <Space block size="lg" direction="vertical">
                    <Card title={'คิวอาร์โค้ดของฉัน'} style={{ minHeight: '70vh' }}>
                        <QRCode type="svg" value={currentUser.id} width="100%" size={300} />
                        <AutoCenter>
                            <Title level={4} style={{marginTop: '20px'}}>{currentUser.name}</Title>
                        </AutoCenter>
                    </Card>
                </Space>
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    minHeight: '95vh', // Set minimum height for the whole viewport
                    width: '100%',
                    padding: '6px',
                    minWidth: '100%',
                }}>
                {userData && (
                    <>
                        <Content />
                    </>
                )}

            </div >
        </>
    );
};

export default MemberQRCode;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};