import React, { useRef, useState } from 'react';
import styled from 'styled-components';
const noImage = 'https://via.placeholder.com/200'; // Placeholder image URL

const StoreItem = ({ item, onSelect }) => {

  // console.log('Store Item', item);

  return (
    <Item 
    onClick={() => onSelect(item)} 
    style={{ cursor: 'pointer' }}
    >
      <ImageWrapper>
        {item.logo ? (
          <>
            <img
              src={item.logo ? `https://www.atom-soft.com/image-api/${item.logo}` : noImage}
              alt={item.name} />
          </>
        ) : (
          <>
            <ImgText>
              {item.name}
            </ImgText>
          </>
        )}
      </ImageWrapper>
      <Info>
        <Name>{item.name}</Name>
        <Barcode>{item.description}</Barcode>
        {/* <Price>{item.price.toFixed(2)}</Price>  */}
        {/* <Name style={{ fontSize: 15, color: 'GrayText' }}>คงเหลือ {item.unit_in_stock} ชิ้น</Name> */}
        {/* <CartButton onClick={() => onSelect(item)}>รายละเอียด</CartButton> */}
      </Info>
    </Item>
  );
};

export default StoreItem;

const ImgText = styled.div`
  width: auto;
  height: 150px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #62676cb8;
`;

const Item = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  text-align: center;
`;

const Info = styled.div`
  padding: 15px;
  text-align: center;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0;
  margin-top: 0px;
  color: #333;
  height: 30px; /* Fixed height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #FFFFFF;
`;

const Barcode = styled.div`
font-size: 14px;
font-weight: regular;
margin: 0px 0;
color: #131313;
height: 40px; /* Adjust the height for two lines */
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2; /* Number of lines to show */
-webkit-box-orient: vertical;
`;

const Price = styled.div`
  font-size: 18px;
  color: #e91e63;
`;

const CartButton = styled.a`
  display: inline-block;
  margin-top: 1px;
  padding: 10px 15px;
  background-color: rgb(0, 123, 255);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  &:hover {
    background-color: rgb(122 182 246);
  }
`;