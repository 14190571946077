import React, { useState, useEffect } from 'react';
import { Typography, message, Layout, Row, Col, Card, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal, DatePicker, InputNumber, Upload } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import userService from '../../../services/user.service';
import ProductService from '../../../services/product.service';
import ProductCategoryService from '../../../services/product-category.service';
import columns from './config';
import ImageUpload from '../../../common/ImageUpload';
import SearchComponent from '../../../common/TableSearch';
import EditForm from './editForm';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;
const { Link } = Typography;

const ProductsCategoryManagement = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false); //drawer add new product
    const [storeId, setStoreId] = useState(null);
    const [fileList, setFileList] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isEditmode, setIsEditMode] = useState(false);

    const showDrawer = () => {
        loadData();
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        onReset();
        setIsEditMode(false);
    };

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('store', storeId);
        if (storeId) {
            setStoreId(storeId);
            loadData(storeId);
        }
    }, [data]);

    const loadData = async (storeId) => {
        const response = await ProductCategoryService.getAllByStoreId(storeId);
        //console.log('products categorys', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }


    }

    const successNotification = (placement) => {
        api.info({
            message: isEditmode?'แก้ไขข้อมูลเรียบร้อย' : `เพิ่มข้อมูลเรียบร้อย`,
            description:
            isEditmode?'ระได้บันทึกแก้ไขข้อมูลเรียบร้อย' : `ระบบเพิ่มข้อมูลเรียบร้อย`,
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'product');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUrl: response.data.imageUrl };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        const logo = fileList ? await uploadImage() : {};
        const response = await isEditmode ? ProductCategoryService.update({ ...values, id: selectedCategory.id, storeId: [storeId], logo: logo ? logo.imageUrl : '' }) : ProductCategoryService.create({ ...values, storeId: [storeId], logo: logo ? logo.imageUrl : '' });
        if (response) {
            console.log(response);
            successNotification('topRight')
            await loadData(storeId);
            onClose();
        }

    };

    const onReset = () => {
        form.resetFields();
        setSelectedCategory(null);
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSearch = (value, _e, info) => console.log(info?.source, value);
    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    const handleSearch = async (results) => {
        if (results.error) {
            loadData(storeId);
        } else {
            setData(results.data);
        }
    };

    columns[1].render = (text, record) => (
        <>
            <Link onClick={() => {
                console.log('selected', record);
                setIsEditMode(true);
                setSelectedCategory({ ...record, id: record._id });
                showDrawer(true);
            }} target="_self">
                {text}
            </Link>
        </>
    )

    return (
        <>
            {contextHolder}
            <Drawer
                title={isEditmode ? 'แก้ไขหมวดหมู่' : 'สร้างหมวดหมู่สินค้า'}
                width={720}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onReset}>เคลียร์</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>}>

                {isEditmode ? (
                    <>
                        {selectedCategory && (
                            <>
                                <EditForm
                                    initialValues={selectedCategory}
                                    form={form}
                                    onFinish={onFinish}
                                    fileList={fileList}
                                    setFileList={setFileList} />
                            </>
                        )}

                    </>
                ) : (
                    <>
                        <Form
                            onFinish={onFinish}
                            form={form}
                            layout="vertical">
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Form.Item
                                        name="categoryNumber"
                                        label="รหัสหมวดหมู่"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'ป้อนรหัสหมวดหมู่',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="1" />
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name="index"
                                        label="ลำดับ"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'ป้อนลำดับหมวดหมู่',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="1" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label="ชื่อหมวดหมู่"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาป้อนชื่อหมวดหมู่',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="เครื่องดื่ม" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}


            </Drawer>
            <Layout style={{ minHeight: '100%', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'product_category'} storeId={storeId} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                                onClick={() => showDrawer(true)}
                            >
                                เพิ่มหมวดหมู่
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card className='card' style={{ width: '100%', }} title="หมวดหมู่สินค้าในร้าน">
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={'_id'}
                                    locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default ProductsCategoryManagement;
