import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}order`;

//url /api/order/create
const createOrder = (value) => {
    return axios.post(`${AppConfig.API_URL}order/create`, value, { headers: authHeader() });
};


//url:  /api/order/:userId
const getAllByUserId = (userId) => {
    return axios.get(AppConfig.API_URL + 'order/' + userId, { headers: authHeader() });
};

//url:  /api/order/:storeId
const getAllByStoreId = (storeId) => {
    return axios.get(AppConfig.API_URL + 'store/order/' + storeId, { headers: authHeader() });
};

//url: /api/order/:userId
const getOrdersByUserId = (userId) => {
    return axios.get(AppConfig.API_URL + 'order/' + userId, { headers: authHeader() });
};

//url: /api/order-detail/:orderId
const getAllByOrderId = (orderId) => {
    return axios.get(AppConfig.API_URL + 'order-detail/' + orderId, { headers: authHeader() });
};


//update by orderId
//:  /api/order/:orderId
const updateById = (orderId, value) => {
    return axios.put(AppConfig.API_URL + 'order/' + orderId, { ...value }, { headers: authHeader() });
  };

//url: /api/order/:id
const deleteById = (orderId) => {
    return axios.delete(AppConfig.API_URL + 'order/' + orderId, { headers: authHeader() });
};

const OrderService = {
    createOrder,
    getOrdersByUserId,
    getAllByStoreId,
    getAllByUserId,
    getAllByOrderId,
    updateById,
    deleteById,
};

export default OrderService;