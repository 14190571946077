import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import liff from '@line/liff';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Spin, Select, Popover, Form, Input, Upload, message, Avatar, Layout, Col, Row, notification, Menu, Typography } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Result, Grid, Card, Toast, Button, List, Space, PageIndicator, Swiper, Tabs } from 'antd-mobile'
import { LocationOutline, AntOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline, LockOutline } from 'antd-mobile-icons'
import { SwiperRef } from 'antd-mobile/es/components/swiper'

import AppConfig from '../../../config/AppConfig';
import userService from '../../../services/user.service';
import { logout } from "../../../slices/auth";
import { loginLine } from "../../../slices/auth";
import { getAutoSuggestion } from 'thai-data';
import '../overview/style.css';

const OverviewAccount = ({ isMobile, redirectUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const SwiperRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0) //set default active tab is 0
    const [walletBalance, setWalletBalance] = useState(0);
    const [isLoading, setIsloading] = useState(true);

    const logOut = useCallback(() => {
        dispatch(logout());
        liff.logout();
    }, [dispatch]);

    //LINE LIFF initialization
    const initLine = () => {
        liff.init({ liffId: AppConfig.LIFF_ID }, async () => {
            if (liff.isLoggedIn()) {
                const email = liff.getDecodedIDToken().email;
                //console.log('Received Overview Acc:', email);
                if (!isLoggedIn) {
                    liff.getProfile().then(profile => {
                        dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl, email: email || profile.userId + '@gmail.com' }))
                            .unwrap()
                            .then(() => {
                                //navigate("/home");
                                window.location.reload();
                                setIsloading(false);
                            })
                            .catch(() => {
                                setIsloading(false);
                            });
                    }).catch(err => {
                        console.error(err);
                        setIsloading(false);

                    });
                } else {
                    //verify token
                    const response = await userService.getUserById(ownerId);
                    if (response.status == 403) {
                        logOut();
                        setIsloading(false);
                    }
                }
            }
        }, err => {
            console.error(err);
            setIsloading(false);
        });
    }

    useEffect(() => {
        initLine();
        // Set the document title when the component mounts
        document.title = `บัญชี`;
    }, []);


    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }
    }, []);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            setUserData(response.data[0]);
            setWalletBalance(response.data[0].points);
            formRef.current?.setFieldsValue({
                subdistirct: response.data[0].subdistirct,
            });
            setIsloading(false);
        } else {
            setIsloading(false);
        }
    }


    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const tabItems = [
        { key: 'overview', title: 'เมนู' },
        // { key: 'account', title: 'ประวัติ' },
    ]

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '50px', justifyContent: 'center', alignContent: 'center' }} />
                <Result
                    status='waiting'
                    title='กรุณารอสักครู่...'
                    description={<Button color='primary' fill='none'
                        onClick={() => {
                            logOut();
                            navigate('/login');
                        }}>ค้างนานเกินไป แตะที่นี่</Button>}
                />
            </div>
        </>
    }

    if (!isLoggedIn) {
        // Navigate to the destination route
        navigate('/login');
    }

    const Content = () => {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginTop: '30px',
                    marginBottom: '20px',
                    padding: '10px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    width: '100%',
                    background: '#e9ecef',
                    maxHeight: '50vh'
                }}>
                <Space block size="lg" direction='vertical' width={'100%'}>
                    <Card title={'คะแนนสะสม'}>
                        <div>
                            {/* <Space size="sm" /> */}
                            <h3>{walletBalance.toFixed(2)}</h3>
                            {/* <Space size="sm" /> */}
                        </div>
                    </Card>
                    <Card title={'บัญชีของฉัน'}>
                        <Swiper
                            direction='horizontal'
                            style={{ minHeight: '180px' }}
                            // loop
                            indicator={() => null}
                            ref={SwiperRef}
                            defaultIndex={activeIndex}
                            onIndexChange={index => {
                                setActiveIndex(index)
                            }}
                        >
                            <Swiper.Item>
                                <Grid columns={2} gap={12} style={{ marginTop: '20px', marginBottom: '30px' }}>
                                    <Grid.Item>
                                        <div className={'grid-demo-item-block'}>
                                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '100px', width: '100px', borderRadius: '20px', background: '#0058fd' }}
                                                onClick={() => navigate('/my-qrcode')}>
                                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                                    <ScanCodeOutline style={{ fontSize: 39 }} />
                                                    รับ Point
                                                </Space>
                                            </Button>
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className={'grid-demo-item-block'}>
                                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '100px', width: '100px', borderRadius: '20px', background: '#0058fd' }}
                                                onClick={() => navigate('/profile')}>
                                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                                    <UserOutline style={{ fontSize: 39 }} />
                                                    โปรไฟล์
                                                </Space>
                                            </Button>
                                            {/* <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '100px', width: '100px', borderRadius: '20px', background: '#0058fd' }}
                                             onClick={() => navigate('/confirm-payment')}
                                            >
                                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                                    <TravelOutline style={{ fontSize: 39 }} />
                                                    แจ้งชำระเงิน
                                                </Space>
                                            </Button> */}
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className={'grid-demo-item-block'}>
                                            {/* <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '100px', width: '100px', borderRadius: '20px', background: '#0058fd' }}
                                                onClick={() => navigate('/profile')}>
                                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                                    <UserOutline style={{ fontSize: 39 }} />
                                                    โปรไฟล์
                                                </Space>
                                            </Button> */}
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className={'grid-demo-item-block'}>
                                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '100px', width: '100px', borderRadius: '20px', background: '#0058fd' }}
                                                onClick={() => logOut()}>
                                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                                    <LockOutline style={{ fontSize: 39 }} />
                                                    ออกจากระบบ
                                                </Space>
                                            </Button>
                                        </div>
                                    </Grid.Item>
                                </Grid>

                            </Swiper.Item>
                        </Swiper>
                        <PageIndicator
                            total={2}
                            current={activeIndex}
                            style={{
                                '--dot-color': 'rgba(0, 0, 0, 0.4)',
                                '--active-dot-color': '#ffc0cb',
                                '--dot-size': '10px',
                                '--active-dot-size': '30px',
                                '--dot-border-radius': '50%',
                                '--active-dot-border-radius': '15px',
                                '--dot-spacing': '8px',
                            }}
                        />
                    </Card>
                </Space>

            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    minHeight: '95vh', // Set minimum height for the whole viewport
                    width: '100%',
                    padding: '6px',
                    minWidth: '100%',
                    background: '#e9ecef',
                }}>
                {userData && (
                    <>
                        <Content />
                    </>
                )}

            </div >
        </>
    );
};

export default OverviewAccount;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};