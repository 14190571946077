import React, { useState } from 'react';
import ProductService from '../../services/product.service';
import ProductCategoryService from '../../services/product-category.service';
import StoreService from '../../services/stores.service';
import EventService from '../../services/event.service';
import EventRegisterService from '../../services/event-register.service';
import PaymentConfirm from '../../services/payment.service';
import AdsService from '../../services/ads.service';
import { Input } from 'antd';

const { Search } = Input;
const SearchComponent = ({ mode, onSearch, storeId, eventId, ownerId, isAdmin }) => {
    const [query, setQuery] = useState('');

    const handleSearch = async (keyword) => {
        try {
            // console.log('storeId', storeId);
            // Perform the API request with the search query
            let response;
            if (mode == 'product') {
                response = await ProductService.getSearchByName(storeId, keyword);
            } else if (mode == 'product_category') {
                response = await ProductCategoryService.getSearchByName(storeId, keyword);
            } else if (mode == 'store') {
                if (isAdmin) {
                    response = await StoreService.adminGetSearchByName(ownerId, keyword);
                } else {
                    response = await StoreService.getSearchByName(ownerId, keyword);
                }
            } else if (mode == 'events') {
                if (isAdmin) {
                    response = await EventService.adminGetSearchByName(ownerId, keyword);
                } else {
                    response = await EventService.getSearchByName(ownerId, keyword);
                }
            } else if (mode == 'event-register') {
                response = await EventRegisterService.getSearchByName(eventId, keyword);
            } else if (mode == 'payment') {
                response = await PaymentConfirm.getSearchByName(eventId, keyword);
            } else if (mode == 'ads') {
                response = await AdsService.getSearchByName(keyword || ' ');
            }
            // Pass the search results to the parent component
            onSearch(response);
        } catch (error) {
            onSearch({ error: 'not found!' });
            //console.error('Error:', error);
        }
    };

    return (
        <div>
            <Search placeholder="พิมพ์คำที่ต้องการค้นหา" onSearch={handleSearch} enterButton />
        </div>
    );
};

export default SearchComponent;