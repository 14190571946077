import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Empty, Spin, Divider, Table, Form, Radio, Tooltip, InputNumber, Layout, message, Menu, Row, Col, Card, Drawer, Space, Button, Input, Select, Steps, theme, Typography, Result, App } from 'antd';
import { DeleteOutlined, ShoppingCartOutlined, CloseOutlined } from '@ant-design/icons' //antd design icons import
import { useForm } from 'antd/lib/form/Form';
import { Grid, List, NumberKeyboard, VirtualInput, Image, AutoCenter, ImageUploader, Toast, Modal, NoticeBar, Mask } from 'antd-mobile';
import { AddOutline, MinusOutline } from 'antd-mobile-icons'
import axios from 'axios';

//services
import userService from '../../services/user.service';
import OrderService from '../../services/order.service';
import ShopCartSerivce from '../../services/shopcart.service';


import { getAutoSuggestion } from 'thai-data';
import noImage from '../../assets/images/noImage.jpg';
import AppConfig from '../../config/AppConfig';
import styles from './styles.css';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Paragraph, Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 16,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};



const ShoppingCart = ({ cartData, onDelete, onUpdateCart, setCartData, rerenderKey }) => {
    const { token } = theme.useToken();
    const formRef = React.useRef(null);
    const [form] = useForm(); // Create a form instance
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [userData, setUserData] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [data, setData] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [current, setCurrent] = useState(0);
    const [payMethod, setPayMethod] = useState('CASH');
    const [grandTotal, setGrandTotal] = useState(0);
    const [grandDeliveryFee, setGrandDeliveryFee] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [orderData, setOrderData] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const zipcodeRef = React.useRef();

    //addres state
    const [zipCode, setZipCode] = useState('');
    const [subDistrict, setSubDistrict] = useState(Array);
    const [subDistrictSelect, setSubDistrictSelect] = useState('');
    const [district, setDistrict] = useState('');
    const [province, setProvince] = useState('');
    const [isDisabledSubDistrictSelect, setIsDisabledSubDistrictSelect] = useState(true);
    const [isShipping, setIsShipping] = useState(false);
    const [shippingMethod, setShippingMethod] = useState('รับเอง');
    const [paymentMethod, setPaymentMethod] = useState('TRANSFER');
    const [image, setImage] = useState(null); //หลักฐานการชำระเงิน
    const [isShowMask, setIsShowMask] = useState(false); //
    const [storeInfo, setStoreInfo] = useState(null);

    // Handle logistic method change
    const handleLogisticChange = (value) => {
        if (value === 'จัดส่ง') {
            setShippingMethod('จัดส่ง');
            setIsShipping(true);
        } else {
            setIsShipping(false);
            setShippingMethod('รับเอง');
        }
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };


    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)

        if (storeId) {
            setStoreId(storeId);
        }

        if (currentUser) {
            getUserData(currentUser.id);
            loadItems(currentUser.id);
        }

        // Set the document title when the component mounts
        document.title = `รถเข็น`;

    }, []);

    useEffect(() => {
        if (data) {
            getGrandTotal();
        }
    });

    const getUserData = async (userId) => {
        const response = await userService.getUserById(userId);
        if (response.status == 200) {
            const userdata = response.data[0];
            setUserData(userdata);
            onFill(userdata);
            setIsLoading(false);
        }
    }

    //get shopsCart by userId
    const loadItems = async (userId) => {
        const response = await ShopCartSerivce.getShopCartByUserId(userId);
        if (response) {
            //console.log('items loaded', response.data);
            setData(response.data);
            getGrandTotal();
        }
    }

    const handleIncrement = async (key, storeId, item) => {
        //console.log('item', item);
        const targetKey = key;
        const response = await ShopCartSerivce.handleIncrease(targetKey);
        if (response) {
            //console.log('updated', response);
            loadItems(currentUser.id);
        }
    };

    const handleDecrement = async (key, storeId, item) => {
        //console.log('item', item);
        const targetKey = key;
        const response = await ShopCartSerivce.handleDecrease(targetKey);
        if (response) {
            //console.log('updated', response);
            loadItems(currentUser.id);
        }
    };

    //ยอดรวมการซื้อสินค้า
    const total = data.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength - 3) + '...';
        }
        return text;
    }

    const onFinish = async (values) => {
        console.log('values', values);

        if (!image) {
            Modal.alert({
                title: 'Error',
                content: 'กรุณาชำระเงิน, กรุณาแนบหลักฐานการชำระเงิน !',
                showCloseButton: true,
                cancelText: 'ยกเลิก',
                confirmText: 'ตกลง',
            });
            setCurrent(current - 1);
            return;
        }

        Toast.show({
            icon: 'loading',
            content: 'รอสักครู่…',
        });

        setIsLoading(true);

        // save user information
        const userId = currentUser.id;
        const response = await userService.UpdateById(userId, { name: values.name, phone: values.phone });
        if (response.status == 200) {
            console.log('update success');
        }

        //console.log('order data:', { userId: currentUser.id, paymentMethod: values.paymentMethod, orders: data, shippingAddress: values });
        const orderInfo = {
            userId: currentUser.id,
            paymentMethod: paymentMethod,
            orders: data,
            shippingAddress: values,
            deliveryfee: AppConfig.DEFAULT_DELIVERY_FEE,
            logisticMethod: values.logisticMethod,
            customerNote: values.customerNote,
            paymentDoc: image[0].imageUrl
        };

        await OrderService.createOrder(orderInfo).then((response) => {
            setOrderData(response.data.data);
            //console.log('Order created', response);
            next();
            setIsSuccess(true);
            setIsLoading(false);
            setCartData([]);
            next();
        })
            .catch((error) => {
                console.error(error);
            });
    };

    const onReset = () => {
        formRef.current?.resetFields();
    };

    const onFill = (userdata) => {
        formRef.current?.setFieldsValue({
            name: userdata.name,
            phone: userdata.phone,
            address: userdata.address,
            zipcode: userdata.zipcode,
            district: userdata.district,
            subdistrict: userdata.subdistrict,
            province: userdata.province,
            paymentMethod: 'TRANSFER',
            logisticMethod: 'รับเอง'
        });
    };

    const onPayMethodChange = (e) => {
        console.log('radio checked', e.target.value);
        //setPayMethod(e.target.value);
    };

    const getGrandTotal = () => {
        let total = 0;
        let totalDeliveryFee = 0;
        data.map((item, index) => {
            total += item.totalAmount;
            totalDeliveryFee += AppConfig.DEFAULT_DELIVERY_FEE;
        });

        const grandTotalWithDeliveryFee = total + totalDeliveryFee;
        setGrandTotal(grandTotalWithDeliveryFee); //ยอดรวม + ค่าจัดส่งของแต่ละคำสั่งซื้อ
        setGrandDeliveryFee(totalDeliveryFee);
        setIsLoading(false);
    }

    const handleDeleteClick = (itemId) => {

        //console.log('cartData', data);

        const filteredData = data
            .map((store) => {
                // Use filter to remove items with _id equal to '651a242ae1224897f2da6cb0'
                const filteredItems = store.items.filter((item) => item._id !== itemId);

                if (filteredItems.length === 0) {
                    // If items are empty, do not include this store in the result
                    return null;
                }

                // Update the itemCount and totalAmount based on the filtered items
                const itemCount = filteredItems.length;
                const totalAmount = filteredItems.reduce((total, item) => total + item.total, 0);

                return {
                    ...store,
                    itemCount,
                    totalAmount,
                    items: filteredItems,
                };
            })
            .filter((store) => store !== null); // Remove stores with empty items

        //console.log('updated data', filteredData);
        if (data.length == 1) {
            setCartData([]);
        }
        onDelete(itemId); // Call the parent component's delete function
        setData(filteredData); //set local cart data
    };

    const ShoppingCart = (<>
        <div>
            {data.map((item, index) => (
                <>
                    <List mode='card'>
                        {item.items.map(product => {
                            let variantLabel = ''
                            const variantsArray = product.variants.map((variant, index) => {
                                variantLabel += variant.variantName + ': ' + variant.name + ' '
                            });

                            const productName = `${product.product_name} ${variantsArray > 0 ? ` (${variantLabel})` : ''}`;

                            return (
                                (
                                    <>
                                        <List.Item
                                            style={{ color: 'black' }}
                                            // title={'ราคา ' + product.price.toFixed(2)}
                                            // description={}
                                            extra={<Tooltip title="ลบ" style={{ display: 'flex', alignContent: 'flex-end' }}>
                                                <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger onClick={() => handleDeleteClick(product._id)} />
                                            </Tooltip>}>
                                            {productName} ({product.price.toFixed(2)})
                                            <div style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                <InputNumber
                                                    size='large'
                                                    addonBefore={<Button type="primary" shape="circle" icon={<MinusOutline />} onClick={() => handleDecrement(product._id, item.key, item)} />}
                                                    addonAfter={<Button type="primary" shape="circle" icon={<AddOutline />} onClick={() => handleIncrement(product._id, item.key, item)} />}
                                                    defaultValue={product.quantity ? product.quantity : 1}
                                                    value={product.quantity}
                                                />

                                            </div>
                                            <div style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                {'ราคา ' + product.price.toFixed(2)}
                                            </div>
                                        </List.Item>
                                    </>
                                )
                            )

                        })}

                    </List>
                </>
            ))}

            <>
                <Divider orientation="right"><b>ยอดรวมค่าจัดส่ง </b> {grandDeliveryFee ? grandDeliveryFee.toFixed(2) : '0.00'} บาท</Divider>
                <Divider orientation="right"><b>ยอดรวมทั้งสิ้น </b> {grandTotal.toFixed(2)} บาท</Divider>
                <Tooltip title="กดเพื่อดำเนินการสั่งซื้อ" style={{ display: 'flex', alignContent: 'flex-end' }}>
                    <Button type="primary" icon={<ShoppingCartOutlined />} style={{ width: '90%', height: '50px', borderRadius: '10px' }} onClick={() => {
                        if (data.length > 0) {
                            next();
                            onFill();
                        }
                    }}>ถัดไป</Button>
                </Tooltip>
            </>

        </div>
    </>
    )

    const onChange = (value) => {
        if (/^\d{0,5}$/.test(value)) {
            if (getAutoSuggestion(value).subDistrict) {
                setSubDistrict(getAutoSuggestion(value).subDistrict)
                // setIsDisabledSubDistrictSelect(false)
                formRef.current?.setFieldsValue({
                    subdistrict: getAutoSuggestion(value).subDistrict[0],
                });
                zipcodeRef.current.focus();
            }
        }
    };


    const autoSuggestion = (zipCode, subDistrict) => {
        const districtName = getAutoSuggestion(zipCode, subDistrict).districtName;
        const provinceName = getAutoSuggestion(zipCode, subDistrict).provinceName;
        setDistrict(districtName);
        setProvince(provinceName);
        formRef.current?.setFieldsValue({
            district: districtName,
            province: provinceName,
        });
    }

    const onSetDistrict = (subDistrict) => {
        //console.log(zipcodeRef.current.value);
        const zipcode = zipcodeRef.current.value;
        autoSuggestion(zipcode, subDistrict)
    }

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleUpload = (file) => {
        // console.log('uploaded file:', file);
        // you store them in state, so that you can make a http req with them later
        setImage(file);
    };

    async function mockUpload(file) {
        setIsShowMask(true);
        Toast.show({
            icon: 'loading',
            content: 'รอสักครู่…',
        });
        const formData = new FormData();
        formData.append("image", file);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            await sleep(3000)
            setIsShowMask(false);
            // Toast.removeAll();
            return {
                ...response.data,
                imageUrl: response.data.imageUrl,
                url: URL.createObjectURL(file),
            }
        } else {
            console.log("uploaded error");
            setIsShowMask(false);
            // Toast.removeAll();
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const LogisticForm = () => {

        if (!userData) {
            return <>
                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        minHeight: '80vh',
                    }}>
                    <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
                </div>
            </>
        }

        return (
            <>
                <Content type="flex" justify="center" style={{
                    minHeight: '20vh',
                    overflow: 'auto',
                    height: '100%',
                    width: '100%',
                    paddingTop: '55px',
                    paddingBottom: '80px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    backgroundColor: '#ffffff'
                }}>

                    <Form
                        {...layout}
                        ref={formRef}
                        form={form} // Pass the form instance to the Form component
                        initialValues={userData}
                        name="control-ref"
                        onFinish={onFinish}
                        style={{
                            maxWidth: 600,
                        }}
                    >
                        <Form.Item
                            name="name"
                            label="ชื่อ"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="เบอร์ติดต่อ"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="วิธีรับสินค้า"
                            name="logisticMethod"
                            rules={[{ required: false, message: 'เลือกวิธีจัดส่ง' }]}>
                            <Radio.Group
                                defaultValue="รับเอง"
                                onChange={val => {
                                    handleLogisticChange(val.target.value);
                                }}>
                                <Radio.Button value='รับเอง' checked={true}>ไปรับเอง</Radio.Button>
                                <Radio.Button value='จัดส่ง' disabled>จัดส่ง</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {isShipping && (
                            <>
                                <Form.Item
                                    label="บ้านเลขที่ หมู่ที่ ถนน"
                                    name="address"
                                    rules={[{ required: true, message: 'กรุณาป้อนบ้านเลขที่ หมู่ที่' }]}
                                >
                                    <Input placeholder='53 ม.11 ถ.สถิตย์นิมานกาล' />
                                </Form.Item>

                                <Form.Item
                                    label="รหัสไปรษณีย์"
                                    name="zipcode"
                                    style={{ textAlign: 'left' }} // Align the input to the left
                                    rules={[{ required: false, message: 'กรุณาป้อนรหัสไปรษณีย์' }]}>
                                    <InputNumber ref={zipcodeRef} placeholder="ป้อนรหัสไปรษณีย์ก่อน" onChange={onChange} style={{ width: '200px' }} />
                                </Form.Item>

                                <Form.Item
                                    label="ตำบล"
                                    name="subdistrict"
                                    style={{ textAlign: 'left' }} // Align the input to the left
                                    rules={[{ required: false, message: 'กรุณาเลือกตำบล' }]}>
                                    <Select
                                        labelInValue
                                        // disabled={isDisabledSubDistrictSelect ? true : false}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={e => {
                                            onSetDistrict(e.value);
                                            formRef.current?.setFieldsValue({
                                                subdistrict: e.value,
                                            });
                                        }}
                                        options={subDistrict.map((item, index) => {
                                            return { key: index, value: item }
                                        })}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="อำเภอ"
                                    name="district"
                                    rules={[{ required: false, message: 'กรุณาเลือกตำบล' }]}
                                >
                                    <Input placeholder="อำเภอ" readOnly />
                                </Form.Item>

                                <Form.Item
                                    label="จังหวัด"
                                    name="province"
                                    rules={[{ required: false, message: 'จังหวัด' }]}
                                >
                                    <Input placeholder="จังหวัด" readOnly />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            name="customerNote"
                            label="หมายเหตุอื่นๆ"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="paymentMethod"
                            label="วิธีชำระเงิน"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group
                                defaultValue="TRANSFER"
                                value={paymentMethod}
                                buttonStyle="solid"
                                onChange={(val) => {
                                    setPaymentMethod(val.target.value);
                                }}
                                style={{ alignContent: 'left', alignItems: 'left', textAlign: 'left' }}
                            >
                                <Radio.Button value="CASH" disabled>จ่ายด้วยเงินสด</Radio.Button>
                                <Radio.Button value="TRANSFER">เงินโอน</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <AutoCenter>
                            {paymentMethod === 'TRANSFER' && (
                                <>
                                    <Space wrap>
                                        {data[0].storeInfo && (
                                            <>
                                                <Image
                                                    width={250}
                                                    style={{ textAlign: 'center' }}
                                                    fit="scale-down"
                                                    src={'https://www.atom-soft.com/image-api/' + data[0].storeInfo.qrcode_payment} />
                                            </>
                                        )}
                                    </Space>
                                </>
                            )}
                            <Divider>รวม {grandTotal} บาท</Divider>
                        </AutoCenter>
                        {paymentMethod === 'TRANSFER' && <Divider>แนบหลักฐานการชำระเงิน</Divider>}
                        <NoticeBar content={'กรุณาแนบหลักฐานการชำระเงินที่หน้านี้ เพื่อสั่งซื้อสินค้า'} color='alert' />
                        <AutoCenter>
                            <Form.Item
                                label="กรุณาแนบหลักฐานการชำระเงิน"
                                name="paymentDoc"
                                rules={[{ required: true, message: 'กรุณาแนบหลักฐานสลิปโอนเงิน' }]}>
                                <ImageUploader
                                    style={{ '--cell-size': '90px' }}
                                    onChange={handleUpload}
                                    upload={mockUpload}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </AutoCenter>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('gender') === 'other' ? (
                                    <Form.Item
                                        name="customizeGender"
                                        label="Customize Gender"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Form>

                    <Tooltip title="ตรวจสอบข้อมูลให้เรียบร้อยก่อนกดสั่งซื้อ" style={{ display: 'flex', alignContent: 'flex-end' }}>
                        <Button type="primary" icon={<ShoppingCartOutlined />} style={{ width: '90%', height: '50px', borderRadius: '10px' }} onClick={() => {
                            if (data.length > 0) {
                                form.submit();
                            }
                        }}>สั่งซื้อ</Button>
                    </Tooltip>

                </Content >
            </>
        )

    }


    const Success = () => {

        if (!orderData) {
            return <>
                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        minHeight: '80vh',
                    }}>
                    <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
                </div>
            </>
        }

        //console.log('orderData', orderData);

        return (
            <>
                <Result
                    status="success"
                    title="ได้รับคำสั่งซื้อเรียบร้อย"
                    subTitle={`หมายเลขคำสั่งซื้อ: ${orderData[0] ? orderData[0].orderNumber : '-'}`}
                    extra={[
                        <Button type="primary" key="console" onClick={() => navigate('/home')}>
                            ไปที่หน้าแรก
                        </Button>, ,
                    ]}
                />
            </>
        )
    }

    const steps = [
        {
            title: 'รถเข็น',
            content: ShoppingCart,
        },
        {
            title: 'ข้อมูลจัดส่ง และวิธีชำระเงิน',
            content: <LogisticForm />,
        },
        {
            title: 'สำเร็จ',
            content: <></>,
        },
    ];


    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const contentStyle = {
        lineHeight: '80px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    if (data.length <= 0) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }}
                    description={
                        <span>
                            ยังไม่มีสินค้าในรถเข็น <a href={'#'} onClick={() => navigate('/store')}>เลือกซื้อสินค้า</a>
                        </span>
                    }
                />
            </div>
        </>
    }

    return (
        <>
            <Content type="flex" justify="center" style={{
                minHeight: '80vh',
                overflow: 'auto',
                height: '100%',
                width: '100%',
                paddingTop: '60px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingBottom: '5px',
            }}>
                <Mask visible={isShowMask} />
                {userData && (
                    <>
                        <Steps current={current} items={items} />
                        <div style={contentStyle}>{steps[current].content}</div>
                        {isSuccess && <Success />}
                    </>
                )}
                <div
                    style={{
                        marginTop: 20,
                    }}>

                    {current > 0 && current < steps.length - 1 && (
                        <Button
                            style={{
                                margin: '0 8px',
                                marginBottom: '50px'
                            }}
                            onClick={() => prev()}
                        >
                            ก่อนหน้า
                        </Button>
                    )}
                </div>
            </Content>
        </>
    );
};

export default ShoppingCart;
