import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Space, Divider, Card, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined, SearchOutlined } from '@ant-design/icons';
import AppConfig from '../../config/AppConfig';
import liff from '@line/liff';
import { login, loginLine } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { useObject } from '../../globalState/ObjectContextProvider'; // Import your context

const SignInScreen = ({ redirectUrl }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    //global state
    const { object } = useObject(); //for retrieving navigate parameters
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);

    //LINE LOGIN
    const [pictureUrl, setPictureUrl] = useState('');
    const [idToken, setIdToken] = useState("");
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");
    const [redirectUri, setRedirectUri] = useState(null);

    useEffect(() => {
        // Extract the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUri = urlParams.get('redirectUri');
        setRedirectUri(redirectUri);
    }, []);

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const onLogin = (values) => {
        //console.log('Received values:', values);
        const { username, password } = values;
        const user = username.trim();
        const pass = password.trim();
        setLoading(true);
        dispatch(login({ username, password }))
            .unwrap()
            .then(() => {
                if (redirectUrl) {
                    navigate(redirectUrl);
                } else {
                    navigate('/home');
                }
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });
    };


    const handleLineLogin = async () => {
        setLoading(true);
        if (!liff.isLoggedIn()) {
            liff.login()
                .then(() => {
                    if (redirectUri) {
                        navigate(redirectUri);
                    } else {
                        navigate(`/home`);
                    }
                });
        };
    }

    const initLine = () => {
        liff.init({ liffId: AppConfig.LIFF_ID }, () => {
            if (liff.isLoggedIn()) {
                const email = liff.getDecodedIDToken().email;
                setEmail(email);
                if (!isLoggedIn) {
                    liff.getProfile().then(profile => {
                        dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl, email: email }))
                            .unwrap()
                            .then(() => {
                                if (redirectUri) {
                                    navigate(redirectUri);
                                } else {
                                    navigate(`${redirectUri ? redirectUri : '/home'}`);
                                }
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    }).catch(err => console.error(err));
                }
            }
        }, err => console.error(err));
    }


    useEffect(() => {
        initLine();
    }, []);


    if (isLoggedIn) {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUri = urlParams.get('redirectUri');
        return <Navigate to={`${redirectUri ? redirectUri : '/home'}`} />;
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px',
                minHeight: '60vh', // Set minimum height for the whole viewport

            }}
        >
            <Card title="เข้าสู่ระบบ" style={{ width: 300, borderRadius: 20 }}>
                <Form
                    name="signin"
                    onFinish={onLogin}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'กรุณาป้อนชื่อผู้ใช้' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="ชื่อผู้ใช้" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'กรุณาป้อนรหัสผ่าน' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="รหัสผ่าน" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%', height: '50px' }}>
                            เข้าสู่ระบบ
                        </Button>
                    </Form.Item>
                </Form>

                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Button type="primary" style={{ backgroundColor: '#06C755', color: '#ffffff', height: '50px', width: '100%' }}
                        onClick={handleLineLogin}
                    >
                        เข้าสู่ระบบด้วย LINE
                    </Button>
                    <Divider plain>หรือว่า</Divider>
                    <Button type="link" style={{ height: '50px', width: '100%' }}
                        onClick={() => navigate('/register')}
                    >
                        สมัครใช้งาน
                    </Button>
                </Space>

                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}

            </Card>
        </div>
    );
};

export default SignInScreen;