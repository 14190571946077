import { Image } from 'antd';

export default [
  {
    title: 'โลโก้',
    dataIndex: 'logo',
    key: 'logo',
  },
  {
    title: 'ชื่อร้าน',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'ที่อยู่',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'เจ้าของร้าน',
  },
];