import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Card, Form, Input, Button, Upload, message, Avatar, Layout, Col, Row, notification, Menu, Spin } from 'antd';
import { Tabs, Badge, Swiper, CapsuleTabs } from 'antd-mobile'
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import { AutoCenter, FloatingPanel } from 'antd-mobile'
import userService from '../../services/user.service';
import MyAccount from './myAccount';
import MyOrders from './myOrders';
import MyNotifications from './notifications';
import OverviewAccount from './overview';

import { SwiperRef } from 'antd-mobile/es/components/swiper'

const { Header, Footer, Sider, Content } = Layout;
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem('บัญชีผู้ใช้', 'menu', <HomeOutlined />, [
        getItem((<Link to={"/user/user-account"}>บัญชีของฉัน</Link>), 'user-account'),
        getItem((<Link to={"/user/user-orders"}>ประวัติ</Link>), 'user-orders'), 
        // getItem(<Link to={"/user/user-notifications"}>การแจ้งเตือน</Link>, 'user-notifications'),
    ]),
    {
        type: 'divider',
    },
];

const ProfileEditScreen = () => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(true);
    const [pathName, setPathName] = useState('user-account');
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [deviceType, setDeviceType] = useState("");
    const SwiperRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                var UA = navigator.userAgent;
                hasTouchScreen =
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }
        if (hasTouchScreen) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }
    }, []);

    useEffect(() => {
        // setIsloading(true);
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const pathParam = parts[parts.length - 1]; // Retrieve the last part 
        setPathName(pathParam);
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }
    }, [currentUser]);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            //console.log('currentUser', response.data[0]);
            setUserData(response.data[0]);
        }
        setIsloading(false);
    }

    const onFinish = async (values) => {
        //console.log('Form values:', values);
        const userId = currentUser.id;
        const response = await userService.UpdateById(userId, values);
        if (response.status == 200) {
            successNotification('topRight')
        }
    };

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };


    const onMenuClick = (e) => {
        setPathName(e.key);
    };

    if (!isLoggedIn) {
        navigate('/login');
    }

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    const anchors = [100, window.innerHeight * 0.4, window.innerHeight * 0.8]
    const tabItems = [
        { key: 'account', title: 'ข้อมูลส่วนตัว', content: <MyAccount /> },
        { key: 'notifications', title: 'แจ้งเตือน', content: <MyNotifications /> },
    ]

    return (
        <>
            <Content type="flex" justify="center" style={{
                // minHeight: '50vh',
                overflow: 'auto',
                height: '99%',
                width: '100%',
                paddingTop: deviceType == 'Mobile' ? '50px' : '0px',
                paddingBottom: deviceType == 'Mobile' ? '60px' : '0px'
                // background: '#ffffff'
            }}>
                {contextHolder}
                <Layout>
                    {deviceType == 'Desktop' && (
                        <>
                            <Sider style={siderStyle}>
                                {/* Sider */}
                                <Avatar
                                    size={{
                                        xs: 24,
                                        sm: 32,
                                        md: 40,
                                        lg: 64,
                                        xl: 80,
                                        xxl: 100,
                                    }}
                                    src={userData ? `https://www.atom-soft.com/image-api/${userData.pictureUrl}` : null}
                                // icon={<AntDesignOutlined />}
                                />
                                <Menu
                                    onClick={onMenuClick}
                                    theme="light"
                                    style={{ height: '100%', borderRight: 0, left: 0, position: 'absolute', background: '#ffffff', }}
                                    defaultSelectedKeys={['menu']}
                                    defaultOpenKeys={['menu', 'settings']}
                                    mode="inline"
                                    inlineCollapsed={true}
                                    items={items}
                                />
                            </Sider>
                        </>)}
                    <Content style={contentStyle}>
                        {/* Content */}
                        {deviceType == 'Desktop' ? (
                            <>
                                {pathName == 'profile' && (<MyAccount />)}
                                {pathName == 'user-account' && (<MyAccount />)}
                                {pathName == 'user-orders' && (<MyOrders />)}
                                {pathName == 'user-notifications' && (<MyNotifications />)}
                            </>) : (
                            <>
                                <MyAccount />
                                {/* <Tabs
                                    activeKey={tabItems[activeIndex].key}
                                    onChange={key => {
                                        const index = tabItems.findIndex(item => item.key === key)
                                        setActiveIndex(index)
                                        SwiperRef.current?.swipeTo(index)
                                    }}
                                >
                                    {tabItems.map(item => (
                                        <Tabs.Tab title={item.title} key={item.key} children={item.content} />
                                    ))}
                                </Tabs> */}
                                {/* <Swiper
                                    direction='horizontal'
                                    loop
                                    indicator={() => null}
                                    ref={SwiperRef}
                                    defaultIndex={activeIndex}
                                    onIndexChange={index => {
                                        setActiveIndex(index)
                                    }}
                                >
                                    <Swiper.Item>
                                        <MyAccount />
                                    </Swiper.Item>
                                    <Swiper.Item>
                                        <MyOrders />
                                    </Swiper.Item>
                                    <Swiper.Item>
                                        <MyNotifications />
                                    </Swiper.Item>

                                </Swiper> */}
                            </>
                        )}
                    </Content>
                </Layout>
            </Content>
        </>
    );
};

export default ProfileEditScreen;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    // backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    // minHeight: '100%',
    lineHeight: '120px',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};