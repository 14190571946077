import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Layout, Spin, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import AppConfig from '../../../config/AppConfig';
import ProductService from '../../../services/product.service';
import ProductCategoryService from '../../../services/product-category.service';

const defaultCategoryId = AppConfig.DEFAULT_PRODUCT_CATEGORY_ID;

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const EditForm = ({ initialValues, isEdit, form, onFinish, fileList, setFileList }) => {

    const [formReady, setFormReady] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState();
    const formRef = React.useRef(form);
    const [categorys, setCategorys] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false); //drawer add new product
    const [id, setId] = useState(null);

    useEffect(() => {

        if (initialValues) {
            setId(initialValues._id);
            loadData(initialValues._id);
        }


        //console.log(initialValues);
        if (initialValues.image[0].imageUrl === null) {
            setFileList([]);
        } else {
            setFileList([
                {
                    uid: '-1',
                    name: 'image1.png',
                    status: 'done',
                    url: initialValues.image[0].imageUrl ? `http://www.atom-soft.com/image-api/${initialValues.image[0].imageUrl}` : '', // Replace with actual image URLs
                }]);
        }


    }, []);

    const loadData = async (productId) => {
        setIsLoading(true);
        const response = await ProductService.getById(productId);
        //console.log('products', productId, response);
        if (response.status == 200) {
            // setData(response.data);
            // onFill(response.data);
            setData(initialValues);
            onFill(initialValues);

            setIsLoading(false);
            // Simulate an API call to fetch the selectedItem
            setTimeout(() => {
                setFormReady(true);
            }, 1000); // Adjust the delay as needed
        } else {
            setData(null);
            setIsLoading(false);
            setFormReady(true);
            form.setFieldsValue({variants: []});
        }
        getCategory(initialValues.storeId[0]);
    }

    const onFill = (values) => {
        form.setFieldsValue(values);
    };

    const getCategory = async (storeId) => {
        const categoryRes = await ProductCategoryService.getAllByStoreId(storeId);
        if (categoryRes.status == 200) {
            const categoryMenu = categoryRes.data.map((item) => {
                return { label: item.name, value: item._id };
            })
            setCategorys(categoryMenu);
            setIsLoading(false);
        } else {
            setCategorys([]);
            setIsLoading(false);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มข้อมูลใหม่เรียบร้อย`,
            description:
                'เพิ่มบัญชีผู้ใช้ใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    if (!formReady) {
        return (
            <>
                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        minHeight: '80vh',
                    }}>
                    <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
                </div>
            </>
        )
    }

    return (
        <>
            {contextHolder}
            {data && (
                <>
                    <Form
                        ref={formRef}
                        onFinish={onFinish}
                        //initialValues={data}
                        form={form}
                        layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="ชื่อสินค้า"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาป้อนชื่อสินค้า',
                                        },
                                    ]}
                                >
                                    <Input placeholder="ปลากระป๋องตราสามแม่ครัว" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="category_id"
                                    label="หมวดหมู่สินค้า"
                                    rules={[
                                        { required: true, message: 'กรุณาเลือกหมวดหมู่สินค้า' },
                                    ]}>
                                    <Select placeholder="กรุณาเลือกหมวดหมู่" onChange={(v, item) => setSelectedCategory(item.name)}>
                                        <Option value={defaultCategoryId} key={defaultCategoryId}>ไม่ระบุ</Option>
                                        {
                                            categorys.map(function (category) {
                                                return (defaultCategoryId === category.value ? null : <Option key={category.value} value={category.value} name={category.label}>{category.label}</Option>)
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="barcode"
                                    label="บาร์โค้ดสินค้า"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาป้อนรหัสบาร์โค้ดสินค้า หรือใช้เครื่องสแกน',
                                        },
                                    ]}
                                >
                                    <Input placeholder="8850153333058" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="unit_in_stock"
                                    label="จำนวนคงเหลือ"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาป้อนจำนวนคงเหลือ',
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder="100" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="cost"
                                    label="ราคาต้นทุน"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาป้อนราคาต้นทุน',
                                        },
                                    ]}
                                >
                                    <Input placeholder="90.50" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="price"
                                    label="ราคาขาย"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาป้อนราคาขายสินค้า',
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder="120" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="pxpos_primarykey"
                                    label="Primary Key สินค้าของโปรแกรม PXPOS"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'กรุณาป้อน Primary Key สินค้าในโปรแกรม PXPOS',
                                        },
                                    ]}
                                >
                                    <Input placeholder="1" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="image"
                                    label="รูปสินค้า"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'กรุณาเลือกรูปสินค้า',
                                        },
                                    ]}
                                >
                                    <Upload
                                        listType="picture-card"
                                        maxCount={1}
                                        fileList={fileList}
                                        onChange={handleUpload}
                                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: '30px' }}>
                            <Form.List name="variants">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            rowGap: 8,
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {fields.map((field) => (
                                            <Card
                                                style={{ minWidth: '70vh' }}
                                                size="small"
                                                title={`ตัวเลือกสินค้า ${field.name + 1}`}
                                                key={field.key}
                                                extra={
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                    />
                                                }
                                            >
                                                <Form.Item style={{ display: 'none' }} name={[field.name, 'product']}>
                                                    <Input value={'123'} />
                                                </Form.Item>

                                                <Form.Item label="ชื่อตัวเลือก" placeholder="เช่น สี ไซส์...." name={[field.name, 'name']}>
                                                    <Input placeholder="เช่น สี ไซส์...." />
                                                </Form.Item>

                                                {/* Nest Form.List */}
                                                <Form.Item label="ตัวเลือก" placeholder="เช่น สีแดง สีเขียว L M XL....">
                                                    <Form.List name={[field.name, 'options']}>
                                                        {(subFields, subOpt) => (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    rowGap: 16,
                                                                }}
                                                            >
                                                                {subFields.map((subField) => (
                                                                    <Space key={subField.key}>
                                                                        <Form.Item style={{ display: 'none' }} name={[subField.name, 'product']}>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item noStyle name={[subField.name, 'name']}>
                                                                            <Input label="ชื่อตัวเลือก" placeholder="เช่น L" />
                                                                        </Form.Item>
                                                                        <Form.Item noStyle label="ราคา" name={[subField.name, 'price']}>
                                                                            <Input placeholder="เช่น 250" />
                                                                        </Form.Item>
                                                                        <Form.Item noStyle label="คลัง" name={[subField.name, 'quantity']}>
                                                                            <Input placeholder="เช่น 15" />
                                                                        </Form.Item>
                                                                        <Form.Item noStyle label="เลข SKU" name={[subField.name, 'sku']}>
                                                                            <Input placeholder="หมายเลข sku หรือบาร์โค้ด" />
                                                                        </Form.Item>
                                                                        <CloseOutlined
                                                                            onClick={() => {
                                                                                subOpt.remove(subField.name);
                                                                            }}
                                                                        />
                                                                    </Space>
                                                                ))}
                                                                <Button type="dashed" onClick={() => subOpt.add()} block>
                                                                    + เพิ่มตัวเลือกย่อย
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </Form.List>
                                                </Form.Item>
                                            </Card>
                                        ))}

                                        <Button type="dashed" onClick={() => add()} block>
                                            + เพิ่มตัวเลือกสินค้า
                                        </Button>
                                    </div>
                                )}
                            </Form.List>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="คำอธิบายย่อ"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'ป้อนรายละเอียดคำอธิบายย่อ',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} placeholder="ป้อนรายละเอียดคำอธิบายย่อ" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}
        </>
    );
};

export default EditForm;
