import React, { useState, useEffect } from 'react';
import { Image, Layout, Row, Col, Card, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Spin, InputNumber, Upload } from 'antd';
import { PlusOutlined, CloseOutlined, SearchOutlined, RadiusUprightOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import EventService from '../../../services/event.service';
import columns, { Title } from './config';
import CreateForm from './createForm';
import SearchComponent from '../../../common/TableSearch';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;

const EventsList = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false); //drawer add new product
    const [ownerId, setOwnerId] = useState(null);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [fileList, setFileList] = useState(null);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        onReset();
    };


    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }

    }, []);

    const loadData = async (ownerId) => {
        let response;
        const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
        if (isAdmin) {
            response = await EventService.getAll();
        } else {
            response = await EventService.getAllByOwnerId(ownerId);
        }
        //console.log('eventDetial', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มกิจกรรมใหม่เรียบร้อย`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const onFinish = async (values) => {
        console.log(values);

        const response = await EventService.create(values);
        if (response) {
            console.log(response);
            successNotification('topRight')
        }

    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const onSearch = (value, _e, info) => console.log(info?.source, value);

    columns[0].render = (text, record) => <Image src={`https://www.atom-soft.com/image-api/${record.cover ? record.cover : './logos/NoImage.png'}`} style={{ maxWidth: '50px' }} />
    columns[1].render = (text, record) => <a onClick={() => navigate('/admin/events-detail/' + record._id)} style={{ color: '#0300a8' }}>{record.name}</a>
    columns[4].render = (text, record) => <a>{record.owner[0] ? record.owner[0].username : '-'}</a>

    const handleSearch = (results) => {
        if (results.error) {
            loadData(ownerId);
        } else {
            setData(results.data);
        }
    };

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };


    return (
        <>
            {contextHolder}
            <Drawer
                title="สร้างกิจกรรมใหม่"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}>
                <CreateForm onOK={() => {
                    onClose();
                    loadData(ownerId);
                    successNotification('topRight')
                }}
                    destroyOnClose={true}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh', margin: 30 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'events'} ownerId={ownerId} isAdmin={isAdmin} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                            //onClick={() => navigate('/add-store')}
                            onClick={() => showDrawer(true)}
                            >
                                เพิ่มกิจกรรม
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card style={{ width: '100%', }} title={Title}>
                                <Table 
                                dataSource={data} 
                                columns={columns} 
                                rowKey={'_id'}
                                locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default EventsList;
