import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Spin, InputNumber, Select, Popover, Card, Form, Input, Button, Upload, message, Avatar, Layout, Col, Row, notification, Menu, Typography } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import userService from '../../../services/user.service';
import { getAutoSuggestion } from 'thai-data';
import axios from 'axios';

const MyAccount = ({ isMobile }) => {
    const formRef = React.useRef(null);
    const zipcodeRef = React.useRef();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsloading] = useState(true);
    const [profileImage, setProfileImage] = useState(null);
    const [fileList, setFileList] = useState([]);

    //addres state
    const [zipCode, setZipCode] = useState('')
    const [subDistrict, setSubDistrict] = useState(Array)
    const [subDistrictSelect, setSubDistrictSelect] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [isDisabledSubDistrictSelect, setIsDisabledSubDistrictSelect] = useState(true)

    useEffect(() => {
        //console.log('useEffect called');
        if (currentUser) {
            const ownerId = currentUser.id;
            loadData(ownerId);
        }
    }, [currentUser]);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            console.log('user data loaded successfully', response.data[0]);
            setUserData(response.data[0]);
            if (response.data[0].pictureUrl) {
                setProfileImage(response.data[0].pictureUrl)
            }
            formRef.current?.setFieldsValue({
                subdistirct: response.data[0].subdistirct,
            });
            setIsloading(false);
        }
    }

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'user');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUrl: response.data.imageUrl };
        } else {
            console.log("uploaded error");
        }
    };


    const onFinish = async (values) => {
        // Handle form submission here (e.g., update user data)
        console.log('Form values:', values);
        const oldCover = userData.pictureUrl;
        const userImage = fileList.length >= 1 ? fileList[0].originFileObj ? await uploadImage() : { imageUrl: oldCover } : { imageUrl: oldCover };
        const userId = currentUser.id;
        const response = await userService.UpdateById(userId, { ...values, pictureUrl: userImage.imageUrl });
        if (response.status == 200) {
            successNotification('topRight')
        }
    };

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };


    const onChange = (value) => {
        if (/^\d{0,5}$/.test(value)) {
            if (getAutoSuggestion(value).subDistrict) {
                setSubDistrict(getAutoSuggestion(value).subDistrict)
                // setIsDisabledSubDistrictSelect(false)
                formRef.current?.setFieldsValue({
                    subdistrict: getAutoSuggestion(value).subDistrict[0],
                });
                zipcodeRef.current.focus();
            }
        }
    };


    const autoSuggestion = (zipCode, subDistrict) => {
        const districtName = getAutoSuggestion(zipCode, subDistrict).districtName;
        const provinceName = getAutoSuggestion(zipCode, subDistrict).provinceName;
        setDistrict(districtName);
        setProvince(provinceName);
        formRef.current?.setFieldsValue({
            district: districtName,
            province: provinceName,
        });
    }

    const onSetDistrict = (subDistrict) => {
        //console.log(zipcodeRef.current.value);
        const zipcode = zipcodeRef.current.value;
        autoSuggestion(zipcode, subDistrict)
    }

    const CardItem = ({ children }) => {
        return <Card title="โปรไฟล์" style={{ display: 'block', justifyContent: 'center', margin: '0 auto' }} span={24}>
            {children}
        </Card>
    }

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // Get the URL of the image from the file object
        const imageUrl = URL.createObjectURL(fileList[0].originFileObj);
        // you store them in state, so that you can make a http req with them later
        setProfileImage(imageUrl);
        setFileList(fileList);
    };

    const regex = /^https:\/\//;

    const Content = () => {
        return <>
            {regex.test(userData.pictureUrl) ? (
                <>
                    <Avatar size={100}
                        // icon={<UserOutlined />}
                        src={userData.pictureUrl ? userData.pictureUrl : null}
                        style={{
                            display: 'block ',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '50px'
                        }} />
                </>
            ) : (
                <>
                    <Avatar size={100}
                        // icon={<UserOutlined />}
                        src={userData.pictureUrl ? `https://www.atom-soft.com/image-api/${userData.pictureUrl}` : null}
                        style={{
                            display: 'block ',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '50px'
                        }} />
                </>
            )

            }

            <Form
                ref={formRef}
                form={form}
                name="editProfile"
                initialValues={userData}
                onFinish={onFinish}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 12 }}>
                <Form.Item label="รูปโปรไฟล์" name="profileImage">
                    <Upload
                        name="pictureUrl"
                        action="/api/upload-profile-image" // Replace with your upload API endpoint
                        listType="picture"
                        showUploadList={false}
                        onChange={handleUpload}
                    >
                        <Button icon={<UploadOutlined />}>อัพโหลด</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="อีเมล์"
                    name="email"
                    rules={[
                        { required: true, message: 'ป้อนอีเมล์' },
                        { type: 'email', message: 'รูปแบบอีเมล์ไม่ถูกต้อง !' },
                    ]}
                >
                    {userData.email ? (
                        <Input readOnly />
                    ) : (
                        <Input />
                    )}
                </Form.Item>

                <Form.Item
                    label="ชื่อ"
                    name="name"
                    rules={[{ required: true, message: 'กรุณาป้อนชื่อ' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="เบอร์โทร"
                    name="phone"
                    rules={[{ required: true, message: 'กรุณาป้อนเบอร์โทรศัพท์' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="บ้านเลขที่ หมู่ที่"
                    name="address"
                    rules={[{ required: false, message: 'กรุณาป้อนบ้านเลขที่ หมู่ที่' }]}
                >
                    <Input placeholder='53 ม.11' />
                </Form.Item>

                <Form.Item
                    label="รหัสไปรษณีย์"
                    name="zipcode"
                    style={{ textAlign: 'left' }} // Align the input to the left
                    rules={[{ required: false, message: 'กรุณาป้อนรหัสไปรษณีย์' }]}>
                    <InputNumber ref={zipcodeRef} placeholder="รหัสไปรษณีย์" onChange={onChange} style={{ width: '200px' }} />
                </Form.Item>

                <Form.Item
                    label="ตำบล"
                    name="subdistrict"
                    style={{ textAlign: 'left' }} // Align the input to the left
                    rules={[{ required: false, message: 'กรุณาเลือกตำบล' }]}>
                    <Select
                        labelInValue
                        // disabled={isDisabledSubDistrictSelect ? true : false}
                        style={{
                            width: '100%',
                        }}
                        onChange={e => {
                            onSetDistrict(e.value);
                            formRef.current?.setFieldsValue({
                                subdistrict: e.value,
                            });
                        }}
                        options={subDistrict.map((item, index) => {
                            return { key: index, value: item }
                        })}
                    />
                </Form.Item>

                <Form.Item
                    label="อำเภอ"
                    name="district"
                    rules={[{ required: false, message: 'กรุณาเลือกตำบล' }]}
                >
                    <Input placeholder="อำเภอ" readOnly />
                </Form.Item>

                <Form.Item
                    label="จังหวัด"
                    name="province"
                    rules={[{ required: false, message: 'จังหวัด' }]}
                >
                    <Input placeholder="จังหวัด" readOnly />
                </Form.Item>

                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                    }}>
                    <Button type="primary" htmlType="submit" style={{ width: '250px' }}>
                        บันทึก
                    </Button>
                </div>
            </Form>
        </>
    }


    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }


    return (
        <>
            {contextHolder}
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    minHeight: '80vh', // Set minimum height for the whole viewport
                    width: '100%',
                    padding: '6px',
                }}>
                {userData && (
                    <>
                        {isMobile ? (
                            <>
                                <Content />
                            </>
                        ) : (
                            <CardItem>
                                <Content />
                            </CardItem>
                        )}
                    </>
                )}

            </div >
        </>
    );
};

export default MyAccount;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};