import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}`;

//api/payment
const create = (value) => {
  return axios.post(API_URL + 'payment/', value, { headers: authHeader() });
};

const getPublic = () => {
  return axios.get(API_URL + "/all");
};

const getById = (id) => {
  return axios.get(AppConfig.API_URL + 'payment/' + id, { headers: authHeader() });
};

const getAllByEventId = (eventId) => {
  return axios.get(AppConfig.API_URL + 'payment/' + eventId, { headers: authHeader() });
};

const getSearchByName = (eventId, keyword) => {
  return axios.get(AppConfig.API_URL + `payment/${keyword}`, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL+ `payment/`, { headers: authHeader() });
};

const updateById = (id, ownerId, values) => {
  return axios.put(AppConfig.API_URL + 'payment/' + id, { ...values }, { headers: authHeader() });
};

const PaymentConfirm = {
  create,
  getPublic,
  getAll,
  getAllByEventId,
  getById,
  getSearchByName,
  updateById
};

export default PaymentConfirm;