import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ObjectProvider } from './globalState/ObjectContextProvider'; //for global state pass object data between route
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById("root");
const root = createRoot(container);

document.body.style = 'background: #f4f7fa;';

const AppWithRouter = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ObjectProvider>
        <App />
      </ObjectProvider>
    </Provider>,
  </BrowserRouter>
);

ReactDOM.render(<AppWithRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
