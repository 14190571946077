import React, { useState, useEffect } from 'react';
import { DatePicker, Spin, Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, InputNumber, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import locale from 'antd/es/date-picker/locale/th_TH';
import AppConfig from '../../../config/AppConfig';

import axios from 'axios';
import EventService from '../../../services/event.service';
import userService from '../../../services/user.service';
import StoreService from '../../../services/stores.service';

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 16,
    },
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const StoreSetting = ({ onOK, initialValues }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'logo.png',
            status: 'done',
            url: initialValues.logo ? `http://www.atom-soft.com/image-api/${initialValues.logo}` : '', // Replace with actual image URLs
        }]);
    const [fileList2, setFileList2] = useState([
        {
            uid: '-1',
            name: 'logo.png',
            status: 'done',
            url: initialValues.qrcode_payment ? `http://www.atom-soft.com/image-api/${initialValues.qrcode_payment}` : '', // Replace with actual image URLs
        }]);
    const [userList, setUserList] = useState([]);
    const [eventDate, setEventDate] = useState('2023-10-01');
    const [selectedDates, setSelectedDates] = useState(dayjs('2023-09-29T00:00:00').tz('Asia/Bangkok'));

    useEffect(() => {
        if (currentUser) {
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_OWNER"));
            getUserList();
            const eventDate = dayjs(initialValues.event_date, AppConfig.DATE_FORMAT);
            setEventDate(eventDate);
            initialValues.event_date = eventDate;
            setLoading(false);
        }

    }, []);


    const getCoverImage = async (initialValues) => {
        const uploadedCover = initialValues.logo ? [
            {
                uid: '-1',
                name: 'image1.png',
                status: 'done',
                url: initialValues.logo ? initialValues.logo : '', // Replace with actual image URLs
            }] : [];
        return uploadedCover;
    }

    const getUserList = async () => {
        const response = await userService.getUser();
        if (response.status == 200) {
            //map user array to options         
            const userItems = response.data.map((user) => {
                return { label: user.name ? user.name : user.username, value: user._id };
            })
            setUserList(userItems);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `แก้ไขข้อมูลเรียบร้อย`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUrl: response.data.imageUrl };
        } else {
            console.log("uploaded error");
        }
    };

    const uploadImage2 = async () => {
        const formData = new FormData();
        formData.append("image", fileList2[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded payment Image success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUrl: response.data.imageUrl };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        console.log(values);
        const storeId = initialValues._id;
        const ownerId = values.owner ? values.owner : currentUser.id;
        const oldCover = values.cover;
        const oldPayment = values.paymentqr;
        const eventDate = dayjs(values.event_date).tz('Asia/Bangkok')
        const productImage = fileList.length >= 1 ? fileList[0].originFileObj ? await uploadImage() : { imageUrl: oldCover } : { imageUrl: null };
        const qrcode_payment = fileList2.length >= 1 ? fileList2[0].originFileObj ? await uploadImage2() : { imageUrl: oldPayment } : { imageUrl: null };

        const response = await StoreService.updateById(storeId, { ...values, logo: productImage.imageUrl, qrcode_payment: qrcode_payment.imageUrl, owner: ownerId });
        if (response) {
            console.log('update success', response);
            successNotification('topRight');
        }
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getFile = (e) => {
        //console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    const handleUpload2 = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList2(fileList);
    };

    const handleDefaultValue = () => {
        if (selectedDates) {
            return dayjs(eventDate, AppConfig.DATE_FORMAT);
        }
        return [dayjs(), dayjs()];
    };

    if (loading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            <Form
                {...layout}
                form={form}
                initialValues={initialValues}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                    marginTop: 30,
                    margin: '50 auto',
                }}
            >
                <Form.Item
                    name="name"
                    label="ชื่อร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ATOMSOFT" />
                </Form.Item>

                <Form.Item
                    name="address"
                    label="ที่อยู่ร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} placeholder={"53 ม.11 บ.ดวงเจริญ ต.ธาตุ อ.วารินชำราบ \nจ.อุบลราชธานี 34190"} />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="คำอธิบายร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} placeholder={"ขายสินค้าอุปโภค บริโภค และอื่นๆ"} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="เบอร์โทร"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="09465495xx, 08072926xx" />
                </Form.Item>

                <Divider>ข้อมูลการชำระเงิน</Divider>
                <Form.Item
                    name="bank_name"
                    label="ธนาคาร"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="กรุงไทย" />
                </Form.Item>

                <Form.Item
                    name="bank_acc_name"
                    label="ชื่อบัญชี"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ป้อนชื่อบัญชีธนาคาร" />
                </Form.Item>

                <Form.Item
                    name="bank_acc_no"
                    label="หมายเลขบัญชี"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ป้อน หมายเลขบัญชี" />
                </Form.Item>

                <Form.Item
                    name="bank_acc_branch"
                    label="สาขา"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="สาขา" />
                </Form.Item>


                <Form.Item
                    name="paymentqr"
                    label="รูปคิวอาร์โค้ดชำระเงิน"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    getValueFromEvent={getFile}
                >
                    <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={fileList2}
                        previewFile={false}
                        onChange={handleUpload2}
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                อัพโหลดรูป
                            </div>
                        </div>
                    </Upload>
                </Form.Item>

                <Divider>ข้อมูลการชำระเงิน</Divider>

                <Form.Item
                    name="cover"
                    label="รูปหน้าปกร้าน"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    getValueFromEvent={getFile}
                >
                    <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={fileList}
                        previewFile={false}
                        onChange={handleUpload}
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                อัพโหลดรูป
                            </div>
                        </div>
                    </Upload>
                </Form.Item>



                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" style={{ width: '200px' }}>
                        บันทึก
                    </Button>
                </Form.Item>
            </Form >
        </>
    );
};

export default StoreSetting;
