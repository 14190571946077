import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Card, Col, Row, Button, List, Drawer, Image, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UserService from "../../../../services/user.service";
import StoreService from "../../../../services/stores.service";
import SearchComponent from "./SearchComponent";

import styles from './style.css';
import StoreItem from './StoreItem';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const AdminStore = () => {
  const { token: { colorBgContainer }, } = theme.useToken();
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
        loadData();
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

   // Function to update screenWidth state when the window is resized
   const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to update screenWidth when the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const loadData = async () => {
    const response = await StoreService.getAllStores();
    if (response.status == 200) {
      setData(response.data);
    } else {
      setData([]);
    }
  }

  const onSelect = (item) => {
    //navigate('/' + item.name + '/' + item._id)
    navigate('/admin/order/' + item._id);
  }

  return (
    <Content type="flex" justify="center" style={{
      minHeight: '70vh',
      maxWidth: '99vh',
      overflow: 'auto',
      height: '100%',
      width: '100%',
      padding: '10px',
      paddingTop: '55px',
      paddingBottom: '100px',
    }}>
      <Divider orientation="left">เลือกร้านที่ต้องการดูรายการสั่งซื้อ</Divider>

      <div class="container" style={{ marginTop: screenWidth < 768 ? 60 : 0, marginBottom: screenWidth < 768 ? 50 : 0 }}>
        <div class="store-grid">
          {data.map(item => (
            <StoreItem key={item._id} item={item} onSelect={onSelect} />
          ))}
        </div>
      </div>

      {/* <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        dataSource={data}
        itemLayout="horizontal"
        renderItem={(store) => (
          <List.Item>
            <Card
              cover={
                <Image
                  preview={false}
                  src={`https://www.atom-soft.com/image-api/${store.logo}`}
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '150px',
                    width: 'auto',
                    height: 'auto',
                    margin: '0 auto 10px',
                    cursor: 'pointer',
                  }}
                />
              }
              onClick={() => { navigate('/admin/order/' + store._id) }}
            >
              <Meta
                title={store.name}
                description={`${store.description}`}
              />
            </Card>
          </List.Item>
        )}
      /> */}
    </Content>
  );
};

export default AdminStore;
