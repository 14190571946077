import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";
import userService from '../../../services/user.service';
import columns from './config';
import CreateForm from './createForm';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const UsersManagement = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await userService.getUser();
        //console.log('response', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มร้านใหม่เรียบร้อย`,
            description:
                'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const onSearch = (value, _e, info) => console.log(info?.source, value);
    return (
        <>
            {contextHolder}
            <Modal
                title="เพิ่มผู้ใช้ระบบ"
                style={{
                    top: 20,
                }}
                open={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
                destroyOnClose={true}
                footer={[]}
            >
                <CreateForm onOK={() => {
                    setModal1Open(false);
                    loadData();
                    successNotification('topRight')
                }} />
            </Modal>
            <Layout style={{ minHeight: '100vh', margin: 30 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Search placeholder="พิมพ์คำที่ต้องการค้นหา" onSearch={onSearch} enterButton />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                                //onClick={() => navigate('/add-store')}
                                onClick={() => setModal1Open(true)}
                            >
                                เพิ่มผู้ใช้ใหม่
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card style={{ width: '100%', }} title="บัญชีผู้ใช้ระบบ">
                                <Table 
                                dataSource={data} 
                                columns={columns}
                                locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default UsersManagement;
