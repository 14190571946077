import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Empty, Layout, Row, Col, Card, List, Drawer, Button, Image, Typography } from 'antd';
import { Popup, SearchBar, NavBar, Toast, Input, Space } from 'antd-mobile'
import { CloseOutlined } from '@ant-design/icons' //antd design icons import
import ProductDetailScreen from './ProductDetailScreen';
import ProductService from '../../services/product.service';
// import ProductCategoryService from '../../services/product-category.service';
import noImage from '../../assets/images/noImage.jpg';
import styles from './style.css';
import ProductItem from './ProductItem';

const { Content } = Layout;
const { Meta } = Card;
const { Text, Link } = Typography;

const SearchProducts = ({ onUpdateCart, keyword, onSearch }) => {
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [data, setData] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [keywordParam, setKeywordParam] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const searchRef = useRef(null);

    const showDrawer = (product) => {
        setSelectedProduct(product);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        // Get the URLSearchParams object
        const queryParams = new URLSearchParams(window.location.search);

        // Access the query parameters
        const keywordParam = queryParams.get("keyword");
        //const show = queryParams.get("show");

        if (keywordParam) {
            setKeywordParam(keywordParam);
            loadData(keywordParam);
        } else {
            setKeywordParam(keyword);
            loadData(keyword);
        }

    }, [keyword]);

    const loadData = async (keyword) => {
        //console.log('keyword:', keyword);
        const response = await ProductService.getSearchAllByName(keyword);
        //console.log('search: ', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // if (data.length <= 0) {
    //     return <>
    //         <SearchBarNav />
    //         <div style={
    //             {
    //                 margin: 'auto auto auto auto',
    //                 marginBottom: '20px',
    //                 padding: '5px',
    //                 textAlign: 'center',
    //                 borderRadius: '4px',
    //                 minHeight: '80vh',
    //             }}>
    //             <Empty
    //                 image={Empty.PRESENTED_IMAGE_SIMPLE}
    //                 style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }}
    //                 description={
    //                     <span>
    //                         ไม่พบผลการค้นหา ! <a href={'#'} onClick={() => navigate('/store')}>เลือกซื้อร้านอื่น</a>
    //                     </span>
    //                 }
    //             />
    //         </div>
    //     </>
    // }


    return (
        <>
            {screenWidth < 768 ? (
                <>
                    <NavBar
                        backArrow={true}
                        onBack={() => {
                            navigate(-1);
                        }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            background: '#007bff',
                            height: '60px',
                            width: '100%',
                            color: 'white'
                        }}>
                        <Space>
                            <SearchBar
                                style={{ width: '85%', color: '#ffffff' }}
                                ref={searchRef}
                                onChange={(val) => {
                                    // Toast.show(`ผลการค้นหา：${val}`)
                                    setSearchKeyword(val);
                                }}
                                onSearch={(val) => {
                                    loadData(val);
                                    Toast.show(`ผลการค้นหา：${val}`);
                                }}
                                placeholder='พิมพ์คำที่ต้องการค้นหา...'
                                showCancelButton
                                onCancel={() => {
                                    loadData(searchKeyword);
                                    Toast.show(`ผลการค้นหา：${searchKeyword}`);
                                }}
                                cancelText={(<Text type="secondary" style={{ color: '#ffffff' }}>ค้นหา</Text>)} />
                        </Space>
                    </NavBar>
                    <Popup
                        title="รายละเอียดสินค้า"
                        position='right'
                        visible={open}
                        showCloseButton={false}
                        onClose={onClose}
                    >
                        <Content type="flex" justify="center" style={{
                            minHeight: '100vh',
                            overflow: 'auto',
                            height: '100%',
                            width: '100%',
                            paddingTop: '0px',
                            paddingBottom: '80px',
                        }}>
                            <ProductDetailScreen productInfo={selectedProduct} storeId={storeId} onUpdateCart={onUpdateCart} onClose={onClose} />
                        </Content>

                    </Popup>
                </>
            ) :
                <>
                    <Drawer
                        title="รายละเอียดสินค้า"
                        placement="bottom center"
                        onClose={onClose}
                        open={open}
                        size={screenWidth > 768 ? 'large' : 'large'}
                        showCloseButton={false}
                        destroyOnClose={true}
                        mask={true} // Disable the backdrop when snapped to the top
                        extra={
                            <Space>
                                <Button type="primary" onClick={onClose}>ปิดหน้าต่าง</Button>
                            </Space>
                        }>
                        <ProductDetailScreen productInfo={selectedProduct} storeId={storeId} onUpdateCart={onUpdateCart} onClose={onClose} />
                    </Drawer>
                </>}

            <div class="container" style={{ marginTop: screenWidth < 768 ? 120 : 0, marginBottom: screenWidth < 768 ? 50 : 0 }}>
                <div class="product-grid">
                    {data.map(product => (
                        <ProductItem key={product.id} item={product} onSelect={(showDrawer)} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default SearchProducts;
