import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal, Image } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import StoreService from '../../../services/stores.service';
import columns from './config';
import CreateForm from './createForm';
import SearchComponent from '../../../common/TableSearch';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;

const StoreListScreen = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [ownerId, setOwnerId] = useState(null);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }

    }, []);

    const loadData = async (ownerId) => {
        let response;
        const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
        if (isAdmin) {
            response = await StoreService.getAllStores();
        } else {
            response = await StoreService.getAllStoresByOwnerId(ownerId);
        }
        //console.log('myStore', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มร้านใหม่เรียบร้อย`,
            description:
                'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const onFinish = async (values) => {
        console.log(values);

        const response = await StoreService.createStore(values);
        if (response) {
            console.log(response);
            successNotification('topRight')
        }

    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const onSearch = (value, _e, info) => console.log(info?.source, value);

    columns[0].render = (text, record) => <Image src={`https://www.atom-soft.com/image-api/${record.logo ? record.logo : './logos/NoImage.png'}`} style={{ maxWidth: '50px' }} />
    columns[1].render = (text, record) => <a onClick={() => navigate('/admin/store/' + record._id)} style={{ color: '#0300a8' }}>{record.name}</a>
    columns[4].render = (text, record) => <a>{record.owner[0] ? record.owner[0].username : '-'}</a>

    const handleSearch = (results) => {
        if (results.error) {
            loadData(ownerId);
        } else {
            setData(results.data);
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="เพิ่มร้านใหม่"
                style={{
                    top: 20,
                }}
                open={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
                destroyOnClose={true}
                footer={[]}
            >
                <CreateForm onOK={() => {
                    setModal1Open(false);
                    loadData(ownerId);
                    successNotification('topRight')
                }}
                    destroyOnClose={true}
                />
            </Modal>
            <Layout style={{ minHeight: '100vh', margin: 30 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'store'} ownerId={ownerId} isAdmin={isAdmin} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                                //onClick={() => navigate('/add-store')}
                                onClick={() => setModal1Open(true)}
                            >
                                เพิ่มร้านใหม่
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card style={{ width: '100%', }} title="รายชื่อร้านค้า">
                                <Table 
                                dataSource={data} 
                                columns={columns} 
                                rowKey={'_id'}
                                locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default StoreListScreen;
