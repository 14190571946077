import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Card, Form, Input, Button, Upload, message, Avatar, Layout, Col, Row, notification, Tabs } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import userService from '../../../services/user.service';

const MyNotifications = () => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }
    }, []);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            //console.log('currentUser', response.data[0]);
            setUserData(response.data[0]);
        }

    }

    const onFinish = async (values) => {
        // Handle form submission here (e.g., update user data)
        //console.log('Form values:', values);
        const userId = currentUser.id;
        const response = await userService.UpdateById(userId, values);
        if (response.status == 200) {
            successNotification('topRight')
        }
    };

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setProfileImage(info.file.response.url);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <>
            {contextHolder}
            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    minHeight: '80vh', // Set minimum height for the whole viewport
                    width: '100%',
                    padding: '6px',
                }}>
                <Card title="การแจ้งเตือน" style={{ display: 'block', justifyContent: 'center', margin: '0 auto' }} span={24}>
                    

                </Card>
            </div>
        </>
    );
};

export default MyNotifications;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};