import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal, Tabs } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";
import EventDetailForm from './detail';
import StoreService from '../../../services/stores.service';
import RegisterManagement from '../eventRegister';
import PaymentManagement from '../payment';
import ProductsCategoryManagement from '../productCategorys';
import ContentEditor from './contents.js';
import Editor from "./editor/Editor";
import EventService from '../../../services/event.service';
import dayjs from 'dayjs';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;


const AdminEventDetail = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);
    const [EventDetail, setEventDetail] = useState(null);

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const eventId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        if (eventId) {
            loadData(eventId);
        }
    }, []);

    const successNotification = (placement) => {
        api.info({
            message: `บันทึกข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    }

    const loadData = async (eventId) => {
        const response = await EventService.getById(eventId);
        //console.log('event', response.data);
        if (response.status == 200) {
            // usage: Asia/Jakarta is GMT+7
            const asiaDate = convertTZ(response.data.event_date, "Asia/Jakarta");
            //console.log('event date: ', convertTZ(response.data.event_date, "Asia/Jakarta"));
            response.data.event_date = dayjs(asiaDate);
            setEventDetail(response.data);
        } else {
            setData([]);
        }
    }

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            {contextHolder}
         
            <Layout style={{ minHeight: '100vh', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card style={{ width: '100%', padding: 0 }} title={EventDetail ? EventDetail.name : 'รายละเอียดกิจกรรม'}>
                                {EventDetail && (
                                    <>
                                        <Tabs
                                            type="card"
                                            items={[
                                                {
                                                    label: `รายชื่อผู้สมัคร`,
                                                    key: 1,
                                                    children: <RegisterManagement initialValues={EventDetail} id={EventDetail._id}/>,
                                                },
                                                {
                                                    label: `รายการแจ้งชำระ`,
                                                    key: 2,
                                                    children: <PaymentManagement initialValues={EventDetail} id={EventDetail._id}/>,
                                                },
                                                {
                                                    label: `ข้อมูลกิจกรรม`,
                                                    key: 3,
                                                    children: <EventDetailForm initialValues={EventDetail} id={EventDetail._id} />,
                                                },
                                                {
                                                    label: `รายละเอียด`,
                                                    key: 4,
                                                    children: <Editor eventId={EventDetail._id} editorHtml={EventDetail.content} placeholder={"พิมพ์รายละเอียดเนื้อหากิจกรรม..."} style={{maxHeight: '60vh'}}/>,
                                                }
                                            ]}
                                        />
                                    </>
                                )}

                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default AdminEventDetail;
