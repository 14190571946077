import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from 'axios';
import StoreService from '../../../services/stores.service';
import userService from '../../../services/user.service';
import ProductCategoryService from '../../../services/product-category.service';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 16,
    },
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const CreateForm = ({ onOK }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(null);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if (currentUser) {
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_OWNER"));
            getUserList();
        }
    }, []);

    const getUserList = async () => {
        const response = await userService.getUser();
        if (response.status == 200) {
            //map user array to options         
            const userItems = response.data.map((user) => {
                return { label: user.name ? user.name : user.username, value: user._id };
            })
            setUserList(userItems);
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                อัพโหลด
            </div>
        </div>
    );

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มร้านใหม่เรียบร้อย`,
            description:
                'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };


    const onFinish = async (values) => {
        //console.log(values);
        const ownerId = currentUser.id;
        const productImage = await uploadImage();
        console.log('productImage', productImage);
        const response = await StoreService.createStore({ ...values, logo: productImage.imageUrl, owner: ownerId });
        if (response) {
            //console.log(response);
            //successNotification('topRight')
            onOK();
        }

    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };


    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    return (
        <>
            {contextHolder}
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                }}
            >
                <Form.Item
                    name="name"
                    label="ชื่อร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ATOMSOFT" />
                </Form.Item>

                <Form.Item
                    name="address"
                    label="ที่อยู่ร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} placeholder={"53 ม.11 บ.ดวงเจริญ ต.ธาตุ อ.วารินชำราบ \nจ.อุบลราชธานี 34190"} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="เบอร์โทรศัพท์"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="09465495xx" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="คำอธิบายย่อเกี่ยวกับร้าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} placeholder="ขายและพัฒนาซอฟแวร์ POS เพื่อผู้ประกอบการร้านค้าไทย" />
                </Form.Item>
                {isAdmin && (
                    <>
                        <Form.Item
                            name="owner"
                            label="เจ้าของร้าน"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Select
                                showSearch
                                placeholder="เลือกบัญชีผู้ใช้"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={userList}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="logo"
                    label="โลโก้ร้าน"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    getValueFromEvent={getFile}
                >
                    <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={fileList}
                        onChange={handleUpload}
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    </Upload>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        บันทึก
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        เคลียร์
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default CreateForm;
