import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Card, Col, Row, List, Button, Drawer, Image, Typography, Spin } from 'antd';
import { PlusOutlined, SettingOutlined, EditOutlined, EllipsisOutlined, SendOutlined, OrderedListOutlined } from '@ant-design/icons';
import EventService from "../../services/event.service";
import UserService from "../../services/user.service";
import StoreService from "../../services/stores.service";
const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const { Title } = Typography;

const Events = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [content, setContent] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        UserService.getPublicContent().then(
            (response) => {
                setContent(response.data);
                loadData();
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );
    }, []);


    const loadData = async () => {
        const response = await EventService.getAllWIthDetails();
        //console.log('events', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }
        setIsloading(false);
    }

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    return (
        <>
            <List
                grid={{
                    gutter: 6,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                }}
                dataSource={data}
                itemLayout="vertical"
                renderItem={(item) => (
                    <List.Item style={{ margin: '8px' }}>
                        <Card

                            cover={
                                <Image
                                    onClick={() => { navigate('/events/' + item._id) }}
                                    preview={false}
                                    src={`https://www.atom-soft.com/image-api/${item.cover}`}
                                    style={{
                                        display: 'block',
                                        maxWidth: '100%',
                                        maxHeight: '350px',
                                        width: 'auto',
                                        height: 'auto',
                                        margin: '0 auto 10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            }
                            actions={[
                                <>
                                    <Row gutter={1}>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 12 : 6}>
                                            <Title level={5}>รับสมัคร</Title>
                                            <Title level={1} style={{ marginTop: '5px' }}>{item.maximum_register}</Title>
                                        </Col>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 12 : 6}>
                                            <Title level={5}>ผู้สมัคร</Title>
                                            <Title level={1} style={{ marginTop: '5px' }}>{item.external_registration_url ? '-' : item.totalRegistrations || 0}</Title>
                                        </Col>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 24 : 12} grid={{
                                            gutter: 6,
                                            xs: 24,
                                            sm: 24,
                                            md: 1,
                                            lg: 1,
                                            xl: 1,
                                            xxl: 1,
                                        }}>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    if (item.external_registration_url) {
                                                        window.open(item.external_registration_url, '_blank');
                                                    } else {
                                                        navigate('/events/register/' + item._id)
                                                    }
                                                }}
                                                icon={<SendOutlined />}
                                                block
                                                style={{ borderRadius: 10, width: '95%', height: '50px', marginBottom: 10 }}>
                                                สมัครร่วมกิจกรรม
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    navigate('/confirm-payment');
                                                }}
                                                icon={<PlusOutlined />}
                                                block
                                                style={{ borderRadius: 10, width: '95%', height: '50px', marginBottom: 10 }}>
                                                แจ้งชำระเงิน
                                            </Button>
                                            <Button
                                                onClick={() => { navigate('/events/' + item._id) }}
                                                type="primary"
                                                block
                                                style={{ borderRadius: 10, width: '95%', height: '50px', marginBottom: 10 }}>
                                                รายละเอียด & ตรวจสอบรายชื่อ
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            ]}
                        >
                            <Meta
                                title={`${item.name}`}
                                description={`${item.description}`}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        </>

    );
};

export default Events;