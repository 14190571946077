import { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import EventService from '../../../../services/event.service';
import { Card } from 'antd';

import './styles.css';
import { Button, Space, notification} from 'antd';

Quill.register('modules/imageResize', ImageResize);

/*
 * Simple editor component that takes placeholder text as a prop
 */
class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = { editorHtml: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.quillRef = null;
    }

    componentDidMount() {
        this.setState({ editorHtml: this.props.editorHtml });
    }

    handleChange(html) {
        this.setState({ editorHtml: html });
        //console.log(html);
    }

    async handleSave() {
        const { eventId } = this.props;
        const { editorHtml } = this.state;
        const res = await EventService.updateById(eventId, { content: editorHtml });
        this.successNotification('topRight');
        //console.log('saved...', res.data);
    }


    insertImageByUrl = () => {
        const editor = this.quillRef.getEditor();
        const imageUrl = prompt('ใส่ URL ของรูป:'); // Prompt the user for the image URL
        if (imageUrl) {
            const selection = editor.getSelection();
            //editor.insertEmbed(10, 'image', imageUrl);
            if (selection && selection.index !== undefined) {
                editor.insertEmbed(selection.index, 'image', imageUrl);
              } else {
                // If selection is not valid, insert the image at the end of the editor
                editor.insertEmbed(editor.getLength(), 'image', imageUrl);
              }
        }
    };

    successNotification = (placement) => {
        notification.success({
            message: `บันทึกข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    render() {
        const editorHtml = this.state;
        return (
            <>
            <Space direction='horizantal' style={{marginBottom: '10px'}}>
            <Button onClick={this.handleSave}>บันทึก</Button>
                <Button onClick={this.insertImageByUrl}>เพิ่มรูป</Button>
            </Space>
            {editorHtml && (
                    <>
                                <div style={{ width: '100%', maxHeight: '60vh'}} title={'รายละเอียดกิจกรรม'}>
                        <ReactQuill
                            ref={(el) => (this.quillRef = el)}
                            theme={this.state.theme}
                            onChange={this.handleChange}
                            value={this.state.editorHtml}
                            modules={Editor.modules}
                            formats={Editor.formats}
                            isFullscreen={true}
                            //bounds={'#root'}
                            placeholder={this.props.placeholder}
                        />
                        </div>
                    </>
                )}
            </>
        );
    }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: ['Prompt'] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { align: ['right', 'center', 'left'] }, // Add alignment options to the toolbar
        ],
        ['link', 'video'],
        ['clean'],
        [{ 'fullscreen': true }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    },
};


/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'left',
    'right',
    'center',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
];

export default Editor;
