import { Tooltip, Tag, Popover, Button, Space, Image } from 'antd';


export default [
  {
    title: 'ชื่อ-นามสกุล',
    dataIndex: 'name',
    key: 'name',
    onFilter: (value, record) => record.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'จำนวนเงิน',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'แจ้งชำระเมื่อ',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'หลักฐาน',
    dataIndex: 'paymentDoc',
    key: 'paymentDoc',
    render: (text, record) => (
      <>
        {record.paymentDoc ? (
          <>
            <Image
              width={30}
              src={`https://www.atom-soft.com/image-api/${record.paymentDoc}`}
              placeholder={
                <Image
                  preview={false}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                  width={30}
                />
              }
            />
          </>
        ) : (
          <Tag color="red">no image</Tag>
        )}
      </>
    )
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => (
      <>
        {record.status == 'PENDING' && (
          <Tag color="red" key={record._id}>
            รอตรวจสอบ
          </Tag>
        )}
        {record.status == 'PROCESSING' && (
          <Tag color="orange" key={record._id}>
            กำลังตรวจสอบ
          </Tag>
        )}
        {record.status == 'COMPLETED' && (
          <Tag color="green" key={record._id}>
            ตรวจสอบแล้ว
          </Tag>
        )}
        {record.status == 'CANCELED' && (
          <Tag color="red" key={record._id}>
            ยกเลิก
          </Tag>
        )}
      </>
    )
  },
  {
    title: 'ดำเนินการ',
    dataIndex: 'note',
    key: 'note',
    render: (text, record) => (
      <>
        {/* action in index.js file */}
      </>
    )
  }
];

