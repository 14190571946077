import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}`;

//api/events/register
const create = (value) => {
  return axios.post(API_URL + 'register/', value, { headers: authHeader() });
};

const getPublic = () => {
  return axios.get(API_URL + "/all");
};

const getById = (id) => {
  return axios.get(AppConfig.API_URL + 'register-info/' + id, { headers: authHeader() });
};

const getAllByOwnerId = (ownerId) => {
  return axios.get(AppConfig.API_URL + 'register-user/' + ownerId, { headers: authHeader() });
};

const getAllByEventId = (eventId) => {
  return axios.get(AppConfig.API_URL + 'register/' + eventId, { headers: authHeader() });
};

const getCountByEventId = (eventId) => {
  return axios.get(AppConfig.API_URL + 'register-count/' + eventId, { headers: authHeader() });
};

const getSearchByName = (eventId, keyword) => {
  return axios.get(AppConfig.API_URL + `register/${eventId}/${keyword}`, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const updateById = (id, ownerId, values) => {
  return axios.put(AppConfig.API_URL + 'register/' + id, { ...values, ownerId: ownerId }, { headers: authHeader() });
};

const EventRegisterService = {
  create,
  getPublic,
  getAll,
  getAllByOwnerId,
  getAllByEventId,
  getById,
  getSearchByName,
  getCountByEventId,
  updateById
};

export default EventRegisterService