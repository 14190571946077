import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}cart`;

const addToCart = (value, userId) => {
    return axios.post(userId?`${API_URL}/add/${userId}`:`${API_URL}/add`, value, { headers: authHeader() });
};

//api/increase/${itemId}
const handleIncrease = (itemId) => {
    return axios.post(`${API_URL}/increase/${itemId}`, { headers: authHeader() });
};

const handleDecrease = (itemId) => {
    return axios.post(`${API_URL}/decrease/${itemId}`, { headers: authHeader() });
};

///api/cart/:userId
const getAllByUserId = (userId) => {
    return axios.get(AppConfig.API_URL + 'cart/' + userId, { headers: authHeader() });
};

//shopcart
const getShopCartByUserId = (userId) => {
    return axios.get(AppConfig.API_URL + 'shopcart/' + userId, { headers: authHeader() });
};

const getAllBySessionId = (sessionId) => {
    return axios.get(AppConfig.API_URL + 'cart/' + sessionId, { headers: authHeader() });
};

//: api/cart/delete/:id
const deleteById = (itemId) => {
    return axios.delete(AppConfig.API_URL + 'cart/' + itemId, { headers: authHeader() });
};

const ShopCartSerivce = {
    addToCart,
    getShopCartByUserId,
    getAllByUserId,
    getAllBySessionId,
    deleteById,
    handleIncrease,
    handleDecrease,
};

export default ShopCartSerivce;