import React, { useState, useEffect } from "react";
import liff from '@line/liff';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Card, Typography, Image, Layout } from 'antd';
import AppConfig from '../../config/AppConfig';
import dbdRegistered from '../../assets/images/dbd_registered.png';
import gsoLogo from '../../assets/images/icon.png';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const AboutUs = ({ redirectUrl }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    //global state
    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        // Set the document title when the component mounts
        document.title = `เกี่ยวกับ`;
    }, []);

    return (
        <Card title="เกี่ยวกับเรา" style={{ marginTop: '50px', borderRadius: 20, margin: '0 auto' }}>
            <Footer style={{ textAlign: 'center', marginTop: 10, padding: 0, borderRadius: 30, }}>
                <Image width={250} src={gsoLogo} preview={false} style={{ cursor: 'pointer' }} />
            </Footer>
            <Footer style={{ textAlign: 'center', marginBottom: 0, paddingBottom: 0, borderRadius: 30, }}>
                <Text> {AppConfig.APP_FOOTER}</Text>
                <Text> {AppConfig.APP_FOOTER1}</Text>
            </Footer>
            <Footer style={{ textAlign: 'center', marginBottom: 0, paddingBottom: 0, borderRadius: 30, }}>
                {/* <Image width={120} src={dbdRegistered} preview={false} style={{ cursor: 'pointer' }} onClick={() => {
                    window.open('https://www.trustmarkthai.com/callbackData/popup.php?data=922-37-6-78b603f9713b6889a3ee1d845e99634d7d1132531a7', '_blank');
                }} /> */}
            </Footer>
        </Card>
    );
};

export default AboutUs;