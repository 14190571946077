import React, { useState, useEffect, useRef } from "react";
import { Row, Layout, Card } from "antd";
import { Footer, Button, Space, Swiper, Toast, Image, AutoCenter, Divider } from 'antd-mobile'

import AppConfig from '../config/AppConfig';
import dbdRegistered from '../assets/images/dbd_registered.png';

import UserService from "../services/user.service";
import AdsService from '../services/ads.service';
import Event from "./events/";
import Store from "./store";

const { Header, Sider, Content } = Layout;
const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']

const Home = () => {
  const SwiperRef = useRef(null)
  const [content, setContent] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [ads, setAds] = useState([]);

  // Function to update screenWidth state when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to update screenWidth when the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    loadData();

  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );

    // Set the document title when the component mounts
    document.title = `ATOMSOFT | ร้านค้าชุมชน`;

    scrollToTop();

  }, []);

  const loadData = async () => {
    const response = await AdsService.getByPosition('HOME-TOP')
    //console.log('ads', response.data);
    if (response.status == 200) {
      let data = response.data
      data = data.filter(item => item.isActive); // Filter active items
      data.sort((a, b) => a.index - b.index);
      //console.log('ads', data);
      setAds(data);

    } else {
      setAds([]);
    }
  }

  const items = ads.map((adsItem, index) => (
    <Swiper.Item key={index}>
      <div
        style={{
          height: 'auto',
          color: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          fontSize: '48px',
          userSelect: 'none',
          margin: '10px'
        }}
      >
        <AutoCenter>
          <Image src={adsItem.imageUrl} width={'100%'} style={{ '--border-radius': '8px', borderRadius: '8px', cursor: 'pointer' }} fit='fill'
            onClick={() => {
              window.open(adsItem.link ? adsItem.link : '#', '_self');
            }}
          />
        </AutoCenter>
      </div>
    </Swiper.Item>
  ))

  return (
    <>
       <div class="container" style={{ marginTop: screenWidth < 768 ? 100 : 0, marginBottom: screenWidth < 768 ? 50 : 0 }}>
          {/* <Space direction='vertical' block> */}
          <Swiper
            style={{
              '--border-radius': '8px',
              width: '98%',
            }}
            defaultIndex={0}
            loop
            autoplay
          >

            {items}
          </Swiper>
          <Store />
          {screenWidth < 768 && (
            <>
              <AutoCenter style={{ textAlign: 'center', marginBottom: 0, paddingBottom: 55, borderRadius: 30, fontSize: '10px' }}>
                {AppConfig.APP_FOOTER}
                {AppConfig.APP_FOOTER1}
                {/* <Image width={80} src={dbdRegistered} preview={false} style={{ margin: '0 auto', cursor: 'pointer' }} onClick={() => {
                  window.open('https://www.trustmarkthai.com/callbackData/popup.php?data=922-37-6-78b603f9713b6889a3ee1d845e99634d7d1132531a7', '_blank');
                }} /> */}
                <Footer content='@ 2023 atom-soft.com All rights reserved'></Footer>
              </AutoCenter>

            </>
          )}
        </div>
    </>

  );
};

export default Home;
