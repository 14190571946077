import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}events`;

const create = (value) => {
  return axios.post(API_URL, value, { headers: authHeader() });
};

const getPublic = () => {
  return axios.get(API_URL + "/all");
};

const getAllWIthDetails = () => {
  return axios.get(`${AppConfig.API_URL}events/count`);
};

const getById = (eventId) => {
  return axios.get(AppConfig.API_URL + 'events/' + eventId, { headers: authHeader() });
};

const getByDetailById = (eventId) => {
  return axios.get(AppConfig.API_URL + 'events-detail/' + eventId, { headers: authHeader() });
};

const getAllByOwnerId = (ownerId) => {
  return axios.get(AppConfig.API_URL + 'events/' + ownerId, { headers: authHeader() });
};

const getSearchByName = (ownerId, keyword) => {
  return axios.get(AppConfig.API_URL + `events/${ownerId}/${keyword}`, { headers: authHeader() });
};

const adminGetSearchByName = (ownerId, keyword) => {
  return axios.get(AppConfig.API_URL + `events-admin/${keyword}`, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const updateById = (eventId, value) => {
  return axios.put(AppConfig.API_URL + 'events/' + eventId, { ...value }, { headers: authHeader() });
};

const EventService = {
  create,
  getPublic,
  getAll,
  adminGetSearchByName,
  getAllByOwnerId,
  getAllWIthDetails,
  getByDetailById,
  getById,
  getSearchByName,
  updateById
};

export default EventService