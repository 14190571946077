import React, { useState, useEffect } from 'react';
import { Typography, QRCode, Result, Checkbox, DatePicker, Spin, Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, InputNumber, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/th_TH';
import AppConfig from '../../config/AppConfig';
import { getAutoSuggestion } from 'thai-data';

import axios from 'axios';
import EventService from '../../services/event.service';
import EventRegisterService from '../../services/event-register.service';
import userService from '../../services/user.service';
import ProductCategoryService from '../../services/product-category.service';


const { Title } = Typography;
const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const EventRegisterForm = ({ onOK, initialValues }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [eventDate, setEventDate] = useState('2023-10-01');
    const [selectedDates, setSelectedDates] = useState('2023-10-01');
    const [generationsOptions, setGenerationsOptions] = useState([]);
    const [selectedGenerations, setSelectedGenerations] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successData, setSuccessData] = useState({});

    //addres state
    const [zipCode, setZipCode] = useState('')
    const [subDistrict, setSubDistrict] = useState(Array)
    const [subDistrictSelect, setSubDistrictSelect] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [isDisabledSubDistrictSelect, setIsDisabledSubDistrictSelect] = useState(true)

    useEffect(() => {
        if (currentUser) {
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_OWNER"));
        }
        loadData();
    }, []);

    const loadData = async () => {
        //console.log("uploaded success", initialValues);
        const generations = initialValues.generations.map((item) => {
            return { value: item.id, label: item.name, price: item.price}
        })
        setGenerationsOptions(generations);
        setLoading(false);

    }

    const getCoverImage = async (initialValues) => {
        const uploadedCover = initialValues.cover ? [
            {
                uid: '-1',
                name: 'image1.png',
                status: 'done',
                url: initialValues.cover ? initialValues.cover : '', // Replace with actual image URLs
            }] : [];
        return uploadedCover;
    }

    const successNotification = (placement) => {
        api.info({
            message: `สมัครเรียบร้อย`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        values.generations = selectedGenerations;
        //console.log(values);
        const eventId = initialValues._id;
        const ownerId = currentUser ? currentUser.id : [];
        //const oldCover = values.cover;
        const paymentDoc = fileList.length >= 1 ? fileList[0].originFileObj ? await uploadImage() : { imageUrl: null } : { imageUrl: null };
        //const productImage = fileList.length >= 1 ? fileList[0].originFileObj ? await uploadImage() : { imageUrl: oldCover } : { imageUrl: null };
        //console.log('productImage', productImage);
        const res = await EventRegisterService.getCountByEventId(eventId);
        const { count } = res.data;
        const newNumber = count + 1;
        // console.log(newNumber);
        const response = await EventRegisterService.create({ ...values, points: initialValues.points, registerNumber: newNumber, paymentDoc: paymentDoc.imageUrl, owner: ownerId, eventId: eventId, payment_status: 'PENDING' });
        if (response) {
           //console.log('register response: ', response.data.data);
            successNotification('topRight')
            setSuccessData(response.data.data);
            setIsSuccess(true);
            //onOK();
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getFile = (e) => {
        //console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    if (loading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    if (isSuccess) {
        console.log('success:', successData);
        return <>
            {successData && (
                <>
                    <div style={
                        {
                            margin: '0 auto',
                            textAlign: 'center',
                            borderRadius: '4px',
                        }}>

                    </div>
                    <Result
                        status="success"
                        title="สมัครสำเร็จ"
                        subTitle={`หมายเลขอ้างอิง: ${successData._id}`}
                        extra={[
                            <Title level={3} style={{ fontSize: 30 }}>หมายเลขใบสมัคร</Title>,
                            <Title style={{ fontSize: 80 }}>{successData.registerNumber}</Title>,
                            <Button type="primary" key="console" onClick={() => navigate('/events/' + initialValues._id)}>
                                ไปที่หน้ากิจกรรม
                            </Button>
                        ]}
                    />
                </>
            )}
        </>
    }

    const onSetZipCode = (e) => {
        setSubDistrictSelect('')
        setDistrict('')
        setProvince('')
        if (/^\d{0,5}$/.test(e)) {
            setZipCode(e)
            if (getAutoSuggestion(e).subDistrict) {
                setSubDistrict(getAutoSuggestion(e).subDistrict)
                setIsDisabledSubDistrictSelect(false)
                formRef.current?.setFieldsValue({
                    subdistrict: getAutoSuggestion(e).subDistrict[0],
                });
            } else {
                setIsDisabledSubDistrictSelect(true)
            }
        }
    }

    const autoSuggestion = (zipCode, subDistrict) => {
        const districtName = getAutoSuggestion(zipCode, subDistrict).districtName;
        const provinceName = getAutoSuggestion(zipCode, subDistrict).provinceName;
        setDistrict(districtName);
        setProvince(provinceName);
        formRef.current?.setFieldsValue({
            district: districtName,
            province: provinceName,
        });
    }

    const onSetDistrict = (subDistrict) => {
        setSubDistrictSelect(subDistrict)
        autoSuggestion(zipCode, subDistrict)
    }


    return (
        <>
            {contextHolder}
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'left',
                    borderRadius: '4px',
                    minHeight: '80vh',
                    paddingBottom: '50px',
                }}>
                <Form
                    {...layout}
                    ref={formRef}
                    form={form}
                    // initialValues={initialValues}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{
                        margin: 'auto auto auto auto',
                        marginTop: 50,
                        maxWidth: '500px'
                    }}
                >
                    <Form.Item
                        name="name"
                        label="คำนำหน้า-ชื่อ-นามสกุล"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="นายสมชาย ใจดี" />
                    </Form.Item>
                    <Form.Item
                        name="age"
                        label="อายุ"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber placeholder="30" />
                    </Form.Item>
                    <Form.Item
                        name="personelId"
                        label="เลขบัตรประชาชน"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="ป้อนเลขบัตรประชาชน" />
                    </Form.Item>
                    <Form.Item
                        name="team"
                        label="ชื่อทีม"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="นายสมชาย ใจดี" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="เบอร์โทร"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="09465495xx, 08072926xx" />
                    </Form.Item>

                    <Divider orientation="left">{'กิจกรรมที่เข้าร่วม'}</Divider>
                    {/* Nest Form.List */}
                    <Form.Item
                        name="generations"
                        label="ประเภทรุ่น"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="เลือกรุ่นที่สมัคร"
                            optionFilterProp="children"
                            onChange={(item, record) => {
                                //console.log('selected generation: ', record);
                                setSelectedGenerations([record]);
                            }}
                            filterOption={filterOption}
                            options={generationsOptions}
                        >
                        </Select>
                    </Form.Item>
                    <Divider orientation="left">{'ที่อยู่ (กรณีให้จัดส่ง)'}</Divider>
                    <Form.Item
                        label="บ้านเลขที่ หมู่ที่ ถนน"
                        name="address"
                        rules={[{ required: false, message: 'กรุณาป้อนบ้านเลขที่ หมู่ที่' }]}
                    >
                        <Input placeholder='53 ม.11 ถ.สถิตย์นิมานกาล' />
                    </Form.Item>

                    <Form.Item
                        label="รหัสไปรษณีย์"
                        name="zipcode"
                        rules={[{ required: false, message: 'กรุณาป้อนรหัสไปรษณีย์' }]}
                    >
                        <Input value={zipCode} onChange={e => {
                            onSetZipCode(e.target.value);
                            formRef.current?.setFieldsValue({
                                zipcode: e.target.value,
                            });
                        }}
                            id="zipCode" type="text" placeholder="รหัสไปรษณีย์" />
                    </Form.Item>

                    <Form.Item
                        label="ตำบล"
                        name="subdistrict"
                        rules={[{ required: zipCode.length === 5 ? true : false, message: 'กรุณาเลือกตำบล' }]}

                    >
                        <Select
                            labelInValue
                            disabled={zipCode.length === 5 ? false : true}
                            style={{
                                width: '100%',
                            }}
                            onChange={e => {
                                onSetDistrict(e.value);
                                formRef.current?.setFieldsValue({
                                    subdistrict: e.value,
                                });
                            }}
                            options={subDistrict.map((item, index) => {
                                return { key: index, value: item }
                            })}
                        />
                    </Form.Item>

                    <Form.Item
                        label="อำเภอ"
                        name="district"
                        rules={[{ required: zipCode.length === 5 ? true : false, message: 'กรุณาเลือกตำบล' }]}
                    >
                        <Input placeholder="อำเภอ" readOnly />
                    </Form.Item>

                    <Form.Item
                        label="จังหวัด"
                        name="province"
                        rules={[{ required: zipCode.length === 5 ? true : false, message: 'จังหวัด' }]}
                    >
                        <Input placeholder="จังหวัด" readOnly />
                    </Form.Item>

                    <Divider orientation="left">{'การชำระเงิน'}</Divider>
                    <Form.Item
                        name="paymentDoc"
                        label="หลักฐานการชำระ"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        getValueFromEvent={getFile}
                    >
                        <Upload
                            listType="picture-card"
                            maxCount={1}
                            fileList={fileList}
                            previewFile={false}
                            onChange={handleUpload}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        >
                            <div>
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    อัพโหลด
                                </div>
                            </div>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="isAgreementAccept"
                        label="ข้อตกลงเงื่อนไข"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Checkbox checked={true}>
                            ยอมรับข้อตกลงและเงื่อนไข/กฎกติกาการแข่งขัน
                        </Checkbox>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" style={{ width: '100%', height: '50px', justifyContent: 'center' }}>
                        สมัคร
                    </Button>
                </Form >
            </div>
        </>
    );
};

export default EventRegisterForm;
