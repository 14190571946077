import React, { useState, useEffect } from 'react';
import { Tooltip, Popover, message, Layout, Row, Col, Card, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Spin, InputNumber, Upload } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons' //antd design icons import
import { UndoOutline, EditSOutline, DeleteOutline, MoreOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline } from 'antd-mobile-icons'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import userService from '../../../services/user.service';
import ProductService from '../../../services/product.service';
import ProductCategoryService from '../../../services/product-category.service';
import columns from './config';
import CreateForm from './createForm';
import EditForm from './editForm';
import ImageUpload from '../../../common/ImageUpload';
import AppConfig from '../../../config/AppConfig';
import SearchComponent from '../../../common/TableSearch';

const defaultCategoryId = AppConfig.DEFAULT_PRODUCT_CATEGORY_ID;

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;

const ProductsManagement = () => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false); //drawer add new product
    const [storeId, setStoreId] = useState(null);
    const [categorys, setCategorys] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [variants, setVariants] = useState([]);

    const showDrawer = () => {
        getCategory(storeId);
        setOpen(true);
        setIsLoading(false);
    };
    const onClose = () => {
        form.resetFields();
        setSelectedItem(null);
        setOpen(false);
    };

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('store', storeId);
        if (storeId) {
            setStoreId(storeId);
            loadData(storeId);
            //onFill();
        }
    }, []);

    const loadData = async (storeId) => {
        setIsLoading(true);
        const response = await ProductService.getAllByStoreId(storeId);
        //console.log('products', response);
        if (response.status == 200) {
            setData(response.data);
            setIsLoading(false);
        } else {
            setData([]);
            setIsLoading(false);
        }
        getCategory(storeId);
    }

    const getCategory = async (storeId) => {
        const categoryRes = await ProductCategoryService.getAllByStoreId(storeId);
        //console.log('products', response);
        if (categoryRes.status == 200) {
            const categoryMenu = categoryRes.data.map((item) => {
                return { label: item.name, value: item._id };
            })
            setCategorys(categoryMenu);
            setIsLoading(false);
        } else {
            setCategorys([]);
            setIsLoading(false);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `สำเร็จ`,
            description:
                'บันทึกข้อมูลสำเร็จ',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'product');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        //console.log('form Values', values);
        const oldCover = isEdit?selectedItem.image:{ imageUrl: null };
        const productImage = fileList.length >= 1&&fileList[0].originFileObj ? await uploadImage() : oldCover;
        const categoryName = selectedCategory
        const data = { ...values, storeId: [storeId], category: categoryName, image: fileList.length >= 1 ? productImage : { imageUrl: null } };
        console.log(data);

        if (isEdit) {
            const response = await ProductService.updateById(selectedItem._id, data);
            if (response) {
                //console.log(response);
                successNotification('topRight')
                loadData(storeId);
                onClose();
            }
        } else {
            const response = await ProductService.create(data);
            if (response) {
                //console.log(response);
                successNotification('topRight')
                loadData(storeId);
                onClose();
            }
        }

    };

    const onReset = () => {
        form.resetFields();
        setSelectedItem(null);
        setFileList([]);
    };

    const onFill = () => {
        const initialData = {
            variants: [
                {
                    product: "123",
                    name: "ไซส์",
                    options: [
                        {
                            product: "123",
                            name: "L",
                            price: "250",
                            quantity: "15",
                            sku: "8801"
                        },
                        {
                            product: "123",
                            name: "M",
                            price: "250",
                            quantity: "16",
                            sku: "8802"
                        }
                    ]
                }
            ]
        }

        formRef.current?.setFieldsValue(initialData);
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSearch = (value, _e, info) => console.log(info?.source, value);

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    const handleSearch = (results) => {
        if (results.error) {
            loadData(storeId);
        } else {
            setData(results.data);
        }
    };

    columns[4].render = (text, record) => (
        <>
            <Space direction='horizantal' style={{ fontSize: 14 }}>
                <Tooltip placement="topRight" title={'แก้ไขข้อมูล'}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            color: '#ffffff',
                            height: '30px',
                            width: '130px',
                            borderRadius: '20px',
                            background: '#0058fd'
                        }}
                        onClick={() => {
                            setSelectedItem(record);
                            setIsLoading(true);
                            setIsEdit(true);
                            showDrawer();
                        }}
                    >
                        <Space direction='vertical' style={{ fontSize: 14 }}>
                            แก้ไข
                        </Space>
                    </Button>
                </Tooltip>
            </Space>
        </>
    )

    return (
        <>
            {contextHolder}
            {isEdit ? (<>
                <Drawer
                    title={isEdit ? "แก้ไขสินค้า" : "สร้างสินค้าใหม่"}
                    width={720}
                    onClose={onClose}
                    open={open}
                    //destroyOnClose={true}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                    extra={
                        <Space>
                            <Button onClick={onReset}>เคลียร์</Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    //console.log('form submit');
                                    form.submit()
                                }}
                            >
                                {isEdit ? "บันทึกการเปลี่ยนแปลง" : "สร้าง"}
                            </Button>
                        </Space>
                    }>
                    {selectedItem && (
                        <>
                            <EditForm
                                isEdit
                                initialValues={selectedItem}
                                form={form}
                                onFinish={onFinish}
                                fileList={fileList}
                                setFileList={setFileList} />
                        </>
                    )}
                </Drawer>

            </>) : (<>
                <Drawer
                    title={isEdit ? "แก้ไขสินค้า" : "สร้างสินค้าใหม่"}
                    width={720}
                    onClose={onClose}
                    open={open}
                    destroyOnClose={true}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                    extra={
                        <Space>
                            <Button onClick={onReset}>เคลียร์</Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    //console.log('form submit');
                                    form.submit()
                                }}
                            >
                                {isEdit ? "บันทึกการเปลี่ยนแปลง" : "สร้าง"}
                            </Button>
                        </Space>
                    }>
                    <CreateForm
                        form={form}
                        onFinish={onFinish}
                        fileList={fileList}
                        setFileList={setFileList} />
                </Drawer>
            </>)}

            <Layout style={{ minHeight: '100%', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'product'} storeId={storeId} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}></Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ position: 'absolute', right: '1px' }}
                                onClick={() => {
                                    setSelectedItem(null);
                                    setIsEdit(false);
                                    showDrawer(true);
                                }}
                            >
                                เพิ่มสินค้าใหม่
                            </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card className='card' style={{ width: '100%', }} title="รายการสินค้า">
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={'_id'}
                                    loading={isLoading}
                                    locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default ProductsManagement;
