import { Tooltip, Tag, Popover, Button, Space, Image } from 'antd';


export default [
  {
    title: 'เลขใบสมัคร',
    dataIndex: 'registerNumber',
    key: 'registerNumber',
    filterMode: 'tree',
    onFilter: (value, record) => record.registerNumber.startsWith(value),
    filterSearch: true,
    sorter: (a, b) => b.registerNumber - a.registerNumber,
  },
  {
    title: 'ชื่อ-นามสกุล',
    dataIndex: 'name',
    key: 'name',
    onFilter: (value, record) => record.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: 'รุ่นที่สมัคร',
    dataIndex: 'generations',
    key: 'generations',
    render: (value, record) => record.generations[0] ? record.generations[0].label : '-',
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'วันที่',
    dataIndex: 'register_date',
    key: 'register_date',
  },
  {
    title: 'หลักฐาน',
    dataIndex: 'paymentDoc',
    key: 'paymentDoc',
    render: (text, record) => (
      <>
        {record.paymentDoc ? (
          <>
            <Image
              width={30}
              src={`https://www.atom-soft.com/image-api/${record.paymentDoc}`}
              placeholder={
                <Image
                  preview={false}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                  width={30}
                />
              }
            />
          </>
        ) : (
          <Tag color="red">no image</Tag>
        )}
      </>
    )
  },
  {
    title: 'สถานะ',
    dataIndex: 'payment_status',
    key: 'payment_status',
    render: (text, record) => (
      <>
        {record.payment_status == 'PENDING' && (
          <Tag color="red" key={record._id}>
            รอชำระเงิน
          </Tag>
        )}
        {record.payment_status == 'PROCESSING' && (
          <Tag color="orange" key={record._id}>
            รอตรวจสอบ
          </Tag>
        )}
        {record.payment_status == 'COMPLETED' && (
          <Tag color="green" key={record._id}>
            ชำระแล้ว
          </Tag>
        )}
        {record.payment_status == 'CANCELED' && (
          <Tag color="red" key={record._id}>
            ยกเลิก
          </Tag>
        )}
      </>
    )
  },
  {
    title: 'ดำเนินการ',
    dataIndex: 'note',
    key: 'note',
    render: (text, record) => (
      <>
        {/* action in index.js file */}
      </>
    )
  }
];

