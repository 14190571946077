import React from 'react';
import dayjs from 'dayjs';
import { Button, Space } from 'antd-mobile'
import { QRCode } from 'antd';
import { Tag } from 'antd';
import { translateOrderStatus, translateStatusColor } from '../../../../helpers/order.helper';
import './assets/css/invoice.css'; // Import your CSS file

const Invoice = ({ data }) => {

    function onPrint() {
        document.getElementById('printbutton').remove();
        window.print()
    }

    return (
        <>
            {/* <Button id='printbutton' color='primary' fill='solid' onClick={onPrint} style={{ right: 20 }}>
                    พิมพ์
                </Button> */}
            {data && (
                <>
                    <p id="printbutton" onClick={onPrint} style={{ color: 'blue', cursor: 'pointer' }}>สั่งพิมพ์</p>
                    <div className="wrapper-invoice" id="content">
                        {/* switch mode rtl by adding class rtl on invoice class */}
                        <div className="invoice">
                            <div className="invoice-information">
                                <p><b>ลำดับที่ #</b>: {data.orderIndex}</p>
                                <p><b>สถานะ #</b>: {
                                    <Tag color={translateStatusColor(data.status)} key={data._id}>
                                        {translateOrderStatus(data.status)}
                                    </Tag>
                                }</p>
                                <p><b>เลขที่คำสั่งซื้อ #</b>: {data.orderNumber}</p>
                                <p><b>วิธีรับสินค้า #</b>: {data.logisticMethod}</p>
                                <p><b>วันที่สั่ง </b>: {dayjs(data.createdAt).format('DD/MM/YYYY HH:mm:ss')}</p>

                                <div className="head client-data">
                                    <h5>ลูกค้า</h5>
                                    <p>{data.shippingAddress.name}<br />
                                        {data.logisticMethod === 'จัดส่ง' && (
                                            <>
                                                {`${data.shippingAddress.address} ต.${data.shippingAddress.subdistrict} อ.${data.shippingAddress.district}`}<br />
                                                {`จ.${data.shippingAddress.province} ${data.shippingAddress.zipcode}`}
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                            {/* logo brand invoice */}
                            <div className="invoice-logo-brand">
                                {/* <h2>Tampsh.</h2> */}
                                <img src="https://atomsoft.co/static/media/icon.f4c24201e95e2d62fc4b.png" alt="atomsoft.co" />
                            </div>
                            {/* invoice head */}
                            <div className="invoice-head">
                                <div className="head client-info">
                                    <h2>{`${data.storeName}`}</h2><br />
                                </div>
                            </div>
                            {/* invoice body*/}
                            <div className="invoice-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ลำดับ</th>
                                            <th>สินค้า</th>
                                            <th>จำนวน</th>
                                            <th>ราคา/หน่วย</th>
                                            <th>จำนวนเงิน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.items.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.product_name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                                <div className="flex-table">
                                    <div className="flex-column"></div>
                                    <div className="flex-column">
                                        <table className="table-subtotal">
                                            <tbody>
                                                <tr>
                                                    <td>รวม</td>
                                                    <td>{data.totalAmount} บาท</td>
                                                </tr>
                                                <tr>
                                                    <td>ส่วนลด</td>
                                                    <td>{data.discount || 0} บาท</td>
                                                </tr>
                                                <tr>
                                                    <td>ค่าจัดส่ง</td>
                                                    <td>{data.deliveryfee || 0} บาท</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* invoice total  */}
                                <div className="invoice-total-amount">
                                    <p>รวมทั้งสิ้น: {data.totalAmount + data.deliveryfee} บาท</p>
                                </div>
                            </div>
                            {/* invoice footer */}
                            <div className="invoice-footer">
                                <p>ขอบคุณที่ใช้บริการ</p>
                                <QRCode type="canvas" size={120} value={data.orderNumber} />
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}

export default Invoice;
