import { Tooltip, Tag, Popover, Button, Space, Image } from 'antd';
import { translateOrderStatus, translateStatusColor } from '../../../helpers/order.helper';

export default [
  {
    title: 'ลำดับที่',
    dataIndex: 'orderIndex',
    key: 'orderIndex',
  },
  {
    title: 'หมายเลขคำสั่งซื้อ',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
    filterMode: 'tree',
    onFilter: (value, record) => record.orderNumber.startsWith(value),
    filterSearch: true,
    sorter: (a, b) => b.orderNumber - a.orderNumber,
  },
  {
    title: 'วันที่',
    dataIndex: 'orderDate',
    key: 'orderDate',
  },
  {
    title: 'ชื่อร้าน',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'จำนวนเงิน',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (text, record) => (
      <>
        <Tag color="green" key={record._id}>
          {record.totalAmount + record.deliveryfee}
        </Tag>
      </>
    )
  },
  {
    title: 'ชื่อลูกค้า',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <>
        <Tag color="green" key={record._id}>
          {record.shippingAddress.name}
        </Tag>
      </>
    )
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => (
      <Tag color={translateStatusColor(record.status)} key={record._id}>
        {translateOrderStatus(record.status)}
      </Tag>
    )
  },
  {
    title: 'หลักฐานชำระเงิน',
    dataIndex: 'paymentDoc',
    key: 'paymentDoc',
    render: (text, record) => (
      <>
        {record.paymentDoc ? (
          <>
            <Image
              width={30}
              src={`https://www.atom-soft.com/image-api/${record.paymentDoc}`}
              placeholder={
                <Image
                  preview={false}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                  width={30}
                />
              }
            />
          </>
        ) : (
          <Tag color="red">ไม่ได้ส่ง</Tag>
        )}
      </>
    )
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'customerNote',
    key: 'customerNote',
    render: (text, record) => (
      <>
        {record.customerNote}
      </>
    )
  },
  {
    title: 'ดำเนินการ',
    dataIndex: 'note',
    key: 'note',
    render: (text, record) => (
      <>
        {/* action in index.js file */}
      </>
    )
  }
];

