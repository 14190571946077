export default [
  {
    title: 'ลำดับ',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'รายละเอียด',
    dataIndex: 'description',
    key: 'descripttion',
  }
];