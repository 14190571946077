import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Result } from 'antd';
import { useNavigate } from "react-router-dom";

import { register } from "../slices/auth";
import { clearMessage } from "../slices/message";

const Register = () => {
  const navigate = useNavigate();
  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        "len",
        "ชื่อผู้ใช้ ต้องยาวอย่างน้อย 3 ตัวอักษร และไม่เกิน 20 ตัวอักษร",
        (val) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .matches(/^[a-z]*$/, 'ต้องเป็นตัวอักษรเล็กเท่านั้น !')
      .required("ช่องนี้จำเป็นต้องกรอก!"),
    name: Yup.string()
      .test(
        "len",
        "ชื่อ ต้องยาวอย่างน้อย 3 ตัวอักษร และไม่เกิน 20 ตัวอักษร",
        (val) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("ช่องนี้จำเป็นต้องกรอก!"),
    email: Yup.string()
      .email("กรุณาป้อนอีเมล์ที่ถูกต้อง เช่น star@gmail.com")
      .required("ช่องนี้จำเป็นต้องกรอก!"),
    password: Yup.string()
      .test(
        "len",
        "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร แต่ไม่เกิน 40 ตัวอักษร",
        (val) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("ช่องนี้จำเป็นต้องกรอก!"),
  });

  const handleRegister = (formValue) => {
    const { username, email, name, password } = formValue;

    setSuccessful(false);

    dispatch(register({ username, email, name, password }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  return (
    <div className="col-md-12 signup-form" style={{ marginTop: '50px', minHeight: '60vh', padding: '20px' }}>
      <div className="ant-card" style={{padding: '20px'}}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div>
                <img
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  alt="profile-img"
                  className="profile-img-card"
                />
                <div className="form-group">
                  <label htmlFor="username">ชื่อผู้ใช้</label>
                  <Field name="username" type="text" className="form-control" />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">ชื่อ-นามสกุล</label>
                  <Field name="name" type="text" className="form-control" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">อีเมล์</label>
                  <Field name="email" type="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">รหัสผ่าน</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">สมัคร</button>
                </div>
              </div>
            )}
          </Form>
        </Formik>

        {successful && (
          <>
            <Result
              status="success"
              title="การสมัครสมาชิกเสร็จสมบูรณ์"
              subTitle="คุณสามารถเข้าสู่ระบบด้วยชื่อผู้ใช้ และรหัสผ่านที่สมัครไว้ได้"
              extra={[
                <Button type="primary" key="console" onClick={() => navigate('/login')}>
                  เข้าสู่ระบบ
                </Button>,
              ]}
            />
          </>
        )}

      </div>

      {!successful && message && (
        <div className="form-group">
          <div
            className={successful ? "alert alert-success" : "alert alert-danger"}
            role="alert"
          >
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
