import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import locale from 'antd/es/date-picker/locale/th_TH';
import AppConfig from "./config/AppConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ShopCartSerivce from "./services/shopcart.service";
import liff from '@line/liff';
import { loginLine } from "./slices/auth";

//theme
import { NotificationOutlined, UserOutlined, SettingOutlined, HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Row, Col, Avatar, Spin } from 'antd';
import { Button, ConfigProvider, Space } from 'antd';
import BoxedLayout from './components/layouts/boxedLayout';
import mobileLayout from "./components/layouts/mobileLayout";

import userService from "./services/user.service";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import MyAccount from "./components/profile/myAccount";
import OverviewAccount from "./components/profile/overview";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Store from "./components/store";
import ProfileEditScreen from "./components/profile/index";
import SignInScreen from "./components/SignIn";
import ProductListScreen from "./components/storeDetail";
import UsersManagement from "./components/admin/users";
import StoreDetail from "./components/admin/storeDetail";
import ProductsManagement from "./components/admin/products";
import EventDetail from "./components/events/detail";
import EventsList from "./components/admin/event";
import AdminEventDetail from "./components/admin/eventDetail";
import EventRegister from "./components/events/register";
import ShoppingCart from "./components/cart";
import MemberQRCode from './components/profile/memberQR';
import OrderDetails from "./components/profile/myOrders/orderDetail";
import OrderManagement from "./components/admin/order";
import AdminStore from './components/admin/order/store';
import MyOrders from './components/profile/myOrders';
import AboutUs from "./components/aboutus";
import SearchProducts from "./components/storeDetail/searchProducts";
import ConfirmPayment from "./components/profile/confirmPayment";
import EventRegisterList from "./components/eventRegister";
import AdsManager from "./components/admin/ads";

//admin interface
import StoreCreate from "./components/admin/stores/create";
import StoreList from "./components/admin/stores";

//global state
import { logout } from "./slices/auth";
import EventBus from "./common/EventBus";
import MobileLayout from "./components/layouts/mobileLayout";
import PaymentManagement from './components/admin/payment';

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('เมนู', 'menu', <HomeOutlined />, [
    getItem('สินค้า', 'products', null, [getItem((<Link to={"/admin/add-product"}>เพิ่มสินค้าใหม่</Link>), 'add-product'), getItem('รายการสินค้า', 'product-list')], 'group'),
    getItem('ร้านค้า', 'stores', null, [getItem((<Link to={"/admin/add-store"}>เพิ่มร้านใหม่</Link>), 'add-store'), getItem(<Link to={"/admin/stores-list"}>รายชื่อร้าน</Link>, 'stores-list')], 'group'),
  ]),
  {
    type: 'divider',
  },
  getItem('การตั้งค่า', 'settings', <SettingOutlined />, [
    getItem('บัญชีผู้ใช้', 'accounts', null, [getItem((<Link to={"/admin/users-list"}>จัดการบัญชีผู้ใช้</Link>), 'administrator'),], 'group'),
    getItem('ร้านค้า', 'store', null, [getItem((<Link to={"/profile"}>ข้อมูลร้าน</Link>), 'store-setting'),], 'group'),
  ]),
];


const App = () => {
  const [deviceType, setDeviceType] = useState("");
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showUserBoard, setShowUserBoard] = useState(false);
  const { token: { colorBgContainer }, } = theme.useToken();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [ownerId, setOwnerId] = useState(null);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [cartData, setCartData] = useState([]);

  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  useEffect(() => {
    document.title = AppConfig.APP_NAME;
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setShowUserBoard(currentUser.roles.includes("ROLE_USER"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  useEffect(() => {
    if (currentUser) {
      loadCartItems(currentUser.id);
    }
  }, []);

  const forceRerender = () => {
    // Update the state to trigger a re-render
    setRerenderKey((prevKey) => prevKey + 1);
  };

  const onUpdateCart = async () => {
    if (currentUser) {
      loadCartItems(currentUser.id);
      forceRerender();
    }
  }

  const onDelete = async (id) => {
    const response = await ShopCartSerivce.deleteById(id);
    //console.log('items deleted App.js', response);
    if (response.status == 204) {
      const updatedCartData = cartData.filter((item) => item.id !== id);
      if (currentUser) {
        loadCartItems(currentUser.id);
      }
    } else {
      setCartData([]);
      forceRerender();
    }
  }

  const loadCartItems = async (userId) => {
    const response = await ShopCartSerivce.getAllByUserId(userId);
    //console.log('App.js loading cart items...', response);
    if (response) {
      setCartData(response.data.Items);
      setIsloading(false);
    } else {
      setCartData([]);
      setIsloading(false);
    }
  }

  const validateMessages = {
    required: "'${label}' ห้ามว่างไว้!",
    // ...
  };

  return (
    // <Router>
    <ConfigProvider
      locale={locale}
      form={{ validateMessages }}
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#007bff',
          borderRadius: 2,
          // Alias Token
          colorBgContainer: '#ffffff',
          colorBgLayout: '#ffffff',
          backgroundColor: '#ffffff',
          //fontFamily: '
          fontFamily: 'Prompt',
          fontSize: 15,
          Menu: {
            colorPrimary: '#070054',
            colorBgLayout: '#ffffff',
          }
        },
      }}>
      {deviceType == 'Mobile' && (
        <>
          <MobileLayout>
            <Routes>

              {/* public route */}
              {/* <Route path="/events-map" element={<EventsMapScreen />} /> */}
              {/* <Route path="/events-user" element={<EventUserScreen />} /> */}
              <Route path="/aboutus" element={<AboutUs />} />

              {/* User Route */}
              <Route path="/my-orders" element={isLoggedIn ? <MyOrders /> : <SignInScreen />} />
              <Route path="/order-detail/:orderId" element={<OrderDetails />} />
              <Route path="/profile" element={<ProfileEditScreen />} />
              <Route path="/my-qrcode" element={isLoggedIn ? <MemberQRCode /> : <SignInScreen />} />
              <Route path="/confirm-payment" element={<ConfirmPayment />} />
              <Route path="/user/:pathName" element={isLoggedIn ? <ProfileEditScreen /> : <SignInScreen />} />
              <Route path="/user" element={<BoardUser />} />
              <Route path="/user/overview" element={isLoggedIn ? <OverviewAccount /> : <SignInScreen redirectUrl={'/user/overview'} />} />
              <Route path="/:store/:id" element={<ProductListScreen />} />
              <Route path="/:id" element={<ProductListScreen />} />
              <Route path="/events/:eventId" element={<EventDetail />} />
              <Route path="/events/register/:eventId" element={<EventRegister />} />
              <Route path="/checkout/:sessionId" element={isLoggedIn ? <ShoppingCart cartData={cartData} onUpdateCart={onUpdateCart} onDelete={onDelete} setCartData={setCartData} rerenderKey={rerenderKey} /> : <SignInScreen />} />
              <Route path="/events/register-list/:eventId" element={<EventRegisterList />} />

              <Route exact path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<SignInScreen />} />
              <Route path="/register" element={<Register />} />
              <Route path="/mod" element={<BoardModerator />} />
              <Route path="/store" element={<Store onSearch={setKeyword} keyword={keyword} />} />
              <Route path="/search" element={<SearchProducts onSearch={setKeyword} />} />
              <Route path="/payment-info" element={<PaymentManagement />} />

              {/* Admin Route */}
              <Route path="/admin/ads" element={<AdsManager />} />
              <Route path="/admin/stores-orders" element={<AdminStore />} />
              <Route path="/admin/order/:storeId" element={<OrderManagement />} />
              <Route path="/admin/events-list" element={<EventsList />} />
              <Route path="/admin/events-detail/:id" element={<AdminEventDetail />} />
              <Route path="/admin/stores-list" element={<StoreList />} />
              <Route path="/admin/add-store" element={<StoreCreate />} />
              <Route path="/admin/users-list" element={<UsersManagement />} />
              <Route path="/admin/store/:id" element={<StoreDetail />} />
              <Route path="/admin" element={<BoardAdmin />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </MobileLayout>
        </>
      )}

      {deviceType == 'Desktop' && (
        <>
          <BoxedLayout onSearch={setKeyword} cartData={cartData} onUpdateCart={onUpdateCart} onDelete={onDelete} setCartData={setCartData} rerenderKey={rerenderKey}>
            <Routes>
              {/* public route */}
              {/* <Route path="/events-map" element={<EventsMapScreen />} /> */}
              {/* <Route path="/events-user" element={<EventUserScreen />} /> */}
              <Route path="/aboutus" element={<AboutUs />} />

              {/* User Route */}
              <Route exact path="/" element={<Home />} />
              <Route path="/login" element={<SignInScreen />} />
              <Route path="/home" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<ProfileEditScreen />} />
              <Route path="/user/:pathName" element={<ProfileEditScreen />} />
              <Route path="/user" element={<BoardUser />} />
              <Route path="/user/overview" element={<OverviewAccount />} />
              <Route path="/confirm-payment" element={<ConfirmPayment />} />
              <Route path="/mod" element={<BoardModerator />} />
              <Route path="/store" element={<Store />} />
              <Route path="/:store/:id" element={<ProductListScreen onUpdateCart={onUpdateCart} />} />
              <Route path="/:id" element={<ProductListScreen onUpdateCart={onUpdateCart} />} />
              <Route path="/events/:eventId" element={<EventDetail />} />
              <Route path="/events/register/:eventId" element={<EventRegister />} />
              <Route path="/checkout/:sessionId" element={<ShoppingCart onUpdateCart={onUpdateCart} onDelete={onDelete} setCartData={setCartData} rerenderKey={rerenderKey} />} />
              <Route path="/my-qrcode" element={isLoggedIn ? <MemberQRCode /> : <SignInScreen />} />
              <Route path="/order-detail/:orderId" element={<OrderDetails />} />
              <Route path="/my-orders" element={isLoggedIn ? <MyOrders /> : <SignInScreen />} />
              <Route path="/search" element={<SearchProducts onSearch={setKeyword} keyword={keyword} />} />
              <Route path="/events/register-list/:eventId" element={<EventRegisterList />} />
              <Route path="/payment-info" element={<PaymentManagement />} />

              {/* Admin Route */}
              <Route path="/admin/ads" element={<AdsManager />} />
              <Route path="/admin/stores-orders" element={<AdminStore />} />
              <Route path="/admin/order/:storeId" element={<OrderManagement />} />
              <Route path="/admin/events-list" element={<EventsList />} />
              <Route path="/admin/events-detail/:id" element={<AdminEventDetail />} />
              <Route path="/admin/stores-list" element={<StoreList />} />
              <Route path="/admin/add-store" element={<StoreCreate />} />
              <Route path="/admin/users-list" element={<UsersManagement />} />
              <Route path="/admin/store/:id" element={<StoreDetail />} />
              <Route path="/admin" element={<BoardAdmin />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </BoxedLayout>
        </>
      )}
    </ConfigProvider>
    // </Router>
  );
};

export default App;
