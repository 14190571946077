import { Image } from 'antd';
export const Title = "จัดการกิจกรรม";
export default [
  {
    title: 'หน้าปก',
    dataIndex: 'logo',
    key: 'logo',
  },
  {
    title: 'ชื่อกิจกรรม',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'สถานที่',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'เจ้าของกิจกรรม',
  },
];