import React, { useState, useEffect } from 'react';
import { DatePicker, Spin, Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, InputNumber, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import locale from 'antd/es/date-picker/locale/th_TH';
import AppConfig from '../../../config/AppConfig';

import axios from 'axios';
import EventService from '../../../services/event.service';
import userService from '../../../services/user.service';
import ProductCategoryService from '../../../services/product-category.service';

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 16,
    },
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const EventDetailForm = ({ onOK, initialValues }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image1.png',
            status: 'done',
            url: initialValues.cover ? `http://www.atom-soft.com/image-api/${initialValues.cover}` : '', // Replace with actual image URLs
        }]);
    const [userList, setUserList] = useState([]);
    const [eventDate, setEventDate] = useState('2023-10-01');
    const [selectedDates, setSelectedDates] = useState(dayjs('2023-09-29T00:00:00').tz('Asia/Bangkok'));

    useEffect(() => {
        if (currentUser) {
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_OWNER"));
            getUserList();
            const eventDate = dayjs(initialValues.event_date, AppConfig.DATE_FORMAT);
            setEventDate(eventDate);
            initialValues.event_date = eventDate;
            setLoading(false);
        }

    }, []);


    const getCoverImage = async (initialValues) => {
        const uploadedCover = initialValues.cover ? [
            {
                uid: '-1',
                name: 'image1.png',
                status: 'done',
                url: initialValues.cover ? initialValues.cover : '', // Replace with actual image URLs
            }] : [];
        return uploadedCover;
    }

    const getUserList = async () => {
        const response = await userService.getUser();
        if (response.status == 200) {
            //map user array to options         
            const userItems = response.data.map((user) => {
                return { label: user.name ? user.name : user.username, value: user._id };
            })
            setUserList(userItems);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `แก้ไขข้อมูลเรียบร้อย`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        console.log(values);
        const eventId = initialValues._id;
        const ownerId = values.owner?values.owner:currentUser.id;
        const oldCover = values.cover;
        const eventDate =  dayjs(values.event_date).tz('Asia/Bangkok')
        const productImage = fileList.length >= 1 ? fileList[0].originFileObj ? await uploadImage() : { imageUrl: oldCover } : { imageUrl: null };
        //console.log('eventDate', eventDate);
        const response = await EventService.updateById(eventId, { ...values, cover: productImage.imageUrl, owner: ownerId });
        if (response) {
            console.log(response);
            successNotification('topRight');
            //onOK();
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getFile = (e) => {
        //console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    const handleDefaultValue = () => {
        if (selectedDates) {
            return dayjs(eventDate, AppConfig.DATE_FORMAT);
        }
        return [dayjs(), dayjs()];
    };

    if (loading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            <Form
                {...layout}
                form={form}
                initialValues={initialValues}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                    marginTop: 30,
                    margin: '50 auto',
                }}
            >
                <Form.Item
                    name="name"
                    label="ชื่อกิจกรรม"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ATOMSOFT" />
                </Form.Item>

                <Form.Item
                    name="address"
                    label="สถานที่จัด"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} placeholder={"53 ม.11 บ.ดวงเจริญ ต.ธาตุ อ.วารินชำราบ \nจ.อุบลราชธานี 34190"} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="เบอร์ติดต่อฝ่ายจัด"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="09465495xx, 08072926xx" />
                </Form.Item>

                <Form.Item
                    name="maximum_register"
                    label="จำนวนที่รับสมัคร"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    name="points"
                    label="คะแนนที่จะได้เมื่อสมัคร"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    name="external_registration_url"
                    label="ใช้ลิงค์รับสมัครภายนอก"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="https://docs.google.com/forms/d/e/..." />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="คำอธิบายย่อกิจกรรม"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea showCount maxLength={300} rows={6} placeholder="ขายและพัฒนาซอฟแวร์ POS เพื่อผู้ประกอบการร้านค้าไทย" />
                </Form.Item>
                {isAdmin && (
                    <>
                        <Form.Item
                            name="owner"
                            label="ผู้จัดกิจกรรม"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Select
                                showSearch
                                placeholder="เลือกบัญชีผู้ใช้"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={userList}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="event_date"
                    label="วันที่จัดกิจกรรม"
                    rules={[
                        {
                            required: true,
                        },
                    ]}>
                    <DatePicker format={AppConfig.DATE_FORMAT} locale={locale} />
                </Form.Item>

                <Form.Item
                    name="cover"
                    label="หน้าปกกิจกรรม"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    getValueFromEvent={getFile}
                >
                    <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={fileList}
                        previewFile={false}
                        onChange={handleUpload}
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    </Upload>
                </Form.Item>


                {/* Nest Form.List */}
                <Form.Item
                    label="ประเภทรุ่น">
                    <Form.List name={['generations']}>
                        {(subFields, subOpt) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: 16,
                                }}
                            >
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Form.Item noStyle name={[subField.name, 'id']}>
                                            <Input placeholder="A1" style={{minWidth: '50px'}}/>
                                        </Form.Item>
                                        <Form.Item noStyle name={[subField.name, 'name']}>
                                            <Input placeholder="ป้อนชื่อรุ่น" style={{minWidth: '350px'}}/>
                                        </Form.Item>
                                        <Form.Item noStyle name={[subField.name, 'price']}>
                                            <InputNumber placeholder="ค่าสมัคร" />
                                        </Form.Item>
                                        <CloseOutlined
                                            onClick={() => {
                                                subOpt.remove(subField.name);
                                            }}
                                        />
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => subOpt.add()} block>
                                    + เพิ่มรุ่น
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" style={{width: '200px'}}>
                        บันทึก
                    </Button>
                    {/* <Button htmlType="button" onClick={onReset}>
                        เคลียร์
                    </Button> */}
                </Form.Item>
            </Form >
        </>
    );
};

export default EventDetailForm;
