import axios from "axios";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}auth/`;

const register = (username, name, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    name,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const loginWithLine = (userId, displayName, pictureUrl, email) => {
  return axios
    .post(API_URL + "signin-line", {
      userId,
      displayName,
      pictureUrl,
      email
    })
    .then((response) => {
      //console.log('calling api line login...', response.data);
      if (response.data.email) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  loginWithLine,
  logout,
};

export default authService;
