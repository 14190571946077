import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Empty, Layout, Menu, Row, Col, Card, List, Drawer, Space, Button, Input, Image, Divider, Flex, Descriptions, Typography } from 'antd';
import { Popup, SearchBar, NavBar, Toast } from 'antd-mobile'
import { CloseOutlined } from '@ant-design/icons' //antd design icons import
import ProductDetailScreen from './ProductDetailScreen';
import StoreService from '../../services/stores.service';
import ProductService from '../../services/product.service';
import ProductCategoryService from '../../services/product-category.service';
import noImage from '../../assets/images/noImage.jpg';
import styles from './style.css';
import ProductItem from './ProductItem';
import liff from '@line/liff';
import AppConfig from '../../config/AppConfig';
import userService from '../../services/user.service';
import { login, logout, loginLine } from "../../slices/auth";
import UserService from "../../services/user.service";
import EventBus from '../../common/EventBus';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Title, Text } = Typography;

const ProductListScreen = ({ onUpdateCart, onSearch }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [content, setContent] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [storeData, setStoreData] = useState(null);
    const [data, setData] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [visible7, setVisible7] = useState(false)
    const [productId, setProductId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    
    const logOut = useCallback(() => {
        dispatch(logout());
        liff.logout();
    }, [dispatch]);

    // initialization of liff app
    useEffect(() => {
        const initializeLiff = async () => {
            try {
                await liff.init({ liffId: AppConfig.LIFF_ID });
                if (liff.isLoggedIn()) {
                    handleLineProfile();
                }
            } catch (error) {
                console.error("LIFF initialization failed", error);
            }
        };
        initializeLiff();
    }, []);

    const handleLineProfile = async () => {
        try {
            const profile = await liff.getProfile();
            const email = await liff.getDecodedIDToken().email;
            const lineUserData = {
                userId: profile.userId,
                displayName: profile.displayName,
                pictureUrl: profile.pictureUrl,
                email: email || profile.userId + '@gmail.com',
            };
            await dispatch(loginLine(lineUserData)).unwrap();
            if (isLoggedIn) {
                Toast.show({
                    icon: 'loading',
                    content: 'กรุณารอสักครู่...',
                    duration: 3,
                })
            }
        } catch (error) {
            console.error("Failed to log in with LINE: ", error);
        }
    };


    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                setContent(response.data);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('store', storeId);
        if (storeId) {
            setStoreId(storeId);
            loadData(storeId);
        }
    }, []);

    useEffect(() => {
        //?isShowModal=false&productId=664cb0cf52f0c210a4d1c98f
        // Extract the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const productId = urlParams.get('productId');
        const isShowModal = urlParams.get('isShowModal');

        setProductId(productId);
        setOpen(isShowModal === 'true');
        loadSelectedProduct(productId);

    }, []);


    const showDrawer = (product) => {
        console.log('product', `?isShowModal=true&productId=${product._id}`);
        setSelectedProduct(product);
        navigate(`?isShowModal=true&productId=${product._id}`)
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };



    const loadSelectedProduct = async (productId) => {
        const urlParams = new URLSearchParams(window.location.search);
        const Id = urlParams.get('productId');
        const response = await ProductService.getById(Id);
        //console.log('product Info', Id);
        if (response.status == 200) {
            setSelectedProduct(response.data);
        } else {
            setSelectedProduct(null);
            setOpen(false);
        }
    }

    const loadData = async (storeId) => {

        //get store data
        const storeResponse = await StoreService.getStoresById(storeId);
        //console.log('store Info', storeResponse);
        if (storeResponse.status == 200) {
            setStoreData(storeResponse.data);
        } else {
            setStoreData(null);
        }

        const response = await ProductService.getAllByStoreId(storeId);
        //console.log('products', response);
        if (response.status == 200) {
            setData(response.data);
        } else {
            setData([]);
        }

        const categoryRes = await ProductCategoryService.getAllByStoreId(storeId);
        //console.log('products', response);
        if (categoryRes.status == 200) {
            const categoryMenu = categoryRes.data.map((item) => {
                return { label: item.name, value: item._id };
            })
            setCategorys(categoryMenu);
        } else {
            setCategorys([]);
        }

    }

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (data.length <= 0) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }}
                    description={
                        <span>
                            ร้านนี้ยังไม่มีสินค้าใดๆ <a href={'#'} onClick={() => navigate('/store')}>เลือกซื้อร้านอื่น</a>
                        </span>
                    }
                />
            </div>
        </>
    }

    if (loading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '50px', justifyContent: 'center', alignContent: 'center' }} />
                <Result
                    status='waiting'
                    title='กรุณารอสักครู่...'
                />
            </div>
        </>
    }

    return (
        <>
            {screenWidth < 768 && (
                <>
                    <NavBar backArrow={false} style={{ position: 'fixed', top: 0, left: 0, zIndex: 1, background: '#007bff', height: '60px', width: '100%', color: 'white' }}>
                        <SearchBar
                            style={{ maxWidth: '90%', color: '#ffffff' }}
                            onChange={(val) => {
                                setKeyword(val);
                            }}
                            onSearch={(val) => {
                                navigate(`../search?keyword=${keyword}`);
                                onSearch(keyword);
                                Toast.show(`ผลการค้นหา：${keyword}`)
                            }}
                            placeholder='พิมพ์คำที่ต้องการค้นหา...'
                            showCancelButton
                            onCancel={() => {
                                navigate(`../search?keyword=${keyword}`);
                                onSearch(keyword);
                                Toast.show(`ผลการค้นหา：${keyword}`)
                            }}
                            cancelText={(<Text type="secondary" style={{ color: '#ffffff' }}>ค้นหา</Text>)} />
                    </NavBar>
                </>
            )}
            {screenWidth < 768 ? (
                <>
                    <Popup
                        title="รายละเอียดสินค้า"
                        position='right'
                        visible={open}
                        showCloseButton={false}
                        onClose={onClose}
                    >
                        <Content type="flex" justify="center" style={{
                            minHeight: '100vh',
                            overflow: 'auto',
                            height: '100%',
                            width: '100%',
                            paddingTop: '0px',
                            paddingBottom: '80px',
                        }}>
                            <ProductDetailScreen productInfo={selectedProduct} storeId={storeId} onUpdateCart={onUpdateCart} onClose={onClose} />
                        </Content>

                    </Popup>
                </>
            ) :
                <>
                    <Drawer
                        title="รายละเอียดสินค้า"
                        placement="bottom center"
                        onClose={onClose}
                        open={open}
                        size={screenWidth > 768 ? 'large' : 'large'}
                        showCloseButton={false}
                        destroyOnClose={true}
                        mask={true} // Disable the backdrop when snapped to the top
                        extra={
                            <Space>
                                <Button type="primary" onClick={onClose}>ปิดหน้าต่าง</Button>
                            </Space>
                        }>
                        <ProductDetailScreen productInfo={selectedProduct} storeId={storeId} onUpdateCart={onUpdateCart} onClose={onClose} />
                    </Drawer>
                </>}
            <div class="container" style={{ marginTop: screenWidth < 768 ? 60 : 0, marginBottom: screenWidth < 768 ? 50 : 0 }}>
                {storeData && (
                    <>
                        <Card
                            title={storeData.name}
                            cover={<Image
                                preview={false}
                                src={storeData.logo ? `https://www.atom-soft.com/image-api/${storeData.logo}` : noImage}
                                alt={storeData.name}
                                style={{ width: '90%', maxHeight: '400px', objectFit: 'contain', borderRadius: '10px', marginBottom: '20px', marginTop: '20px', margin: '20px' }}
                            />}
                            bordered={false}>
                            <Space
                                direction="vertical"
                                size="small"
                                style={{
                                    display: 'flex',
                                    marginBottom: '0px',
                                    marginTop: '0px',
                                }}
                            >
                                <Title level={3} style={{ marginTop: 0 }}>{storeData.name}</Title>
                                <p level={6} style={{ marginTop: 0, marginBottom: 0 }}>{storeData.description}</p>
                                <p level={6} style={{ marginTop: 0, marginBottom: 0 }}>{storeData.address}</p>
                                <p level={6} style={{ marginTop: 0 }}>โทร.{storeData.phone}</p>
                            </Space>

                        </Card>
                    </>
                )}
                <div class="product-grid">
                    {data.map(product => (
                        <ProductItem key={product.id} item={product} onSelect={(showDrawer)} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default ProductListScreen;
