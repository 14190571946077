class AppConfig {

  //Config Production (True) Or Development (False)
  static IS_PRODUCTION = true;

  //App Configuration
  static APP_NAME = 'ATOMSOFT';
  static APP_VERSION = 3.0;
  static APP_FOOTER = `ATOMSOFT`;
  static APP_FOOTER1 = ` 53 หมู่ที่ 11 ต.ธาตุ อ.วารินชำราบ \n จ.อุบลราชธานี 34190 โทร.0946549538`;
  static APP_FOOTER2 = `\ncopyright ©2023 by ATOMSOFT V.${this.APP_VERSION}`;
  static APP_DOMAIN = this.IS_PRODUCTION ? `https://api.atomsoft.co/` : `https://gorilla-welcome-hugely.ngrok-free.app`;
  static API_PORT = 8080;
  static MOUNT_PATH = 'api'
  static API_URL = this.IS_PRODUCTION ? `https://api.atomsoft.co/${this.MOUNT_PATH}/` : `http://localhost:8080/${this.MOUNT_PATH}/`;
  static DEFAULT_PRODUCT_CATEGORY_ID = '6607f02968d7afa56a0e4b24'; //default category if user not select product category
  static DATE_FORMAT = 'YYYY-MM-DD';
  static SOCKET_IO_URL = 'https://realtim-geolocation-location-server.vel1wt.easypanel.host/';

  //LINE LOGIN https://developers.line.biz/console/
  static LIFF_ID = this.IS_PRODUCTION?'2004425081-d00ramm9':'1660747288-J9qrn77D';

  //default delivey service charge
  static DEFAULT_DELIVERY_FEE = 0;

}

export default AppConfig;
