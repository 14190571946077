import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button, Space, Divider, Breadcrumb, Layout, Menu, theme, Row, Col, Avatar, Dropdown, Badge, Popover, List, InputNumber } from 'antd';
import { DeleteOutlined, SearchOutlined, ShoppingCartOutlined, BellOutlined, NotificationOutlined, UserOutlined, SettingOutlined, HomeOutlined, VideoCameraOutlined, UploadOutlined, AppstoreAddOutlined, BarChartOutlined } from '@ant-design/icons';
import ShopCartSerivce from "../../services/shopcart.service";

const { Paragraph } = Typography;

function ShoppingCartPopup({ cartData, onDelete, onUpdateCart, setCartData, rerenderKey }) {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);

    const handleDeleteClick = (itemId) => {
        if(cartData.length==1) {
            setCartData([]);
        }
        onDelete(itemId); // Call the parent component's delete function
    };

    useEffect(() => {
        onUpdateCart();
    }, []);

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength - 3) + '...';
        }
        return text;
    }

    const content = (<div>
        <Divider orientation="left">รถเข็น</Divider>
        {cartData && (
            <>
                <List
                    size="large"
                    footer={<div>
                        <Button type="primary" icon={<ShoppingCartOutlined />} style={{ width: '350px' }} onClick={() => {
                            if (!currentUser) {
                                navigate('/login');
                            } else {
                                navigate('/checkout/me');
                            }
                        }}>
                            รถเข็นของคุณ
                        </Button>
                    </div>}
                    bordered
                    dataSource={cartData}
                    renderItem={(item) => <List.Item style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignContent: 'flex-start' }}>
                            <Paragraph ellipsis={true}>
                                {truncateText(item.product_name, 20)}
                            </Paragraph>

                        </div>
                        <div style={{ display: 'flex', alignContent: 'flex-end' }}>
                            <InputNumber type="number" value={item.quantity} readOnly style={{ marginRight: '10px' }} />
                            <Tooltip title="ลบ" style={{ display: 'flex', alignContent: 'flex-end' }}>
                                <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger onClick={() => handleDeleteClick(item._id)} />
                            </Tooltip>
                        </div>
                    </List.Item>}
                />
            </>
        )}

    </div>
    )

    return (
        <div>
            <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ marginRight: '0px', cursor: 'pointer' }}>
                <Popover placement="bottomRight" content={content} trigger="click"
                // onClick={() => {
                //     if (!currentUser) {
                //         navigate('/login');
                //     }}}
                >
                    <Badge count={cartData?cartData.length:0}>
                        <Avatar shape="cirecle" size="small" icon={<ShoppingCartOutlined />} />
                    </Badge>
                </Popover>
            </p>
        </div>
    );
}

export default ShoppingCartPopup;
