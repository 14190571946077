import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}product`;

const getPublicProduct = () => {
  return axios.get(API_URL + "/all");
};

const getSearchByName = (storeId, keyword) => {
  //console.log(`${API_URL}/${storeId}/${keyword}`);
  return axios.get(`${API_URL}/${storeId}/${keyword}`, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getAllByStoreId = (storeId) => {
  return axios.get(AppConfig.API_URL+'store/' + storeId, { headers: authHeader() });
};

///api/product/:keyword
const getSearchAllByName = (keyword) => {
  return axios.get(`${API_URL}/${keyword}`, { headers: authHeader() });
};

//update by productId
//:  /api/product/:productId
const getById = (id) => {
  //console.log('getById', AppConfig.API_URL + 'product/' + id);
  return axios.get(AppConfig.API_URL + 'product-byid/' + id, { headers: authHeader() });
};

const create = (value) => {
  return axios.post(AppConfig.API_URL + 'product', value, { headers: authHeader() });
};

//update by productId
//:  /api/product/:productId
const updateById = (id, value) => {
  return axios.put(AppConfig.API_URL + 'product/' + id, { ...value }, { headers: authHeader() });
};

const ProductService = {
  getPublicProduct,
  getAll,
  getAllByStoreId,
  getById,
  create,
  updateById,
  getSearchByName,
  getSearchAllByName
};

export default ProductService