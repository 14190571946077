import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Form, message, Avatar, notification, Tabs, List, Tag } from 'antd';
import { CapsuleTabs, Empty, Card, Button, JumboTabs } from 'antd-mobile'
import { InformationCircleFill, AntOutline, RightOutline } from 'antd-mobile-icons'
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import userService from '../../../services/user.service';
import OrderService from '../../../services/order.service';
import EventRegisterService from '../../../services/event-register.service';
import { translateOrderStatus, translateEventStatus, translateStatusColor } from '../../../helpers/order.helper';
import dayjs from 'dayjs';
import myOrders from '../myOrders';

const MyOrders = () => {
    const [form] = Form.useForm();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [data, setData] = useState([]);
    const [registerData, setRegisterData] = useState([]);

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        }
        // Set the document title when the component mounts
        document.title = `ประวัติการสมัคร/ซื้อสินค้า`;
        scrollToTop();
    }, []);

    const loadData = async (userId) => {

        const responseRegister = await EventRegisterService.getAllByOwnerId(userId);
        //console.log('currentUser Register data:', responseRegister.data);
        if (responseRegister.status == 200) {
            setRegisterData(responseRegister.data);
        }

        const response = await OrderService.getAllByUserId(userId);
        if (response.status == 200) {
            //console.log('orders Data', response.data);
            setData(response.data);
        }
    }

    const onFinish = async (values) => {
        // Handle form submission here (e.g., update user data)
        //console.log('Form values:', values);
        const userId = currentUser.id;
        const response = await userService.UpdateById(userId, values);
        if (response.status == 200) {
            successNotification('topRight')
        }
    };

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setProfileImage(info.file.response.url);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    function EventsContents({ status }) {
        let filteredOrders;
        if (status) {
            filteredOrders = registerData.filter((order) => order.payment_status === status);
        } else {
            filteredOrders = registerData;
        }
        return (
            <List
                itemLayout="vertical"
                size="large"
                dataSource={filteredOrders ? filteredOrders : data}
                style={{ textAlign: 'left', height: '100%', minWidth: '310px', marginBottom: '50px' }}
                renderItem={(item, index) => (
                    <>
                        <Card
                            title={
                                <div style={{ fontWeight: 'normal' }}>
                                    <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
                                    ใบสมัครเลขที่: {item.registerNumber} <Tag color={translateStatusColor(item.payment_status)} key={item._id}>{translateEventStatus(item.payment_status)}</Tag>
                                </div>
                            }
                            extra={<RightOutline />}
                            style={{ borderRadius: '16px', marginBottom: '10px' }}
                        >
                            <div style={{ height: '25px' }}>{item.eventId[0].name}</div>
                            <div>รุ่นที่สมัคร {item.generations[0].label}</div>
                            <div>ชื่อ: {item.name}</div>
                            <div>team: {item.team}</div>
                            <div>โทร: {item.phone}</div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>ได้รับจำนวน: {item.points} แต้ม</div>
                            </div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>{item.register_date && 'สมัครเมื่อ: ' + dayjs(item.register_date).format('DD/MM/YYYY HH:mm:ss')}</div>
                            </div>
                        </Card>
                    </>
                )}
            />
        );
    }

    function OrderContents({ status }) {
        let filteredOrders;
        if (status) {
            filteredOrders = data.filter((order) => order.status === status);
            //setFilteredOrders(filteredOrders);
        }
        return (
            <List
                itemLayout="vertical"
                size="large"
                dataSource={filteredOrders ? filteredOrders : data}
                style={{ textAlign: 'left', minHeight: '100vh', marginBottom: '50px' }}
                renderItem={(item, index) => (
                    <>
                        <Card
                            title={
                                <div style={{ fontWeight: 'normal' }}>
                                    <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
                                    คำสั่งซื้อ: {item.orderNumber} <Tag color={translateStatusColor(item.status)} key={item._id}>{translateOrderStatus(item.status)}</Tag>
                                </div>
                            }
                            extra={<RightOutline />}
                            style={{ borderRadius: '16px', marginBottom: '10px', backgroundColor: '#519ff229' }}
                        >
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'row',
                                justifyContent: 'flex-start',
                                width: '100%',
                            }} onClick={e => e.stopPropagation()}>
                                {item.items.map(item => {
                                    return (
                                        <>
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<InformationCircleFill />}
                                                    title={item.product_name}
                                                    description={'ราคา ' + item.price + ' บาท x จำนวน ' + item.quantity}
                                                />
                                            </List.Item>
                                        </>)
                                })
                                }
                            </div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>ค่าสินค้า/บริการ {item.totalAmount} บาท</div>
                            </div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>ค่าจัดส่ง {item.deliveryfee} บาท</div>
                            </div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>รวมสุทธิ {item.totalAmount + item.deliveryfee} บาท</div>
                            </div>
                            <div style={{
                                paddingTop: '11px',
                                borderTop: '1px solid #e5e5e5',
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }} onClick={e => e.stopPropagation()}>
                                <div>{item.createdAt && 'สั่งซื้อเมื่อ: ' + dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                            </div>
                        </Card>
                    </>
                )}
            />
        );
    }

    return (
        <>
            {contextHolder}
            {/* Content */}
            {screenWidth < 768 ? (
                <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginTop: '50px',
                    marginBottom: '20px',
                    padding: '0px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    width: '100%',
                    background: '#e9ecef',
                }}>
                        <CapsuleTabs
                            onChange={() => loadData(currentUser.id)}
                        >
                            <CapsuleTabs.Tab title='รอชำระ' key='1' centered onChange={() => loadData(currentUser.id)}>
                                <OrderContents status={'PENDING'} />
                            </CapsuleTabs.Tab>
                            <CapsuleTabs.Tab title='จัดส่ง' key='2' centered onChange={() => loadData(currentUser.id)}>
                                <OrderContents status={'PAID'} />
                            </CapsuleTabs.Tab>
                            <CapsuleTabs.Tab title='สำเร็จ' key='3' centered onChange={() => loadData(currentUser.id)}>
                                <OrderContents status={'DELIVERED'} />
                            </CapsuleTabs.Tab>
                            <CapsuleTabs.Tab title='ยกเลิก' key='4' centered onChange={() => loadData(currentUser.id)}>
                                <OrderContents status={'CANCELED'} />
                            </CapsuleTabs.Tab>
                        </CapsuleTabs>
                        {/* <CapsuleTabs>
                            <CapsuleTabs.Tab title='การซื้อสินค้า' key='1'>
                                <Tabs
                                    style={{ padding: '0px' }}
                                    defaultActiveKey="1"
                                    centered
                                    onChange={() => loadData(currentUser.id)}
                                    items={[
                                        {
                                            label: `ที่ต้องชำระ`,
                                            key: 1,
                                            children: <OrderContents status={'PENDING'} />
                                        },
                                        {
                                            label: `ที่ต้องจัดส่ง`,
                                            key: 2,
                                            children: <OrderContents status={'PAID'} />
                                        },
                                        {
                                            label: `สำเร็จ`,
                                            key: 3,
                                            children: <OrderContents status={'DELIVERED'} />
                                        },
                                        {
                                            label: `ยกเลิก`,
                                            key: 4,
                                            children: <OrderContents status={'CANCELED'} />
                                        },
                                    ]}
                                />
                            </CapsuleTabs.Tab>
                        </CapsuleTabs> */}
                    </div>
                </>
            ) : (
                <>
                    <JumboTabs style={{ width: '100vh' }}>
                        <JumboTabs.Tab title='การซื้อสินค้า' key='1'>
                            <Tabs
                                style={{ padding: '0px' }}
                                defaultActiveKey="1"
                                centered
                                onChange={() => loadData(currentUser.id)}
                                items={[
                                    {
                                        label: `ที่ต้องชำระ`,
                                        key: 1,
                                        children: <OrderContents status={'PENDING'} />
                                    },
                                    {
                                        label: `ที่ต้องจัดส่ง`,
                                        key: 2,
                                        children: <OrderContents status={'PAID'} />
                                    },
                                    {
                                        label: `สำเร็จ`,
                                        key: 3,
                                        children: <OrderContents status={'DELIVERED'} />
                                    },
                                    {
                                        label: `ยกเลิก`,
                                        key: 4,
                                        children: <OrderContents status={'CANCELED'} />
                                    },
                                ]}
                            />
                        </JumboTabs.Tab>
                    </JumboTabs>
                </>
            )}
        </>
    );
};

export default MyOrders;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};