import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import userService from '../../../services/user.service';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const CreateForm = ({ onOK }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    
    useEffect(() =>{
        onFill();
    },[]);

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                อัพโหลด
            </div>
        </div>
    );

    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มข้อมูลใหม่เรียบร้อย`,
            description:
                'เพิ่มบัญชีผู้ใช้ใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    const onFinish = async (values) => {
        const roles = [values.roles];
        values.roles = roles;
        //console.log(values);
        const response = await userService.createUser(values);
        if (response) {
            console.log(response);
            onOK();
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({
            roles: 'user',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <>
            {contextHolder}
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                }}
            >
                <Form.Item
                    name="name"
                    label="ชื่อ-นามสกุล"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="สมชาย ใจดี" />
                </Form.Item>

                <Form.Item
                    name="username"
                    label="ชื่อผู้ใช้"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="ป้อนชื่อผู้ใช้" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="อีเมล์"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="อีเมล์ (สำคัญ)" />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="ป้อนรหัสผ่าน"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input maxLength={16} placeholder="********" type='password' />
                </Form.Item>

                <Form.Item
                    name="roles"
                    label="สิทธิ์การเข้าถึง"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        //labelInValue
                        defaultValue={{
                            value: 'user',
                            label: 'ผู้ใช้ทั่วไป',
                        }}
                        style={{
                            width: 200,
                        }}
                        options={[
                            {
                                value: 'user',
                                label: 'ผู้ใช้ทั่วไป',
                            },
                            {
                                value: 'moderator',
                                label: 'บรรณาธิการ',
                            },
                            {
                                value: 'admin',
                                label: 'ผู้ดูแลระบบ',
                            },
                            {
                                value: 'owner',
                                label: 'เจ้าของร้านค้า',
                            },
                            {
                                value: 'manager',
                                label: 'ผู้จัดการร้านค้า',
                            },
                            {
                                value: 'sale',
                                label: 'พนักงานขาย',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        บันทึก
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        เคลียร์
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default CreateForm;
