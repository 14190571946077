const AsyncStorageService = {
    async setItem(key, value) {
        try {
            const serializedValue = JSON.stringify(value);
            await localStorage.setItem(key, serializedValue);
        } catch (error) {
            throw new Error(`Error setting item: ${error}`);
        }
    },

    async getItem(key) {
        try {
            const serializedValue = await localStorage.getItem(key);
            return JSON.parse(serializedValue);
        } catch (error) {
            throw new Error(`Error getting item: ${error}`);
        }
    },

    async removeItem(key) {
        try {
            await localStorage.removeItem(key);
        } catch (error) {
            throw new Error(`Error removing item: ${error}`);
        }
    },

    async clear() {
        try {
            await localStorage.clear();
        } catch (error) {
            throw new Error(`Error clearing storage: ${error}`);
        }
    },
};

export default AsyncStorageService;