import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import liff from '@line/liff';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Spin, notification, Typography, InputNumber, Tag, Table, Input } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import {
    ConfigProvider, setDefaultConfig,
    Grid, Card, Toast, Button, List, Space,
    AutoCenter, Modal, Divider, ImageUploader, Form, NumberKeyboard,
    FloatingPanel
} from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import { LocationOutline, AntOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline } from 'antd-mobile-icons'
import EventRegisterService from '../../services/event-register.service';
import { translateOrderStatus, translateEventStatus, translateStatusColor } from '../../helpers/order.helper';

const { Text, Title } = Typography;

const EventRegisterList = ({ isMobile, redirectUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = React.useRef(null);
    const ref = useRef(null)
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsloading] = useState(true);
    const [eventId, setEventId] = useState(null);
    const [data, setData] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false)

    const anchors = [72]

    useEffect(() => {
        // Set the document title when the component mounts
        document.title = `รายชื่อผู้สมัครกิจกรรม`;
        setDefaultConfig({
            locale: enUS,
        })
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const eventId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('initialValues', initialValues);
        if (eventId) {
            setEventId(eventId);
            loadData(eventId);
        }

        scrollToTop();

    }, []);

    const loadData = async (eventId) => {
        setIsloading(true);
        const response = await EventRegisterService.getAllByEventId(eventId);
        console.log('registers data: ', response);
        if (response.status == 200) {
            setData(response.data);
            setSourceData(response.data);
            setIsloading(false);
        } else {
            setData([]);
            setSourceData([]);
            setIsloading(false);
        }
    }

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }


    const columns = [
        {
            title: 'No.',
            dataIndex: 'registerNumber',
            key: 'registerNumber',
            render: (_, record) => (
                <>
                    {record.registerNumber}
                </>
            )
        },
        {
            title: 'ชื่อ-นามสกุล',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <>
                    {record.name}
                </>
            )
        },
        {
            title: 'ทีม',
            dataIndex: 'team',
            key: 'team',
            responsive: ['md', 'lg'],
        },
        {
            title: 'สถานะ',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (_, record) => (
                <Space size="middle">
                    <Tag color={translateStatusColor(record.payment_status)} key={record._id}>{translateEventStatus(record.payment_status)}</Tag>
                </Space>
            ),
        },
    ];

    const onSearch = (value, _e, info) => {
        console.log(value)
        const currValue = value;
        setValue(currValue);
        const filteredData = data.filter(
            (entry) =>
                entry.name.toLowerCase().includes(currValue.toLowerCase())
        );
        console.log("filtered Data: ", filteredData);
        setSourceData(filteredData);
    };

    const Content = () => {
        return <>
            <ConfigProvider>
                <Card title={'รายชื่อผู้สมัครร่วมกิจกรรม'} style={{ minHeight: '80vh', marginTop: '50px', marginBottom: '50px' }}>
                <Input.Search
                        placeholder="พิมพ์ชื่อที่ต้องการค้นหา"
                        onSearch={onSearch}
                        allowClear
                    // style={{position: "float", width: "auto", height: "auto"}}
                    />
                <Table rowKey="_id" pagination={{ pageSizeOptions: ['10', '20', '30', '40'] }} columns={columns} dataSource={sourceData} />
                </Card>
                {/* </AutoCenter> */}
            </ConfigProvider >
        </>
    }

    return (
        <>
            {contextHolder}
            {/* Content */}
            <Content />
        </>
    );
};

export default EventRegisterList;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};