import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Card, Col, Row, Button, List, Drawer, Image, Typography } from 'antd';
import { PlusOutlined, SettingOutlined, EditOutlined, EllipsisOutlined, SendOutlined, OrderedListOutlined } from '@ant-design/icons';
import EventService from "../../services/event.service";
import UserService from "../../services/user.service";
import StoreService from "../../services/stores.service";
const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const { Title } = Typography;

const EventDetail = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [content, setContent] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [EventDetail, setEventDetail] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const eventId = parts[parts.length - 1]; // Retrieve the last part (storeId)

        UserService.getPublicContent().then(
            (response) => {
                setContent(response.data);
                loadData(eventId);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );

        scrollToTop();

    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const loadData = async (eventId) => {
        const response = await EventService.getByDetailById(eventId);
        //console.log('event', response);
        if (response.status == 200) {
            setEventDetail(response.data[0]);

            // Set the document title when the component mounts
            document.title = `${response.data[0].name}`;

        } else {
            setData([]);
        }
    }


    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <Content type="flex" justify="center" style={{
                minHeight: '80vh',
                overflow: 'auto',
                height: '99%',
                width: '100%',
                paddingTop: '55px',
            }}>
                {/* <Row type="flex" justify="center"> */}
                {EventDetail && (
                    <>
                        {/* <Card title={EventDetail ? `${EventDetail.name}` : null} style={{ minHeight: '80vh', width: '100%', justifyItems: 'center', justifyContent: 'center', margin: '0 auto' }}> */}
                        <Card
                            cover={
                                <Image
                                    preview={false}
                                    src={EventDetail.cover ? `https://www.atom-soft.com/image-api/${EventDetail.cover}` : null}
                                    style={{
                                        display: 'block',
                                        maxWidth: '100%',
                                        maxHeight: '350px',
                                        width: 'auto',
                                        height: 'auto',
                                        margin: '0 auto 10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            }
                            actions={[
                                <>
                                    <Row gutter={1}>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 12 : 6}>
                                            <Title level={5}>รับสมัคร</Title>
                                            <Title level={1} style={{ marginTop: '5px' }}>{EventDetail.maximum_register}</Title>
                                        </Col>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 12 : 6}>
                                            <Title level={5}>ผู้สมัคร</Title>
                                            <Title level={1} style={{ marginTop: '5px' }}>{EventDetail.external_registration_url ? '-' : EventDetail.totalRegistrations || 0}</Title>
                                        </Col>
                                        <Col className="gutter-row" span={screenWidth < 768 ? 24 : 12}>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    if (EventDetail.external_registration_url) {
                                                        window.open(EventDetail.external_registration_url, '_blank');
                                                    } else {
                                                        navigate('/events/register/' + EventDetail._id)
                                                    }
                                                }}
                                                icon={<SendOutlined />}
                                                block
                                                style={{ borderRadius: 10, width: '100%', height: '50px', marginBottom: 10 }}>
                                                สมัครร่วมกิจกรรม
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    if (EventDetail.external_registration_url) {
                                                        window.open(EventDetail.external_registration_url, '_blank');
                                                    } else {
                                                        navigate('/events/register-list/' + EventDetail._id)
                                                    }
                                                }}
                                                icon={<OrderedListOutlined />}
                                                block
                                                style={{ borderRadius: 10, width: '100%', height: '50px', marginBottom: 10 }}>
                                                ตรวจสอบรายชื่อ
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    navigate('/confirm-payment');
                                                }}
                                                icon={<PlusOutlined />}
                                                block
                                                style={{ borderRadius: 10, width: '100%', height: '50px', marginBottom: 10 }}>
                                                แจ้งชำระเงิน
                                            </Button>
                                        </Col>

                                    </Row>
                                </>
                            ]}
                        >
                            <Meta
                                title={EventDetail.name}
                                description={`${EventDetail.description}`}
                            />
                        </Card>

                        <Card title={'รายละเอียด'}>
                            <div dangerouslySetInnerHTML={{ __html: EventDetail.content }} />
                        </Card>

                    </>
                )}
                {/* </Row> */}
                {/* <div dangerouslySetInnerHTML={{ __html: EventDetail.content }} /> */}
            </Content>
        </>
    );
};

export default EventDetail;