import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}ads`;

const create = (value) => {
    return axios.post(API_URL, value, { headers: authHeader() });
};

const getById = (id) => {
    return axios.get(AppConfig.API_URL + 'ads-detail/' + id, { headers: authHeader() });
};

const getAll = () => {
    return axios.get(AppConfig.API_URL + `ads-all`, { headers: authHeader() });
};

const getByPosition = (keyword) => {
    return axios.get(AppConfig.API_URL + `ads/${keyword}`, { headers: authHeader() });
};

const getSearchByName = (keyword) => {
    return axios.get(`${AppConfig.API_URL}ads?keyword=${keyword}`, { headers: authHeader() });

};

const updateById = (id, value) => {
    return axios.put(AppConfig.API_URL + 'ads/' + id, {...value}, { headers: authHeader() });
};

const AdsService = {
    create,
    getSearchByName,
    getById,
    getAll, 
    getByPosition,
    updateById
};

export default AdsService;