import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}`;

const getUser = () => {
  return axios.get(API_URL + 'user/', { headers: authHeader() });
};

const getUserById = (userId) => {
  return axios.get(API_URL + 'user/' + userId, { headers: authHeader() });
};

const createUser = (values) => {
  return axios.post(API_URL + "auth/signup", values, { headers: authHeader() });
};

const UpdateById = (userId, value) => {
  return axios.put(API_URL + "user/" + userId, value, { headers: authHeader() });
};

const getPublicContent = () => {
  return axios.get(API_URL + "user/all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "user/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "user/admin", { headers: authHeader() });
};

const userService = {
  getUser,
  getUserById,
  createUser,
  UpdateById,
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};

export default userService