import axios from "axios";
import authHeader from "./auth-header";
import AppConfig from "../config/AppConfig";
const API_URL = `${AppConfig.API_URL}category`;

const getPublicProduct = () => {
  return axios.get(API_URL + "/all");
};

const getSearchByName = (storeId, keyword) => {
  //console.log(`${API_URL}/${storeId}/${keyword}`);
  return axios.get(`${API_URL}/${storeId}/${keyword}`, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getAllByStoreId = (storeId) => {
  return axios.get(API_URL+'/' + storeId, { headers: authHeader() });
};


const getById = (id) => {
  return axios.get(API_URL+'-byid/' + id, { headers: authHeader() });
};

const create = (value) => {
  return axios.post(API_URL, value, { headers: authHeader() });
};

const update = (value) => {
  console.log(value);
  return axios.put(API_URL + '/' + value.id, value, { headers: authHeader() });
};

const ProductCategoryService = {
  getPublicProduct,
  getAll,
  getById,
  getAllByStoreId,
  create,
  update,
  getSearchByName
};

export default ProductCategoryService