export default [
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'อีเมล์',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'points',
      dataIndex: 'points',
      key: 'points',
      render: (text, record) => (
        record.points?record.points:0
      )
    }
  ];