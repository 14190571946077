import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Tooltip, Row, Col, Card, Button, Carousel, Image, Typography, notification, InputNumber } from 'antd';
import { NavBar, Space, Toast, Selector, Popup, Result, Dialog, Modal } from 'antd-mobile';
import { AddOutline, MinusOutline } from 'antd-mobile-icons'
import noImage from '../../assets/images/noImage.jpg';
import ShopCartSerivce from '../../services/shopcart.service';
import AsyncStorageService from '../../helpers/AsyncStorageService';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import liff from '@line/liff';
import { login, loginLine } from "../../slices/auth";
import { logout } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import AppConfig from '../../config/AppConfig';
import userService from '../../services/user.service';
const { Paragraph } = Typography;

// Replace this with your product data
const product = {
  id: 1,
  name: 'Product Name',
  description: 'Product description goes here.',
  price: 25.0,
  image: 'https://shop.pospos.co/api/v1/images/1694523943066/product/1694531890209_8182611f450d46b8b1ca956e572ea27e.png'
};

export const ViewBlock = ({ title, children }) => (
  <div style={{ padding: 0 }}>
    <h5>{title}</h5>
    {children}
  </div>
);

const ProductDetailScreen = ({ productInfo, onUpdateCart, onClose }) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [api, contextHolder] = notification.useNotification();
  const [userData, setUserData] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [cartSessionId, setCartSessionId] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [totalVariantsPrice, setTotalVariantsPrice] = useState(productInfo.price);
  const [variantBalance, setVariantBalance] = useState(0);
  const [selectedImage, setSelectedImage] = useState(product.image);
  const [visible1, setVisible1] = useState(false)
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  //AUTHENTICATION
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();


  // initialization of liff app
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: AppConfig.LIFF_ID });
        if (liff.isLoggedIn()) {
          handleLineProfile();
        }
      } catch (error) {
        console.error("LIFF initialization failed", error);
      }
    };
    initializeLiff();
  }, []);


  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  useEffect(() => {
    if (currentUser) {
      const ownerId = currentUser.id;
      setOwnerId(ownerId);
      loadData(ownerId);
    }
  }, []);

  const loadData = async (ownerId) => {
    const response = await userService.getUserById(ownerId);
    if (response.status == 200) {
      setUserData(response.data[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    setSelectedImage(productInfo.image[0].imageUrl);
    if (currentUser) {
      setUserData(currentUser);
    }
  }, []);

  useEffect(() => {
    AsyncStorageService.getItem('cartSessionId')
      .then((result) => {
        setCartSessionId(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    toalVariantPrice()
    if (!selectedVariants) {
      setVariantBalance(productInfo.unit_in_stock);
    }
  }, [selectedVariants, totalVariantsPrice])

  const successNotification = (placement) => {
    setVisible1(true);
  };

  const onLogin = (values) => {
    const { username, password } = values;
    setLoading(true);
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        console.log('login successful');
        Modal.clear();
        navigate(`?isShowModal=true&productId=${productInfo._id}`);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };


  const handleLineProfile = async () => {
    try {
      const profile = await liff.getProfile();
      const email = await liff.getDecodedIDToken().email;
      const lineUserData = {
        userId: profile.userId,
        displayName: profile.displayName,
        pictureUrl: profile.pictureUrl,
        email: email || profile.userId + '@gmail.com',
      };
      await dispatch(loginLine(lineUserData)).unwrap();
      if (isLoggedIn) {
        Toast.show({
          icon: 'loading',
          content: 'กรุณารอสักครู่...',
          duration: 2,
        })
      }
    } catch (error) {
      console.error("Failed to log in with LINE: ", error);
    }
  };

  const handleLineLogin = async () => {
    if (!liff.isLoggedIn()) {
      liff.login();
    }
  }

  const handleAddToCart = async (productItem) => {
    if (!currentUser) {
      Modal.confirm({
        transparent: false,
        header: (<>
          <ViewBlock title='เข้าสู่ระบบก่อน'>
            <Form
              ref={formRef}
              layout="horizontal"
              small="small"
              name="loginForm"
              style={{
                maxWidth: 600,
              }}
              onFinish={onLogin}
              autoComplete="off"
            >
              <Form.Item
                label="ชื่อผู้ใช้"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาป้อนชื่อผู้ใช้!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="รหัสผ่าน"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาป้อนรหัสผ่าน!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
          </ViewBlock>
        </>
        ),
        content: (<>
          <Button
            type="primary"
            style={{
              borderRadius: '4px',
              fontFamily: 'Prompt',
              fontSize: 17,
              color: '#ffffff',
              height: '50px',
              width: '100%'
            }}
            onClick={() => {
              Toast.show({
                icon: 'loading',
                content: 'กำลังเข้าสู่ระบบ…',
              })
              formRef.current.submit();
            }}
          >
            <p style={{ padding: 5 }}>เข้าสู่ระบบ</p>
          </Button>
        </>),
        confirmText: 'เข้าสู่ระบบด้วย LINE',
        cancelText: 'ยกเลิก',
        onConfirm: async () => {
          handleLineLogin();
          Toast.show({
            icon: 'loading',
            content: 'กำลังเข้าสู่ระบบ…',
          })
          throw new Error()
        },
      })

    } else {

      if (productItem.price <= 0) {
        Dialog.alert({
          content: 'ไม่สามารถซื้อสินค้าที่ราคา 0 บาท ได้ !!',
          confirmText: 'ตกลง',
          onConfirm: () => {
            console.log('price is 0')
          },
        })
      } else {

        if (productItem.unit_in_stock > 0) {

          //console.log(userData);
          const userId = userData ? userData.id : null;
          // add to cart logic here
          const totalItemPrice = productItem.price * quantity;
          const data = { ...productItem, userId: ownerId, productId: productItem._id, price: productItem.price, variants: selectedVariants, product_name: productItem.name, quantity: quantity, discount: 0, total: totalItemPrice, serviceCharge: 0 }
          const response = await ShopCartSerivce.addToCart(data, userId);
          //console.log('Added to cart:', response);

          if (response.status == 200) {
            successNotification('bottom')
          }

          onUpdateCart();

        } else {
          Dialog.alert({
            content: 'ยอดคงเหลือไม่เพียงพอ!!',
            confirmText: 'ตกลง',
            onConfirm: () => {
              console.log('price is 0')
            },
          })
        }
      }

    }

  };

  const back = () => {
    onClose();
    Toast.show({
      content: 'กำลังโหลด...',
      duration: 1000,
    })
  }

  const inCrease = () => {
    if (quantity < productInfo.unit_in_stock) {
      setQuantity(quantity + 1)
    }
  }

  const deCrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  // calculate total price
  const toalVariantPrice = () => {
    let price = 0;
    // selectedVariants.forEach((variant) => {
    //   price += variant.price
    // });
    const total = price + productInfo.price
    setTotalVariantsPrice(total)
    return total
  }

  const handleVariantChange = (variantName, variantId, value) => {

    // Create a copy of the existing selected variants
    const updatedVariants = [...selectedVariants];

    // Check if a variant with the same variantId exists in the array
    const existingVariantIndex = updatedVariants.findIndex(
      (variant) => variant._id === value._id
    );

    // Check if avariant Id exists
    const existingVariantId = updatedVariants.findIndex(
      (variant) => variant.variantId === variantId
    );

    if (existingVariantId !== -1) {
      updatedVariants[existingVariantId] = { ...value, variantName: variantName, variantId: variantId };
    } else {
      // If no existing variant is found, add a new variant
      updatedVariants.push({ ...value, variantName: variantName, variantId: variantId });
    }

    //const totalPrice = toalVariantPrice();
    // Set the updated variants in the state
    setSelectedVariants(updatedVariants);
    setVariantBalance(value.quantity);
    console.log('selectedVariants', updatedVariants);

  };

  // Function to update screenWidth state when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to update screenWidth when the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Popup
        visible={visible1}
        onMaskClick={() => {
          setVisible1(false)
        }}
        onClose={() => {
          setVisible1(false)
        }}
        bodyStyle={{ height: '40vh', textAlign: 'center' }}
      >
        <Result
          status='success'
          title='สำเร็จ'
          description='หยิบสินค้าลงตะกร้าเรียบร้อย'
        />
        <Button block color='primary' size='large' style={{ color: 'blue' }}
          onClick={() => {
            navigate('/checkout/me');
          }}
        >
          เปิดดูสินค้าในตะกร้า
        </Button>
      </Popup>
      {screenWidth < 768 && (<NavBar onBack={back} style={{ position: 'fixed', top: 0, left: 0, zIndex: 1, background: '#007bff', width: '100%', color: 'white' }}>รายละเอียดสินค้า</NavBar>)}
      <div style={{ margin: '0 auto', padding: '5px', maxWidth: '1024px', paddingTop: '55px' }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} lg={6} style={{ textAlign: 'center' }}>
            {productInfo.image[0].imageUrl ? (
              <>
                <Image
                  src={productInfo.image[0].imageUrl ? `https://www.atom-soft.com/image-api/${productInfo.image[0].imageUrl}` : noImage}
                  alt={productInfo.name}
                  style={{ width: '90%', maxHeight: '400px', objectFit: 'contain', borderRadius: '10px', marginBottom: '20px', marginTop: '20px', margin: '20px' }}
                />
              </>
            ) : (
              <>
                <ImgText>
                  {productInfo.name}
                </ImgText>
              </>
            )}
          </Col>
          <Col xs={24} sm={24} lg={16}>
            <Card>
              <h3>{productInfo.name}</h3>
              <Paragraph>
                {productInfo.description}
              </Paragraph>
              <h2 style={{ color: 'red' }}>{productInfo.price.toFixed(2)}</h2>
              <Space direction='vertical' style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', textAlignLast: 'center', marginBottom: '60px' }}>
                {productInfo.variants.map((variant) => (
                  <>
                    <div key={variant.id}>
                      <p>เลือก {variant.name}:</p>
                      <Selector
                        columns={3}
                        multiple={false}
                        onChange={(value) => {
                          //console.log(value);
                          const item = variant.options.filter(item => item._id == value);
                          handleVariantChange(variant.name, variant._id, item[0])
                        }}
                        style={{ width: '300px' }}
                        options={variant.options.map(item => {
                          return { label: item.name, value: item._id, key: item._id }
                        })}
                      >
                      </Selector>
                      {/* <Select
                        showSearch
                        placeholder={"กรุณาเลือก" + variant.name}
                        // defaultValue={variant.options[0]._id} // Set the default value
                        onChange={(value) => {
                          const item = variant.options.filter(item => item._id === value);
                          handleVariantChange(variant.name, variant._id, item[0])
                        }}
                        style={{ width: '300px' }}
                      >
                        {variant.options.map((option) => (
                          <Option key={option._id} value={option._id}>
                            {option.name}
                          </Option>
                        ))}
                      </Select> */}
                    </div>
                  </>
                ))}
                <p>คงเหลือในคลัง {variantBalance <= 0 ? productInfo.unit_in_stock : variantBalance} ชิ้น</p>
                <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}>
                  <InputNumber
                    size='large'
                    addonBefore={<Button type="primary" shape="circle" icon={<MinusOutline />} onClick={deCrease} />}
                    addonAfter={<Button type="primary" shape="circle" icon={<AddOutline />} onClick={inCrease} />}
                    defaultValue={quantity ? quantity : 1}
                    value={quantity}
                    onChange={(value) => {

                      if (value <= productInfo.unit_in_stock) {
                        setQuantity(value)
                      } else {
                        setQuantity(productInfo.unit_in_stock)
                      }

                      // if (value <= variantBalance && value >= 1) {
                      //   setQuantity(value)
                      // } else {
                      // setQuantity(value)
                      //}
                    }}
                  />
                </div>
                {screenWidth < 768 ? (
                  <NavBar
                    backArrow={false}
                    style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1, background: '#ffffff', height: '120px', width: '100%', color: 'white' }}>
                    <Button type="primary" onClick={() => handleAddToCart(productInfo)} style={{ width: '300px', height: '50px', borderRadius: '10px', marginBottom: 50 }}>
                      หยิบใส่รถเข็น
                    </Button>
                  </NavBar>) : (
                  <>
                    <Button type="primary" onClick={() => handleAddToCart(productInfo)} style={{ width: '300px', height: '50px', borderRadius: '10px' }}>
                      หยิบใส่รถเข็น
                    </Button>
                  </>
                )}
              </Space>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
};

export default ProductDetailScreen;

const ImgText = styled.div`
  width: auto;
  height: 150px;
  border: 1px solid black;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #62676cb8;
`;