import React, { createContext, useContext, useReducer } from 'react';

// Create a context for the object
const ObjectContext = createContext();

// Create a custom hook to access the object and dispatch function
export function useObject() {
  return useContext(ObjectContext);
}

// Reducer function to update the object
function objectReducer(state, action) {
  switch (action.type) {
    case 'SET_OBJECT':
      return action.payload;
    default:
      return state;
  }
}

export function ObjectProvider({ children }) {
  const [object, dispatch] = useReducer(objectReducer, null);

  return (
    <ObjectContext.Provider value={{ object, dispatch }}>
      {children}
    </ObjectContext.Provider>
  );
}
