import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { NavBar, TabBar,  Image, AutoCenter, Divider   } from 'antd-mobile'
import {
    Route,
    useLocation,
    useNavigate,
    MemoryRouter as Router,
} from 'react-router-dom'
import {
    UserCircleOutline,
    AppstoreOutline
} from 'antd-mobile-icons'
import liff from '@line/liff';
import { ShopOutlined, ShoppingCartOutlined, HistoryOutlined } from '@ant-design/icons';
import AppConfig from '../../config/AppConfig';
import { loginLine } from "../../slices/auth";
import styles from './mobileStyle.css';
import userService from '../../services/user.service';

const Bottom = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { pathname } = location

    const setRouteActive = (value) => {
        navigate(value);
    }

    const tabs = [
        {
            key: '/home',
            title: 'หน้าแรก',
            icon: <AppstoreOutline />,
        },
        {
            key: '/store',
            title: 'ร้านค้า',
            icon: <ShopOutlined />,
        },
        {
            key: '/checkout/me',
            title: 'รถเข็น',
            icon: <ShoppingCartOutlined />,
        },
        {
            key: '/my-orders',
            title: 'ประวัติ',
            icon: <HistoryOutlined />,
        },
        {
            key: '/user/overview',
            title: 'บัญชี',
            icon: <UserCircleOutline />,
        },
    ]

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} safeArea>
            {tabs.map(item => (
                <TabBar.Item className="custom-tab-item" key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}


export default ({ children, cartData, onUpdateCart, onDelete, setCartData, rerenderKey }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [userData, setUserData] = useState(null);
    const [isOwner, setOwnerId] = useState(false);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        setIsloading(true);
        if (currentUser) {
            const ownerId = currentUser.id;
            setOwnerId(ownerId);
            loadData(ownerId);
        }
    }, [currentUser]);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            setUserData(response.data[0]);
        }
        setIsloading(false);
    }
    
    //LINE LIFF initialization
    const initLine = () => {
        liff.init({ liffId: AppConfig.LIFF_ID }, () => {
            if (!isLoggedIn) {
                liff.getProfile().then(profile => {
                    //console.log('Received values:', profile);
                    dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl }))
                        .unwrap()
                        .then(() => {
                            navigate("/664c908052f0c210a4d1c4a7");
                            window.location.reload();
                        })
                        .catch(() => {
                            // setLoading(false);
                        });
                }).catch(err => console.error(err));
            }
        }, err => console.error(err));
    }

    useEffect(() => {
        initLine();
    }, []);


    return (
        <>
            <div className={'app'} style={{ height: '100%' }}>
                <div className={'top'} style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1, background: '#007bff' }}>
                    <NavBar onBack={() => navigate(-1)} style={{ background: '#007bff' }}></NavBar>
                </div>
                <div className={'body'}>
                    {children}
                </div>
                <div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1, background: '#007bff' }}>
                    <Bottom />
                </div>
            </div>
        </>
    )
}