import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Modal, Tabs } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined } from '@ant-design/icons' //antd design icons import
import { useNavigate } from "react-router-dom";

import StoreService from '../../../services/stores.service';
import ProductsManagement from '../products';
import ProductsCategoryManagement from '../productCategorys';
import StoreSetting from './setting';
import Store from '../../store';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;


const StoreDetail = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [modal1Open, setModal1Open] = useState(false);
    const [data, setData] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [storeData, setStoreData] = useState(null);

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        if (storeId) {
            loadData(storeId);
        }
    }, []);


    const loadData = async (storeId) => {
        const response = await StoreService.getStoresById(storeId);
        console.log('storeId', response);
        if (response.status == 200) {
            setStoreData(response.data);
        }
    }


    const successNotification = (placement) => {
        api.info({
            message: `เพิ่มร้านใหม่เรียบร้อย`,
            description:
                'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            {contextHolder}
            <Layout style={{ minHeight: '100vh', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card style={{ width: '100%', padding: 0}} title="รายละเอียดร้าน">
                                <Tabs
                                    type="card"
                                    items={[
                                        {
                                            label: `รายการสินค้าทั้งหมด`,
                                            key: 1,
                                            children: <ProductsManagement />,
                                        },
                                        {
                                            label: `หมวดหมู่สินค้า`,
                                            key: 2,
                                            children: <ProductsCategoryManagement />,
                                        },
                                        {
                                            label: `รายละเอียดร้าน`,
                                            key: 3,
                                            children: <StoreSetting initialValues={storeData}/>,
                                        }
                                    ]}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default StoreDetail;
