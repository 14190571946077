import React, { useState, useEffect } from 'react';
import { Radio, Popover, Tooltip, message, Layout, Row, Col, Card, Drawer, Divider, Space, notification, Button, Form, Input, Select, Table, Spin, InputNumber, Upload } from 'antd';
import { CloseOutlined, SearchOutlined, RadiusUprightOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons' //antd design icons import
import { UndoOutline, EditSOutline, DeleteOutline, MoreOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline } from 'antd-mobile-icons'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from 'axios';
import userService from '../../../services/user.service';
import EventRegisterService from '../../../services/event-register.service';
import OrderService from '../../../services/order.service';
import columns from './config';
import CreateForm from './createForm';
import ImageUpload from '../../../common/ImageUpload';
import AppConfig from '../../../config/AppConfig';
import SearchComponent from '../../../common/TableSearch';
import dayjs from 'dayjs';
import Invoice from './invoice';

const defaultCategoryId = AppConfig.DEFAULT_PRODUCT_CATEGORY_ID;

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea, Search } = Input;

const OrderManagement = ({ initialValues }) => {
    const [form] = Form.useForm();
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [openEditForm, setOpenEditForm] = useState(false);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [open, setOpen] = useState(false); //drawer add new product
    const [storeId, setStoreId] = useState(null);
    const [categorys, setCategorys] = useState([]);
    const [fileList, setFileList] = useState(null);
    const [hideCancelledStatus, setHideCancelledStatus] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        // setOpenEditForm(false);
        // onReset();
        loadData(storeId);
    };

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const storeId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('initialValues', initialValues);
        if (storeId) {
            setStoreId(storeId);
            loadData(storeId);
        }

    }, []);

    const updatePaymentStatusById = async (id, values) => {
        //console.log('id:', id);
        try {
            const response = await OrderService.updateById(id, values);
            if (response) {
                //console.log('updated:', response);
                // Find the row with the specified key (e.g., '1')
                const targetKey = id;
                const updatedData = data.map((row) => {
                    //console.log('row', row);
                    if (row._id === targetKey) {
                        // Update the desired property (e.g., age)
                        return { ...row, status: values.status };
                    }
                    return row;
                });

                // Update the state with the modified data
                setData(updatedData);

            }
        }
        catch (err) {
            console.error(err);
        }
    }

    const loadData = async (storeId) => {
        setIsLoading(true);
        const response = await OrderService.getAllByStoreId(storeId);
        //console.log('orders data: ', response);
        if (response.status == 200) {
            if (hideCancelledStatus) {
                // Filter the data to show only rows with status !== 'CANCELED'
                const filteredData = data.filter((row) => row.status !== 'CANCELED');
                setData(filteredData);
            } else {
                setData(response.data);
            }
            setIsLoading(false);
        } else {
            setData([]);
            setIsLoading(false);
        }
    }


    const successNotification = (placement) => {
        api.info({
            message: `ดำเนินการสำเร็จ`,
            description:
                'บันทึกข้อมูลในฐานข้อมูลสำเร็จ',
            placement,
        });
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'product');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            form.setFieldsValue({ image: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };

    const onReset = () => {
        form.resetFields();
        setFileList([]);
    };

    const onFill = () => {
        form.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSearch = (value, _e, info) => console.log(info?.source, value);
    const handleUpload = ({ fileList }) => {
        //console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
        setFileList(fileList);
    };

    const handleSearch = (results) => {
        if (results.error) {
            loadData(storeId);
        } else {
            setData(results.data);
        }
    };

    columns[1].render = (text, record) => {
        return (
            <Space size="middle">
                <Tooltip placement="topRight" title={'คลิกเพื่อดูรายละเอียดคำสั่งซื้อ'}>
                    <a
                        href={`#`}
                        onClick={(() => {
                            console.log('record:', record);
                            setSelectedItem(record);
                            showDrawer();
                        })}
                    >{record.orderNumber}</a>
                </Tooltip>
            </Space>
        )
    }

    columns[2].render = (_, record) => {
        return (
            <Space size="middle">
                <Tooltip placement="topRight" title={'วันที่ลูกค้าสั่งซื้อ'}>
                    {record.createdAt && dayjs(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </Tooltip>
            </Space>
        )
    }

    columns[9].render = (text, record) => (
        <>
            <Space direction='horizantal' style={{ fontSize: 14 }}>
                <Popover placement="topLeft" title={text} content={<Content record={record} />} trigger="click">
                    <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '60px', borderRadius: '20px', background: '#0058fd' }}>
                        <Space direction='vertical' style={{ fontSize: 14 }}>
                            <MoreOutline style={{ fontSize: 15 }} />
                        </Space>
                    </Button>
                </Popover>
            </Space>
        </>
    )

    const Content = ({ record, onSuccess }) => {
        return (
            <div>
                <Space direction='horizantal' style={{ fontSize: 14 }}>
                    <Space direction='vertical' style={{ fontSize: 14 }}>
                        <Tooltip placement="topRight" title={'จัดส่งเรียบร้อย'}>
                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '130px', borderRadius: '20px', background: '#0058fd' }}
                                onClick={() => updatePaymentStatusById(record._id, { userId: currentUser.id, status: 'DELIVERED', points: 1 })}
                            >
                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                    จัดส่งเรียบร้อย
                                </Space>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="topRight" title={'ชำระแล้ว'}>
                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '130px', borderRadius: '20px', background: '#0058fd' }}
                                onClick={() => updatePaymentStatusById(record._id, { userId: currentUser.id, status: 'PAID', points: 1 })}
                            >
                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                    ชำระแล้ว
                                </Space>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="topRight" title={'ยกเลิก'}>
                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '130px', borderRadius: '20px', background: '#0058fd' }}
                                onClick={() => updatePaymentStatusById(record._id, { userId: currentUser.id, status: 'PROCESSING' })}
                            >
                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                    กำลังตรวจสอบ
                                </Space>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="topRight" title={'ยังไม่ชำระ'}>
                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '130px', borderRadius: '20px', background: '#0058fd' }}
                                onClick={() => updatePaymentStatusById(record._id, { userId: currentUser.id, status: 'PENDING' })}
                                danger
                            >
                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                    ยังไม่ชำระ
                                </Space>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="topRight" title={'ยกเลิก'}>
                            <Button type="primary" htmlType="submit" style={{ color: '#ffffff', height: '30px', width: '130px', borderRadius: '20px', background: '#0058fd' }}
                                onClick={() => updatePaymentStatusById(record._id, { userId: currentUser.id, status: 'CANCELED' })}
                            >
                                <Space direction='vertical' style={{ fontSize: 14 }}>
                                    ยกเลิก
                                </Space>
                            </Button>
                        </Tooltip>
                    </Space>

                </Space>
            </div>
        )
    };



    return (
        <>
            {contextHolder}
            {selectedItem && (
                <>
                    <Drawer
                        title="ใบสั่งซื้อ"
                        width={'100vh'}
                        onClose={onClose}
                        destroyOnClose
                        open={open}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Invoice data={selectedItem} />
                    </Drawer>
                </>
            )}

            <Layout style={{ minHeight: '100%', margin: 0 }}>
                <Space direction="vertical">
                    <Row type="flex" align="top" justify="center" style={{ border: '1px' }}>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                            <SearchComponent mode={'event-register'} storeId={storeId} onSearch={handleSearch} />
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}>

                        </Col>
                        <Col xs={2} sm={4} md={6} lg={12} xl={10}>
                            <Space direction='horizantal'>
                                <Radio.Group
                                    value={hideCancelledStatus}
                                    defaultValue={hideCancelledStatus}
                                    label={'ซ่อนรายการที่ยกเลิก'}
                                    onChange={(value) => {
                                        //console.log('value', value);
                                        setHideCancelledStatus(value.target.value);
                                        loadData(storeId);
                                    }}>
                                    <Radio.Button value={true}>แสดง</Radio.Button>
                                    <Radio.Button value={false}>ซ่อน</Radio.Button>
                                </Radio.Group>
                                <Button
                                    type="primary"
                                    icon={<UndoOutline />}
                                    style={{ position: 'absolute', right: '30px' }}
                                    onClick={() => {
                                        setHideCancelledStatus(true);
                                        loadData(storeId);
                                    }}
                                >
                                    รีเฟรช
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={6}>
                        <Col span={16} xs={24} lg={24}>
                            <Card className='card' style={{ width: '100%', }} title="รายการคำสั่งซื้อ">
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={'_id'}
                                    locale={{ emptyText: 'ยังไม่พบข้อมูล !' }}
                                    loading={isLoading} />;
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </>
    );
};

export default OrderManagement;
