import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import liff from '@line/liff';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Spin, notification, Typography, InputNumber } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import {
    ConfigProvider, setDefaultConfig,
    Grid, Card, Toast, Button, List, Space,
    AutoCenter, Modal, Divider, ImageUploader, Form, Input, NumberKeyboard
} from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { PictureOutline } from 'antd-mobile-icons'
import { LocationOutline, AntOutline, BankcardOutline, ScanningOutline, ScanCodeOutline, TravelOutline, UserOutline } from 'antd-mobile-icons'
import axios from 'axios';

import AsyncStorageService from '../../../helpers/AsyncStorageService';
import AppConfig from '../../../config/AppConfig';
import userService from '../../../services/user.service';
import { logout } from "../../../slices/auth";
import { loginLine } from "../../../slices/auth";
import payment from "../../../services/payment.service";
import { getAutoSuggestion } from 'thai-data';
import '../overview/style.css';

const { Text, Title } = Typography;

const ConfirmPayment = ({ isMobile, redirectUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [userData, setUserData] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [image, setImage] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [visible, setVisible] = useState('')
    const [value, setValue] = useState('')

    const openKeyboard = (name) => {
        setVisible(name)
    }

    const actions = {
        onClose: () => {
            Toast.show('closed');
            setVisible('');
        },
        onInput: (key) => {
            Toast.show(key);
            setValue(v => v + key);
            formRef.current?.setFieldsValue({ totalAmount: value });
        },
        onDelete: () => {
            Toast.show('delete');
            setValue(v => v.slice(0, v.length - 1));
            formRef.current?.setFieldsValue({ totalAmount: value });
        },
    }


    const logOut = useCallback(() => {
        dispatch(logout());
        liff.logout();
    }, [dispatch]);

    //LINE LIFF initialization
    const initLine = () => {
        liff.init({ liffId: AppConfig.LIFF_ID }, () => {
            if (liff.isLoggedIn()) {
                const email = liff.getDecodedIDToken().email;
                if (!isLoggedIn) {
                    liff.getProfile().then(profile => {
                        dispatch(loginLine({ userId: profile.userId, displayName: profile.displayName, pictureUrl: profile.pictureUrl, email: email }))
                            .unwrap()
                            .then(() => {
                                window.location.reload();
                                setIsloading(false);
                            })
                            .catch(() => {
                                setIsloading(false);
                            });
                    }).catch(err => console.error(err));
                }
            }
        }, err => console.error(err));
    }

    useEffect(() => {
        initLine();
        // Set the document title when the component mounts
        document.title = `แจ้งชำระเงิน`;
        setDefaultConfig({
            locale: enUS,
        })
    }, []);


    useEffect(() => {
        if (currentUser) {
            const ownerId = currentUser.id;
            setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
            setIsOwner(currentUser.roles.includes("ROLE_ADMIN"));
            setOwnerId(ownerId);
            loadData(ownerId);
        } else {
            setIsloading(false);
        }
    }, []);

    const loadData = async (ownerId) => {
        const response = await userService.getUserById(ownerId);
        if (response.status == 200) {
            setUserData(response.data[0]);
            // setWalletBalance(response.data[0].points);
            formRef.current?.setFieldsValue({
                subdistirct: response.data[0].subdistirct,
            });
            setIsloading(false);
        } else {
            setIsloading(false);
        }

    }

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    // check login ?
    // if (!isLoggedIn) {
    //     // Navigate to the destination route
    //     navigate('/login');
    // }

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("image", fileList[0].originFileObj);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            console.log("uploaded success", response.data);
            form.setFieldsValue({ paymentDoc: [response.data] })
            return { ...response.data, imageUr: response.data.imageUr };
        } else {
            console.log("uploaded error");
        }
    };

    const onFinish = async (values) => {
        //console.log('Form values:', values);
        Toast.show({
            icon: 'loading',
            content: 'รอสักครู่…',
        });

        const userInfo = await AsyncStorageService.getItem('user');
        //console.log('User info:', userInfo.id);
        const response = await payment.create({ ...values, paymentDoc: image[0].imageUrl });
        if (response.status == 201) {
            //console.log('Successfully created', response);
            Modal.alert({
                title: 'แจ้งชำระสำเร็จ',
                content: 'เราได้รับใบแจ้งชำระแล้ว จะเร่งดำเนินการตรวจสอบ \n',
                showCloseButton: true,
                cancelText: 'ยกเลิก',
                confirmText: 'ตกลง',
            })
            navigate("/user/overview");
        } else {
            Modal.alert({
                title: 'Error',
                content: 'เกิดข้อผิดพลาด, กรุณาลองอีกครั้งภายหลัง',
                showCloseButton: true,
                cancelText: 'ยกเลิก',
                confirmText: 'ตกลง',
            });
        }
    };

    const handleUpload = (file) => {
        // console.log('uploaded file:', file);
        // you store them in state, so that you can make a http req with them later
        setImage(file);
    };

    async function mockUpload(file) {
        //console.log('selected file:', file);
        const formData = new FormData();
        formData.append("image", file);
        formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');
        formData.append('type', 'logos');
        const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status == 200) {
            //console.log("uploaded success", response.data);
            await sleep(3000)
            return {
                ...response.data,
                imageUrl: response.data.imageUrl,
                url: URL.createObjectURL(file),
            }
        } else {
            console.log("uploaded error");
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const Content = () => {
        return <>
            <ConfigProvider>
                <AutoCenter>
                    <Form
                        ref={formRef}
                        form={form}
                        name="confirmPayment"
                        initialValues={userData}
                        onFinish={onFinish}
                        layout='horizontal'
                        // labelCol={{ span: 10 }}
                        // wrapperCol={{ span: 18 }}
                        footer={
                            <Button block
                                htmlType="submit"
                                color='primary'
                                size='large'
                                onClick={() => {
                                    // Check if the formRef is available
                                    if (formRef.current) {
                                        // Trigger the form submission
                                        formRef.current.submit();
                                    }

                                }}>
                                แจ้งชำระเงิน
                            </Button>
                        } >
                        <Form.Header>แจ้งชำระเงิน</Form.Header>
                        <Form.Item
                            label="แนบหลักฐานการชำระเงิน"
                            name="paymentDoc"
                            rules={[{ required: true, message: 'กรุณาแนบหลักฐานสลิปโอนเงิน' }]}>
                            <ImageUploader
                                style={{ '--cell-size': '90px' }}
                                onChange={handleUpload}
                                upload={mockUpload}
                                maxCount={1}
                            />
                        </Form.Item>

                        <Form.Item
                            label="ชื่อ"
                            name="name"
                            rules={[{ required: true, message: 'กรุณาป้อนชื่อ' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="เบอร์โทร"
                            name="phone"
                            rules={[{ required: true, message: 'กรุณาป้อนเบอร์โทรศัพท์' }]}
                            type="number"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="จำนวนเงิน"
                            name="totalAmount"
                            rules={[{ required: true, message: 'กรุณาป้อนจำนวนที่โอน' }]}
                            type="number">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="แจ้งชำระสำหรับ"
                            name="description"
                            rules={[{ required: true, message: 'ป้อนเหตุผลการแจ้งชำระเงิน' }]}
                        >
                            <Input placeholder='ชำระค่าสินค้า.....' />
                        </Form.Item>
                    </Form>

                    <NumberKeyboard
                        visible={visible === 'totalAmount'}
                        onClose={actions.onClose}
                        onInput={actions.onInput}
                        onDelete={actions.onDelete}
                        customKey={['-', '.']}
                        confirmText='ตกลง'
                    />
                </AutoCenter>
            </ConfigProvider >
        </>
    }

    return (
        <>
            {contextHolder}
            {/* Content */}
           <Content />
        </>
    );
};

export default ConfirmPayment;

const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
};
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};