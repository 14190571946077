import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Layout, Spin, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import AppConfig from '../../../config/AppConfig';
import ProductService from '../../../services/product.service';
import ProductCategoryService from '../../../services/product-category.service';

const defaultCategoryId = AppConfig.DEFAULT_PRODUCT_CATEGORY_ID;

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const EditForm = ({ initialValues, isEdit, form, onFinish, fileList, setFileList }) => {

    const [formReady, setFormReady] = useState(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState();
    const formRef = React.useRef(form);
    const [categorys, setCategorys] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false); //drawer add new product
    const [id, setId] = useState(null);

    useEffect(() => {
        if (initialValues) {
            setId(initialValues._id);
            loadData(initialValues._id);
        }
    }, []);

    const loadData = async (categoryId) => {
        setIsLoading(true);
        const response = await ProductCategoryService.getById(categoryId);
        //console.log('category: ', categoryId, response.data);
        if (response.status == 200) {
            setData(response.data);
            onFill(response.data);
            setIsLoading(false);
            setTimeout(() => {
                setFormReady(true);
            }, 1000);
        } else {
            setData(null);
            setIsLoading(false);
            setFormReady(true);
            form.setFieldsValue({});
        }

    }

    const onFill = (values) => {
        form.setFieldsValue(values);
    };

    if (!formReady) {
        return (
            <>
                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        minHeight: '80vh',
                    }}>
                    <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
                </div>
            </>
        )
    }

    return (
        <>
            {contextHolder}
            <Form
                ref={formRef}
                onFinish={onFinish}
                form={form}
                layout="vertical">
                <Row gutter={16}>
                    <Col span={16}>
                        <Form.Item
                            name="categoryNumber"
                            label="รหัสหมวดหมู่"
                            rules={[
                                {
                                    required: true,
                                    message: 'ป้อนรหัสหมวดหมู่',
                                },
                            ]}
                        >
                            <Input placeholder="1" />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="index"
                            label="ลำดับ"
                            rules={[
                                {
                                    required: true,
                                    message: 'ป้อนลำดับหมวดหมู่',
                                },
                            ]}
                        >
                            <Input placeholder="1" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="ชื่อหมวดหมู่"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาป้อนชื่อหมวดหมู่',
                                },
                            ]}
                        >
                            <Input placeholder="เครื่องดื่ม" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default EditForm;
