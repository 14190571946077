import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Card, Col, Row, Button, List, Drawer, Image, Typography } from 'antd';
import { PlusOutlined, SettingOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { AutoCenter } from 'antd-mobile'
import EventService from "../../services/event.service";
import UserService from "../../services/user.service";
import StoreService from "../../services/stores.service";
import EventRegisterForm from "./registerForm";
import AppConfig from "../../config/AppConfig";

const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const { Title } = Typography;

const EventRegister = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [content, setContent] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [EventDetail, setEventDetail] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const eventId = parts[parts.length - 1]; // Retrieve the last part (storeId)

        UserService.getPublicContent().then(
            (response) => {
                setContent(response.data);
                loadData(eventId);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );

        scrollToTop();

    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };


    const loadData = async (eventId) => {
        const response = await EventService.getById(eventId);
        //console.log('event', response);
        if (response.status == 200) {
            setEventDetail(response.data);
        } else {
            setData([]);
        }
    }


    // Function to update screenWidth state when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add an event listener to update screenWidth when the window is resized
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Row type="flex" justify="center">
            {EventDetail && (
                <>
                    <Card title={EventDetail ? `สมัครร่วมกิจกรรม ${EventDetail.name}` : 'สมัครร่วมกิจกรรม'} style={{ minHeight: '80vh', width: '100%', justifyItems: 'center', justifyContent: 'center', margin: '0 auto' }}>
                        <AutoCenter>
                            <Image
                                preview={false}
                                src={EventDetail.cover ? `https://www.atom-soft.com/image-api/${EventDetail.cover}` : null}
                                style={{
                                    display: 'block',
                                    maxWidth: '100%',
                                    maxHeight: '350px',
                                    width: 'auto',
                                    height: 'auto',
                                    margin: '0 auto 10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </AutoCenter>
                        <EventRegisterForm initialValues={EventDetail} />
                    </Card>
                </>
            )}
        </Row>
    )
};

export default EventRegister;