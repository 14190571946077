import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Space, Card, Button, Modal, QRCode, Spin, Select, Popover, Form, Input, Upload, message, Avatar, Layout, Col, Row, notification, Menu, Typography } from 'antd';
import { UserOutlined, UploadOutlined, HomeOutlined, SettingOutlined, AntDesignOutlined } from '@ant-design/icons';
import AppConfig from '../../../config/AppConfig';
import OrderService from '../../../services/order.service';
import '../overview/style.css';
import Invoice from './invoice';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

const { Text, Title } = Typography;

const OrderDetails = () => {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [data, setData] = useState(null);
    const [isLoading, setIsloading] = useState(true);
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    useEffect(() => {

        const path = window.location.pathname; // Get the current URL path
        const parts = path.split('/'); // Split the path by '/'
        const orderId = parts[parts.length - 1]; // Retrieve the last part (storeId)
        //console.log('orderId: ', orderId);
        loadData(orderId);

    }, []);

    const loadData = async (orderId) => {
        const response = await OrderService.getAllByOrderId(orderId);
        if (response.status == 200) {
            setData(response.data);
            setIsloading(false);
        } else {
            setIsloading(false);
        }
    }

    const successNotification = (placement) => {
        api.info({
            message: `อัพเดทข้อมูลสำเร็จ`,
            // description:
            //     'เพิ่มร้านใหม่เข้าสู่ระบบเรียบร้อย',
            placement,
        });
    };

    if (isLoading) {
        return <>
            <div style={
                {
                    margin: 'auto auto auto auto',
                    marginBottom: '20px',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    minHeight: '80vh',
                }}>
                <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
            </div>
        </>
    }

    if (!isLoggedIn) {

        // Navigate to the destination route
        navigate('/login');

    }

    const htmlContent = `
    <!DOCTYPE html>
<html lang="en, id">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>
      ใบสั่งซื้อเลขที่ ${data.orderNumber}
    </title>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
      rel="stylesheet"
    />
    <link rel="stylesheet" href="https://sahrullahh.github.io/invoice-template-html/assets/css/invoice.css" />
  </head>
  <body>
    <section class="wrapper-invoice">
      <!-- switch mode rtl by adding class rtl on invoice class -->
      <div class="invoice">
        <div class="invoice-information">
          <p><b>Invoice #</b> : ${data.orderNumber}</p>
          <p><b>Created Date </b>: ${dayjs(data.createdAt).format('DD/MM/YYYY HH:mm:ss')}</p>
        </div>
        <!-- logo brand invoice -->
        <div class="invoice-logo-brand">
          <!-- <h2>Tampsh.</h2> -->
          <img src="./assets/image/tampsh.png" alt="" />
        </div>
        <!-- invoice head -->
        <div class="invoice-head">
        <div className="head client-data">
                                    <p>-</p>
                                    <p>${data.shippingAddress.name}</p>
                                    <p>${data.shippingAddress.address} ต.${data.shippingAddress.subdistrict} อ.${data.shippingAddress.district}</p>
                                    <p>จ.${data.shippingAddress.province} ${data.shippingAddress.zipcode}</p>
         </div>
          <div class="head client-data">
            <p>-</p>
            <p>Mohammad Sahrullah</p>
            <p>Bondowoso, Jawa timur</p>
            <p>Jln. Duko Kembang, Bondowoso</p>
          </div>
        </div>
        <!-- invoice body-->
        <div class="invoice-body">
          <table class="table">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Template Invoice</td>
                <td>Rp.75.000</td>
              </tr>
              <tr>
                <td>tax</td>
                <td>Rp.5.000</td>
              </tr>
            </tbody>
          </table>
          <div class="flex-table">
            <div class="flex-column"></div>
            <div class="flex-column">
              <table class="table-subtotal">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>Rp.80.000</td>
                  </tr>
                  <tr>
                    <td>PPN 10%</td>
                    <td>Rp.5.000</td>
                  </tr>
                  <tr>
                    <td>Credit</td>
                    <td>Rp.0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- invoice total  -->
          <div class="invoice-total-amount">
            <p>Total : Rp.80.000</p>
          </div>
        </div>
        <!-- invoice footer -->
        <div class="invoice-footer">
          <p>Thankyou, happy shopping again</p>
        </div>
      </div>
    </section>
    <div class="copyright">
      <p>Powered by ATOMSOFT</p>
    </div>
  </body>
</html>`;


    const overlayImageWithLogo = (imageURL, logoURL, outputCanvas) => {
        const image = new Image();
        const logo = new Image();

        image.src = imageURL;
        logo.src = logoURL;

        image.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext('2d');

            // Draw the image on the canvas
            context.drawImage(image, 0, 0, image.width, image.height);

            // Draw the logo on the top-left corner of the canvas
            context.drawImage(logo, 0, 0, logo.width, logo.height);

            // Convert the canvas to a data URL
            const dataURL = canvas.toDataURL('image/png');

            // Display or save the result
            if (outputCanvas) {
                outputCanvas.width = canvas.width;
                outputCanvas.height = canvas.height;
                const outputContext = outputCanvas.getContext('2d');
                const outputImage = new Image();
                outputImage.src = dataURL;
                outputImage.onload = function () {
                    outputContext.drawImage(outputImage, 0, 0, canvas.width, canvas.height);
                };
            } else {
                // You can use the dataURL as needed, e.g., save it or display it.
                // Example: window.location.href = dataURL;
                window.location.href = dataURL
            }
        };
    };

    const handlePrint = async () => {
        const canvas = await html2canvas(document.querySelector("#capture"), {
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
        });

        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL("image/png");
        const logoURL = 'https://www.atom-soft.com/image-api/users/652b53ca93916.png';

        //const outputCanvas = document.getElementById('output-canvas'); // Optional
        //overlayImageWithLogo(canvas, logoURL)

        // Create an "a" element to download the image
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "captured-image.png"; // Set the desired filename
        a.style.display = "none";

        // Append the "a" element to the document body and trigger a click event
        document.body.appendChild(a);
        a.click();

        // Clean up by removing the "a" element
        document.body.removeChild(a);
    };


    const Content = () => {
        return <>
            <div style={
                {
                    display: 'flex',
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally
                    minHeight: '100vh', // To center it in the viewport vertically
                }}>
                <Space block size="lg" direction="vertical">
                    <Button type="primary" onClick={() => {
                        handlePrint();
                    }} style={{ marginBottom: '30px' }}>
                        ดาวน์โหลด
                    </Button>
                    <Card style={{ minHeight: '70vh', width: '100%' }}>
                        <div id="capture">
                            <Invoice data={data} />
                        </div>
                    </Card>
                </Space>
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            {data && (
                <>
                    <Modal
                        ref={modalRef}
                        title="พิมพ์เอกสาร"
                        centered
                        open={visible}
                        onOk={() => setVisible(false)}
                        onCancel={() => setVisible(false)}
                        width={1200}
                        footer={[
                            <Button key="Cancel" onClick={hideModal}>
                                ยกเลิก
                            </Button>,
                            <Button key="Print" onClick={handlePrint}>
                                พิมพ์
                            </Button>,
                        ]}>
                        <Invoice data={data} />
                    </Modal>
                </>
            )}

            {/* Content */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    minHeight: '95vh', // Set minimum height for the whole viewport
                    width: '100%',
                    padding: '6px',
                    minWidth: '100%',
                }}>
                {data && (
                    <>
                        <Content />
                    </>
                )}
            </div >
        </>
    );
};

export default OrderDetails;