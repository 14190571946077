import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Radio, Layout, Spin, Row, Col, Card, List, Drawer, Divider, Space, notification, Button, Form, Input, Select, message, Upload } from 'antd';
import { RadiusUprightOutlined, LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import AppConfig from '../../../config/AppConfig';
import ProductService from '../../../services/product.service';
import ProductCategoryService from '../../../services/product-category.service';
import AdsService from '../../../services/ads.service';

const defaultCategoryId = AppConfig.DEFAULT_PRODUCT_CATEGORY_ID;

const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const EditForm = ({ initialValues, isEdit, form, onFinish, fileList, setFileList, handleUpload }) => {

    const [formReady, setFormReady] = useState(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState();
    const formRef = React.useRef(form);
    const [categorys, setCategorys] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false); //drawer add new product
    const [id, setId] = useState(null);

    useEffect(() => {
        console.log('initializ value', initialValues);
        if (initialValues) {
            setId(initialValues._id);
            loadData(initialValues._id);
            onFill(initialValues);
        }
        if (initialValues.imageUrl) {
            setFileList([
                {
                    uid: '-1',
                    name: 'image1.png',
                    status: 'done',
                    url: initialValues.imageUrl ? `${initialValues.imageUrl}` : '', // Replace with actual image URLs
                }]);
        } else {
            setFileList([]);
        }

    }, []);

    const loadData = async (id) => {
        setIsLoading(true);
        const response = await AdsService.getById(id);
        console.log('ads data: ', response.data);
        if (response.status == 200) {
            setData(response.data);
            onFill(response.data);
            setIsLoading(false);
            setTimeout(() => {
                setFormReady(true);
            }, 1000);
        } else {
            setData(null);
            setIsLoading(false);
            setFormReady(true);
            form.setFieldsValue({});
        }

    }

    const onFill = (values) => {
        form.setFieldsValue(values);
    };

    if (!formReady) {
        return (
            <>
                <div style={
                    {
                        margin: 'auto auto auto auto',
                        marginBottom: '20px',
                        padding: '5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        minHeight: '80vh',
                    }}>
                    <Spin style={{ marginTop: '350px', justifyContent: 'center', alignContent: 'center' }} />
                </div>
            </>
        )
    }

    return (
        <>
            {contextHolder}
            <Form
                ref={formRef}
                onFinish={onFinish}
                form={form}
                layout="vertical">

                <Row gutter={16}>
                    <Col span={16}>
                        <Form.Item
                            name="isActive"
                            label="สถานะ"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากำหนดสถานะ',
                                },
                            ]}>
                            <Radio.Group size="large">
                                <Radio.Button value={true}>แสดง</Radio.Button>
                                <Radio.Button value={false}>ซ่อน</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={16}>
                        <Form.Item
                            name="index"
                            label="ลำดับ"
                            rules={[
                                {
                                    required: true,
                                    message: 'ลำดับ',
                                },
                            ]}
                        >
                            <Input placeholder="1" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="ชื่อโฆษณา"
                            rules={[
                                {
                                    required: true,
                                    message: 'ชื่อโฆษณา',
                                },
                            ]}
                        >
                            <Input placeholder="ชื่อโฆษณา" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="link"
                            label="ลิงค์รูป">
                            <Input placeholder="ลิงค์ที่ต้องการให้ไปเมื่อคลิกรูป" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="position"
                            label="ตำแหน่งโฆษณา"
                            rules={[
                                {
                                    required: false,
                                    message: 'เลือกตำแหน่งแสดงโฆษณา',
                                },
                            ]}
                        >
                            <Select
                                defaultValue={'HOME-TOP'}
                            >
                                <Select.Option value="NONE">เลือกตำแหน่งแสดงโฆษณา</Select.Option>
                                <Select.Option value="HOME-TOP">โฆษณาหน้าแรก</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="หมายเหตุ">
                            <Input placeholder="หมายเหตุ" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="image"
                            label="รูปโฆษณา"
                            rules={[
                                {
                                    required: false,
                                    message: 'เลือกรูปโฆษณา',
                                },
                            ]}
                        >
                            <Upload
                                listType="picture-card"
                                maxCount={1}
                                fileList={fileList}
                                onChange={handleUpload}
                                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            >
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        อัพโหลด
                                    </div>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default EditForm;
