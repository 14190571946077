export default [
  {
    title: 'บาร์โค้ดสินค้า',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'ชื่อสินค้า',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'ราคาสินค้า',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'คงเหลือในคลัง',
    dataIndex: 'unit_in_stock',
    key: 'unit_in_stock',
  },
  {
    title: 'ดำเนินการ',
    dataIndex: 'note',
    key: 'note',
    render: (text, record) => (
      <>
        {/* action in index.js file */}
      </>
    )
  }
];