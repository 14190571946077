export function translateOrderStatus(status) {
    // Define a mapping of status codes to translations
    const statusMappings = {
        PENDING: 'รอยืนยัน',  // Example: "pending" status maps to "Pending"
        PAID: 'ชำระเงินแล้ว',
        PROCESSING: 'กำลังจัดเตรียมสินค้า',
        SHIPPED: 'ขนส่งเข้ารับพัสดุแล้ว',
        DELIVERED: 'จัดส่งสำเร็จ',
        CANCELED: 'ถูกยกเลิก',
        REFUNDED: 'คืนเงิน',
        // Add more status mappings as needed
    };

    // Check if the provided status exists in the mapping
    if (status in statusMappings) {
        return statusMappings[status]; // Return the translated status
    } else {
        return 'ไม่ระบุ'; // Return a default value for unknown status
    }
}

export function translateEventStatus(status) {
    // Define a mapping of status codes to translations
    const statusMappings = {
        PENDING: 'รอตรวจสอบ',  // Example: "pending" status maps to "Pending"
        PAID: 'ชำระเงินแล้ว',
        COMPLETED: 'ชำระเงินแล้ว',
        PROCESSING: 'กำลังตรวจสอบ',
        SHIPPED: 'ขนส่งเข้ารับพัสดุแล้ว',
        DELIVERED: 'จัดส่งสำเร็จ',
        CANCELED: 'ถูกยกเลิก',
        REFUNDED: 'คืนเงิน',
        // Add more status mappings as needed
    };

    // Check if the provided status exists in the mapping
    if (status in statusMappings) {
        return statusMappings[status]; // Return the translated status
    } else {
        return 'ไม่ระบุ'; // Return a default value for unknown status
    }
}

export function translateStatusColor(status) {
    // Define a mapping of status codes to translations
    const statusMappings = {
        PENDING: 'orange',  // Example: "pending" status maps to "Pending"
        PAID: 'green',
        COMPLETED: 'green',
        PROCESSING: 'orange',
        SHIPPED: 'green',
        DELIVERED: 'green',
        CANCELED: 'red',
        REFUNDED: 'red',
        // Add more status mappings as needed
    };

    // Check if the provided status exists in the mapping
    if (status in statusMappings) {
        return statusMappings[status]; // Return the translated status
    } else {
        return 'ไม่ระบุ'; // Return a default value for unknown status
    }
}