export default [
  {
    title: 'รหัสหมวดหมู่',
    dataIndex: 'categoryNumber',
    key: 'categoryNumber',
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'รายละเอียด',
    dataIndex: 'description',
    key: 'descripttion',
  }
];