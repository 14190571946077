import React, { useState } from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchComponent = ({ onSearch, onChange }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  const handleOnChange = (value) => {
    setSearchQuery(value)
    onChange(value);
  }

  return (
    <div className="search-component">
      <Space>
        <Input
          placeholder="พิมพ์คำที่ต้องการค้นหา"
          value={searchQuery}
          onChange={(e) => {
            handleOnChange(e.target.value);
          }}
          style={{ borderRadius: 5, width: '300px' }}
        />
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
          ค้นหา
        </Button>
      </Space>
    </div>
  );
};

export default SearchComponent;